import Main from "@Components/Main";
import { ThemeContextProvider } from "@Components/Themes/ThemeContextProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { Provider } from "react-redux";
import env from "../services/core/env.js";
import { store } from "../store/store";

function App() {
  return (
    // <MuiThemeProvider theme={themeMUI}>
    <GoogleOAuthProvider clientId={env.getGoogleKey()}>
      <Provider store={store}>
        <ThemeContextProvider>
          <CssBaseline />
          <div className="bg-primary">
            <Main />
          </div>
        </ThemeContextProvider>
      </Provider>
    </GoogleOAuthProvider>

    // </MuiThemeProvider>
  );
}

export default App;
