import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { ErrorOrWarningIcon } from "../../../UI/IconPack";

const Warnings = () => {
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const handleDismiss = (index) => {
    let data = {
      ...promptData,
      errors: promptData?.errors?.filter((e, i) => i !== index),
    };
    dispatch(handleChangEditPrompt(data));
  };
  const handleClearAll = () => {
    let data = {
      ...promptData,
      errors: [],
    };
    dispatch(handleChangEditPrompt(data));
  };
  return (
    <>
      {promptData?.errors?.length > 0 ? (
        <div>
          <div className="flex justify-center m-2">
            <Button onClick={handleClearAll} color="secondary">
              Clear all
            </Button>
          </div>
          <div className="flex flex-col gap-4 items-center justify-start pt-0 p-4 h-[calc(100vh-100px)] overflow-auto">
            {promptData?.errors?.map((item, index) => (
              <div
                key={index}
                className="bg-primary border-1 border-primary p-4 rounded-lg w-full"
              >
                <div>
                  <ErrorOrWarningIcon
                    color={item?.type === "error" ? "#F04438" : "#F79009"}
                  />
                </div>
                <p className="text-sm font-semibold text-secondary">
                  {item?.title}
                </p>
                <p className="text-sm  text-tertiary">{item?.description}</p>
                <div>
                  <div
                    onClick={() => handleDismiss(index)}
                    className="text-sm font-semibold text-tertiary pl-0 p-2 cursor-pointer w-fit"
                  >
                    Dismiss
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="h-96 flex items-center justify-center">
          <p className="text-center text-sm text-primary">
            {" "}
            No warnings or errors found{" "}
          </p>
        </div>
      )}
    </>
  );
};

export default Warnings;
