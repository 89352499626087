const isString = (val) => typeof val === 'string';

const isNumber = (val) => typeof val === 'number';

const isBoolean = (val) => typeof val === 'boolean';

const isObject = (val) => typeof val === 'object';

const isArray = (val) => Array.isArray(val);

const isFunction = (val) => typeof val === 'function';

const toArray = (val) => {
    return val === undefined || val === null ? [] : Array.isArray(val) ? val : val;
};

function isEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
const generateGuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export default { isString, isNumber, isBoolean, isObject, isArray, isFunction, toArray, generateGuid,isEmpty };
