import util from '@Services.Core/util';
import { INFO, CANCEL, TOP, BOTTOM, LEFT, RIGHT } from '@Components/UI/PopoverManager';

let manager;

const objectify = (props) => (util.isString(props) ? { content: props } : props);

const setManager = (mgr) => (manager = mgr);

const info = (props) => {
    const _props = Object.assign({ type: INFO, title: 'Info' }, objectify(props));
    return new Promise((resolve) => {
        _props.onClose = () => resolve();
        manager.showPopover(_props);
    });
};

const top = (props) =>
    info(
        Object.assign(
            {
                anchorVertical: 'top',
                anchorHorizontal: 'center',
                transformVertical: 'bottom',
                transformHorizontal: 'center',
                text: 'top'
            },
            objectify(props)
        )
    );

const bottom = (props) =>
    info(
        Object.assign(
            {
                anchorVertical: 'bottom',
                anchorHorizontal: 'center',
                transformVertical: 'top',
                transformHorizontal: 'center',
                text: 'bottom'
            },
            objectify(props)
        )
    );
const right = (props) =>
    info(
        Object.assign(
            {
                anchorVertical: 'right',
                anchorHorizontal: 'right',
                transformVertical: 'left',
                transformHorizontal: 'left',
                text: 'right'
            },
            objectify(props)
        )
    );

const left = (props) =>
    info(
        Object.assign(
            {
                anchorVertical: 'left',
                anchorHorizontal: 'left',
                transformVertical: 'right',
                transformHorizontal: 'right',
                text: 'left'
            },
            objectify(props)
        )
    );

const close = (popover) => manager.close(popover);
const closepopover = (e, anchorEl) => manager.customClosePopover(e, anchorEl);

const valid = (popover, valid) => manager.valid(popover, valid);

export default { setManager, info, top, bottom, right, close, valid, left, closepopover };
