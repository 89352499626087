import { CustomTextField } from "@Components/UI/StyledComponets";
import snackbarService from "@Services.App/snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewCollection, editCollection } from "../../../services/app/api";
import { formatTimestampToDate } from "../../../services/generalFunctions";
import { handleLoading } from "../../../store/collectionsSlice";
import { useAuth } from "../../Auth/AuthContext";
import TagsField from "../../UI/TagsField";
import Templates from "../templates1/Templates";

const NewCollection = (props) => {
  const { handleModal, callCollections, mode = "new", collection = {} } = props;
  const [errorMessages, setErrorMessages] = useState({});
  const { activeTeam, id: userId } = useAuth();
  const dispatch = useDispatch();
  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const navigate = useNavigate();
  const [showEmptyScreen, setShowEmptyScreen] = useState(true);

  useEffect(() => {
    if (mode === "edit") {
      setShowEmptyScreen(true);
      setCollectionName(collection?.name);
      setDescription(collection?.description || "");
      setTagsList(collection?.tags || []);
    }
  }, [mode]);

  const handleAddNewCollection = (e) => {
    e.preventDefault();
    if (!collectionName) {
      setErrorMessages({ collectionName: "Please enter collection name." });
      return;
    }
    const data = {
      name: collectionName,
      user_id: userId,
      team_id: activeTeam,
    };
    if (description) {
      data["description"] = description;
    }
    if (tagsList.length) {
      data["tags"] = tagsList;
    }
    let collectionApi = addNewCollection;
    let payload = { data };
    dispatch(handleLoading(true));
    if (mode === "edit") {
      collectionApi = editCollection;
      payload["collection_Id"] = collection?.id;
      payload["data"] = {
        name: collectionName,
      };
      if (description) {
        payload["data"]["description"] = description;
      }
      if (tagsList.length) {
        payload["data"]["tags"] = tagsList;
      }
    }
    collectionApi(payload)
      .then((res) => {
        callCollections();
      })
      .catch((error) => {
        snackbarService.error({
          title: `${(error.error && error.error.toString()) || "No Data"}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        if (window.location.pathname === "/onboard/templates") {
          navigate("/onboard/collections", { replace: true });
        }
      });
    handleModal();
  };

  const handleChangeScreen = () => {
    setShowEmptyScreen(!showEmptyScreen);
  };

  return (
    <div elevation={2} className="w-full">
      <div className="relative w-full">
        <div className="flex py-6 pl-10  border-b-[1px] border-primary  items-center justify-between">
          <div className="  ">
            <Typography
              id="modal-modal-title"
              variant="p"
              className="font-geometric text-[26px] text-primary"
            >
              {mode === "edit" ? "Rename Collection" : "New Collection"}
            </Typography>
            {!!collection?.updated_at && mode === "edit" && (
              <p className="text-[11px] text-tertiary ">
                Last edited: {formatTimestampToDate(collection?.updated_at)}
              </p>
            )}
          </div>
          <div className=" absolute top-2 right-2 text-tertiary">
            <IconButton onClick={handleModal} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {!showEmptyScreen ? (
          <Templates
            showWelcome={false}
            handleChangeScreen={handleChangeScreen}
          />
        ) : (
          <div className="flex flex-col gap-4 pt-2">
            {/* {mode !== "edit" &&
              window.location.pathname !== "/onboard/templates" && (
                <div
                  onClick={handleChangeScreen}
                  className="m-2 cursor-pointer"
                >
                  <ArrowBack fontSize="small" /> Select template
                </div>
              )} */}
            <div className="px-10">
              <CustomTextField
                label={"Collection name"}
                name="collectionName"
                fullWidth
                size="small"
                error={!!errorMessages?.collectionName}
                helperText={errorMessages?.collectionName}
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                onFocus={() =>
                  setErrorMessages((ini) => ({ ini, collectionName: "" }))
                }
              />
            </div>
            <div className="px-10">
              <CustomTextField
                label={"Description"}
                name="collectionDescription"
                fullWidth
                size="small"
                error={!!errorMessages?.collectionDescription}
                helperText={errorMessages?.collectionDescription}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onFocus={() =>
                  setErrorMessages((ini) => ({
                    ini,
                    collectionDescription: "",
                  }))
                }
              />
            </div>
            <div className="px-10">
              <div>
                <TagsField value={tagsList} onChange={(v) => setTagsList(v)} />
              </div>
            </div>
            <div className="px-10 border-b-[1px] border-primary"></div>
            <div className="flex justify-end m-4 mr-2 sm:mr-6 gap-4 pb-4 ">
              <Button
                onClick={handleModal}
                variant="outlined"
                sx={
                  mode === "light" && {
                    color: "#344054",
                    borderColor: "#D0D5DD",
                  }
                }
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleAddNewCollection}
              >
                {mode == "edit" ? "Edit collection" : " Add collection"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCollection;
