import { useAuth } from "@Components/Auth/AuthContext";
import { ReactComponent as Coins } from "@Images/coins-stacked-03.svg";
import { ReactComponent as Help } from "@Images/help-circle.svg";
import { ReactComponent as LogoutIcon } from "@Images/log-out-01.svg";
import { ReactComponent as Settings } from "@Images/settings.svg";
import { BellIcon } from "@UI/IconPack";
import ArticleIcon from "@mui/icons-material/Article";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { formatNumber } from "../../services/generalFunctions";
import { fetchAvailableCredits } from "../../store/collectionsSlice";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import { Isotype } from "../UI/IconPack";
import MuiMenu from "../UI/MuiMenu";
import "./NavbarDesign.scss";
import GetCredits from "./Settings/GetCredits";
import TeamsList from "./TeamsList";
const PrivateAuth = () => {
  const auth = useAuth();
  const history = useNavigate();
  const { toggleColorMode, mode } = useThemeContext();
  const [openBuyCredits, setOpenBuyCredits] = useState(false);
  const availableCredits = useSelector(
    (state) => state.collectionsSlice?.availableCredits
  );

  const navLinksList = [
    {
      to: "/onboard/collections",
      name: "Workbench",
    },
    {
      to: "/onboard/assistants",
      name: "Assistants",
    },
    {
      to: "/onboard/data-room",
      name: "Data Room",
    },
    {
      to: "/onboard/templates",
      name: "Templates",
    },
  ];

  const menuListItems = [
    {
      title: mode === "dark" ? "Light Mode" : "Dark Mode",
      icon:
        mode === "dark" ? (
          <LightModeOutlinedIcon fontSize="small" sx={{ color: "#94969C" }} />
        ) : (
          <DarkModeOutlinedIcon fontSize="small" sx={{ color: "#94969C" }} />
        ),
      onClick: () => toggleColorMode(),
    },
    {
      title: "My credits",
      icon: <Coins style={{ color: "#94969C" }} />,
      onClick: () => history("/onboard/settings"),
    },
    {
      title: "Support",
      icon: <Help style={{ color: "#94969C" }} />,
      onClick: () => {
        history("/onboard/settings");
      },
    },
    {
      title: "Team documentation",
      icon: (
        <ArticleIcon size="small" style={{ width: "18px", color: "#94969C" }} />
      ),
      onClick: () => {
        history("/onboard/documentation");
      },
    },
    {
      title: "Logout",
      icon: <LogoutIcon style={{ color: "#94969C" }} />,
      onClick: () => auth.logout().then(() => history("/")),
    },
  ];

  return (
    <>
      {auth.isAuthenticated() ? null : history("/") && auth.logout()}

      <div className="bg-primary w-full border-b-1 border-secondary">
        <div className="top-nav-main flex items-center justify-between px-20 py-[7px] ">
          <div className=" flex text-center gap-10">
            <div className="flex flex-row  w-full items-center">
              <Isotype height={40} width={40} />
            </div>
            <div className="flex items-center  gap-2   ">
              {navLinksList.map((link) => (
                <div key={link.to}>
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      ` text-primary   ${
                        isActive
                          ? "selected-btn hover:dark:text-white hover:text-textPrimary-light bg-[#F9FAFB] dark:bg-[#1f242f]"
                          : "non-selected-btn hover:bg-[#F9FAFB] hover:dark:bg-[#1f242f] hover:dark:text-white hover:text-textPrimary-light"
                      }`
                    }
                  >
                    {link.name}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center  gap-2 justify-end  ">
            <CreditsChip />
            <div
              className={
                window.location.pathname === "/onboard/settings"
                  ? "selected-btn hover:dark:text-white hover:text-textPrimary-light bg-[#F9FAFB] dark:bg-[#1f242f]"
                  : "non-selected-btn hover:bg-[#F9FAFB] hover:dark:bg-[#1f242f] hover:dark:text-white hover:text-textPrimary-light"
              }
              onClick={() => {
                history("/onboard/settings");
              }}
            >
              <Settings />
            </div>{" "}
            <div
              onClick={() => {
                history("/onboard/notifications");
              }}
              className={
                window.location.pathname === "/onboard/notifications"
                  ? "selected-btn hover:dark:text-white hover:text-textPrimary-light bg-[#F9FAFB] dark:bg-[#1f242f]"
                  : "non-selected-btn hover:bg-[#F9FAFB] hover:dark:bg-[#1f242f] hover:dark:text-white hover:text-textPrimary-light"
              }
            >
              <BellIcon height={20} width={20} />
            </div>
            <div>
              <div className="flex items-center   font-primary">
                <MuiMenu
                  menuListItems={menuListItems}
                  MenuButtonIcon={
                    <div>
                      <img
                        className="rounded-full"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAP1BMVEWzs7P///+vr6/k5OTs7Oy2tratra3X19fc3NzR0dH8/Pz4+Pjx8fG4uLi9vb3IyMjBwcHFxcXn5+fu7u7Nzc2cgkJPAAAESElEQVR4nO2dC7qjIAxGJdb323b/a53a3rmjrbQCUYLznxV4PpAkEDSKAAAAAAAAAAAAAAAAAAAAAADgUOiB76fYkzy+kxVtc1pN9UNZ50NySkk1oxy76HSSVKslcX8yR4rVK5fiXAPZvRneZ2t2Jsd2xXByPM/SmlSrikoVJ1GkqNQYqnoI35Govb2upYt1NfDXkai4fNB70AWsSG2snZ8zLk2gjtSOG/Sew5j6flgb0nzL+P0whjeKtB4DtZShzVTKzQTvimEtOM2n+KAjC0eRel0O80UxlPWGeiu/SdH3o2/DXlCpPIiJ6iAYxruorSO2IT8Tp69p6Bca3wZfoMxRUF2FD2JrkKlpkB356eosqFTi2+ITTuvoXySHjJRjCFUlOLUxrCd0dL49tJgXFOtcxE5Tcgv2v5Ri1xqmSarUzbeJjhuXodQ9jZUDGEtqqYYssWKilJqcMi00d1rfKhrYBFXhW2WdhM9QaCHMaBjD0A8whOEMoSUiDA04f7Q4f8SHoSdgCEP5hozVk9T9RL4K+PyGvW8VDeffxTi9ofPxr3zDzY16gRqSVSNUSIbOJ/gzJLYO8W3pP5DXx8d1dPgPcUGfW1BVvo2W8A+htLyG6/R3jqxj0pRfUNhuFAxtGHxLLdjjPZR1Dswc7yeERQu+NoxfxG3ssxvKCoc7TFNpk5Sp73KGvNuljAX+hMSOmoHVUN4QcvSwzxDa9sVoKG0h/WHtuwJ2CG1UeP/8hS2lbxMtDZOhrKpiDhUsgrXgXn2eEkPoMvOE45BUXMq9gCE9rQRmMwuc56m4reAXyLVQlH45L4pSxwzc9/NvIHG6gSh7mXnilIFXAQi6Xbu4BWHoUO6PgrOZOdbHNKXMo98VbIsMuRn3G3Y7GkIL+1XskjfRGfcbFslbCKFwhvl10pDm6IR5Mez7ic0xbJAKJNbPMav3A3sJn/QGKfjV98NaYbB9WoY4giYxUe4XBj5C24dQ/peT1jAJF2FUha+kJtl3mCupgWCQ89Qw95Z45PsNw/0oeY0JXzAvnwKL+WRRAseB7NE8sBEMahTJ8rg7GMXEuiO6akMIGmnncvpUiH8ZqXPsVqhFb0eRs99ELPZHNBQVPN0m5XVI5UlS1HN27tVZK+rnHhQNm34QYCjZJyIsiZIh3qGNfaK6ZF2TetWkNCr4blVqNMesj/z80oyiNttp8N6o86I99lcm059V+F+9z1TjYZb3hSVj+36goWXc7R1I7gvL4YP3Qjzs91ISNbmnwVuS7ZL2UNpnfHe1HSnzgTtFp6Y4at3cyJX193t047+45U7OtbLe1xZhw/cLT++G0T+NjqZ2/8GQ7R9jDsN5GHmv+uyB413aPa7Z8+La1896l2kXXD9QD0P/wBCGMPQPDGEIQ//AEIYw9A8MYQhD/8AQhvL32pwvt3djLBv3jX2SjqsgAAAAAAAAAAAAAAAAAAAAAOD/4Q9tQkhDbo2dwAAAAABJRU5ErkJggg=="
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "",
                        }}
                      />
                    </div>
                  }
                  iconsPosition="start"
                  style={{ width: "318px" }}
                  teamsBox={<TeamsList />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {availableCredits < 1000 && (
        <div className="bg-secondary p-3 w-full text-center ">
          <span className="text-textErrorPrimary text-base font-semibold">
            You are running out of credits!{" "}
          </span>
          <span className="text-tertiary text-base">
            Please{" "}
            <span
              onClick={() => setOpenBuyCredits(true)}
              className="cursor-pointer underline hover:text-secondary"
            >
              get credits
            </span>{" "}
            to continue using LLMs.
          </span>
        </div>
      )}
      <GetCredits
        open={openBuyCredits}
        handleClose={() => setOpenBuyCredits(false)}
      />
      <Outlet />
    </>
  );
};

export default PrivateAuth;

function CreditsChip() {
  const dispatch = useDispatch();
  const { activeTeam } = useAuth();
  const availableCredits = useSelector(
    (state) => state.collectionsSlice?.availableCredits
  );
  useEffect(() => {
    dispatch(fetchAvailableCredits({ team_id: activeTeam }));
    return () => {};
  }, [activeTeam]);

  return (
    <div className="flex gap-2 items-center mr-4 text-secondary text-base">
      Credits{" "}
      <div
        className={`border-1 min-w-[60px] text-center rounded-full text-sm font-medium px-2 py-[2px] ${
          availableCredits > 1000
            ? "border-[#D9D6FE] dark:border-[#4A1FB8] bg-[#F4F3FF] dark:bg-[#27115F] text-[#5925DC] dark:text-[#BDB4FE]"
            : "border-red-600 dark:border-[#912018] bg-red-400 dark:bg-[#55160C] text-red-700 dark:text-[#FDA29B]"
        } `}
      >
        {formatNumber(parseInt(availableCredits))}
      </div>{" "}
    </div>
  );
}
