import { useAuth } from "@Components/Auth/AuthContext";
import snackbarService from "@Services.App/snackbar";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewEnvironment,
  editEnvironment,
  getAllDataRoomFiles,
} from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { emptyEnv } from "../../../../services/constants";
import {
  addToEditEnv,
  fetchEnvironments,
  handleChangEditEnv,
  handleLoading,
  handleUpdateEnvironmentId,
  removeFromEditEnv,
} from "../../../../store/collectionsSlice";
import AppTabs from "../../../UI/AppTabs";
import { FileTypeIcon, SaveIcon } from "../../../UI/IconPack";
import EnvironmentData from "./EnvironmentData";

const EnvironmentEditor = () => {
  const newEnvironmentData = useSelector((state) => state.collectionsSlice);
  const [filesData, setFilesData] = useState([]);
  const { editEnvironmentsList, allEnvironments, searchEnvironment } =
    newEnvironmentData;
  const envData =
    editEnvironmentsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const { activeTeam } = useAuth();

  const handleCloseEnv = (e, prompt) => {
    dispatch(removeFromEditEnv(prompt?.id));
    e.preventDefault();
  };

  const handleSetActiveTab = (e, env) => {
    dispatch(addToEditEnv(env));
    e.preventDefault();
  };
  const handleSaveEnvironment = async () => {
    if (
      envData?.variables?.some((item) =>
        item?.type === "text"
          ? !item?.key || !item?.value
          : !item?.key || !item?.file_id
      )
    ) {
      snackbarService.warning({
        title: "You can't keep empty key and value or file",
      });
      return;
    }

    let payload = {
      team_id: activeTeam,
      data: {
        name: envData?.name,
        variables: envData?.variables.map((variable) => {
          if (variable?.type === "file") {
            return {
              key: variable?.key.trim(),
              value: "",
              type: variable?.type,
              file_id: variable?.file_id,
              embed_file: variable?.embed_file ? true : false,
            };
          } else {
            return {
              key: variable?.key.trim(),
              value: variable?.value.trim(),
              type: variable?.type,
            };
          }
        }),
      },
    };

    let successMessage = "";
    let environmentApi = undefined;
    var addAPi = true;
    if (envData?.id?.includes("newEnvId")) {
      environmentApi = addNewEnvironment;
      successMessage = `Environment Added if you select 'true' for embedding the file, it will take some time to complete the embedding process.`;
    } else {
      payload = { ...payload, environment_id: envData.id };
      environmentApi = editEnvironment;
      addAPi = false;
      successMessage =
        "Environment updated note: if you select 'true' for embedding the file, it will take some time to complete the embedding process.";
    }

    dispatch(handleLoading(true));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    try {
      const res = await environmentApi(payload);
      if (addAPi) {
        let data = {
          oldId: envData?.id,
          ...envData,
          ...res,
        };
        dispatch(handleUpdateEnvironmentId(data));
      }

      snackbarService.success({
        title: `${successMessage}`,
      });
    } catch (error) {
      console.log(error);
      snackbarService.error({
        title: `${error.error && error.error.toString()}`,
      });
    } finally {
      let payload1 = {
        team_id: activeTeam,
        params: {
          page: allEnvironments?.page || 1,
          limit: 10,
          searchKey: searchEnvironment,
        },
      };
      dispatch(fetchEnvironments(payload1));
      dispatch(handleLoading(false));
    }
  };

  const handleDispatchEnvName = (e) => {
    let data = {
      ...envData,
      name: e.target.value,
    };
    dispatch(handleChangEditEnv(data));
  };

  const callFiles = () => {
    dispatch(handleLoading(true));
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 500,
        type: "file,link",
        // type: "file",
        format: "txt,pdf",
      },
    };
    getAllDataRoomFiles(payload)
      .then((res) => {
        setFilesData(res?.items);
      })
      .catch((error) => {
        snackbar.error({ title: `${error.error && error.error.toString()}` });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  useEffect(() => {
    callFiles();
  }, []);

  return (
    <div className="h-full relative ">
      <AppTabs
        list={editEnvironmentsList}
        onBlur={handleDispatchEnvName}
        handleAddNew={(e, scroll) => handleSetActiveTab(e, emptyEnv())}
        handleCloseTab={handleCloseEnv}
        handleSetActiveTab={handleSetActiveTab}
      />
      {editEnvironmentsList?.length === 0 ? (
        <div className="text-4xl text-center h-[93.5%] bg-primary flex flex-col justify-center items-center">
          <div className="relative flex flex-col items-center justify-center">
            <FileTypeIcon />
            <div className="absolute bottom-14">
              <p className="text-sm-semibold  text-primary mt-4">
                Nothing to see here
              </p>
              <p className="text-sm text-tertiary z-10">
                Provide the details below to get started.
              </p>
              <div className="my-4 flex items-center justify-center">
                <Button
                  variant="contained"
                  onClick={(e, scroll) => handleSetActiveTab(e, emptyEnv())}
                >
                  Create environment
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <EnvironmentData filesData={filesData} />
          <div className="absolute bottom-0 w-full border-t-1 border-primary flex justify-end items-center h-16 gap-4 px-4 bg-secondary">
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={handleSaveEnvironment}
            >
              Save environment
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EnvironmentEditor;
