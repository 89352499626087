import Style from "@AuthComponent/Settings/CreditsManager.module.css";
import BlockingLoader from "@Components/UI/BlockingLoader";
import { CustomTextField } from "@Components/UI/StyledComponets";
import { ReactComponent as MasterCard } from "@Images/MasterCard.svg";
import { ReactComponent as Visa } from "@Images/Visa.svg";
import {
  addNewCard,
  buyTokens,
  deleteCard,
  getAllTeamsCards,
  makePayment,
} from "@Services.App/api";
import snackbarService from "@Services.App/snackbar";
import MuiCheckbox from "@UI/MuiCheckbox";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormControlLabel, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { convertToToken } from "../../../services/generalFunctions";
import {
  fetchAvailableCredits,
  handleLoading,
} from "../../../store/collectionsSlice";
import { useAuth } from "../../Auth/AuthContext";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import { TrashIcon } from "../../UI/IconPack";
import MuiModal from "../../UI/MuiModal";
const numberOfCredits = [
  {
    label: "20,000",
    value: 20000,
  },
  {
    label: "30,000",
    value: 30000,
  },
  {
    label: "40,000",
    value: 40000,
  },
  {
    label: "50,000",
    value: 50000,
  },
  {
    label: "60,000",
    value: 60000,
  },
  {
    label: "70,000",
    value: 70000,
  },
  {
    label: "80,000",
    value: 80000,
  },
  {
    label: "90,000",
    value: 90000,
  },
  {
    label: "100,000",
    value: 100000,
  },
];

const GetCredits = ({ open, handleClose }) => {
  const { mode, colors } = useThemeContext();
  const [noOfTokens, setNoOfTokens] = useState(null);
  const [planType, setPlanType] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedCartId, setCheckedCartId] = useState("");
  const [cards, setCards] = useState([]);

  const { activeTeam } = useAuth();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setNoOfTokens(null);
    setPlanType("");
    handleClose();
  };

  const getAllCards = (team_id) => {
    setLoading(true);

    getAllTeamsCards(team_id)
      .then((res) => {
        setCards(res.items);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProcessPayment = () => {
    if (noOfTokens <= 0) {
      snackbarService.error({
        title: `Please check amount amount is invalid`,
      });
      return;
    }
    dispatch(handleLoading(true));
    makePayment({
      team_id: activeTeam,
      data: {
        payment_method_id: checkedCartId,
        amount: noOfTokens,
      },
    })
      .then((res) => {
        snackbarService.success({
          title: `Your payment is done.`,
        });
        dispatch(fetchAvailableCredits({ team_id: activeTeam }));
        dispatch(handleLoading(false));
        handleCloseModal();
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
        dispatch(handleLoading(false));
      })
      .finally(() => {});
  };

  const handleProceed = () => {
    dispatch(handleLoading(true));
    const data = {
      token_type: "text_tokens",
      token: noOfTokens,
    };
    buyTokens({ team_id: activeTeam, data })
      .then((res) => {
        if (!!res?.url) window.open(res?.url, "_self");
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        handleCloseModal();
      });
  };

  const handleAddCards = () => {
    dispatch(handleLoading(true));

    addNewCard(activeTeam)
      .then((res) => {
        if (!!res?.url) window.open(res?.url, "_self");
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };

  const handleDeleteCard = (id) => {
    setLoading(true);

    deleteCard(activeTeam, id)
      .then((res) => {
        handleCloseModal();
        snackbarService.success({
          title: `Card deleted. `,
        });
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllCards(activeTeam);
  }, []);

  return (
    <MuiModal open={open} handleClose={handleClose}>
      <BlockingLoader loading={loading} />
      <div className="bg-primary rounded-lg p-5">
        <div className="flex justify-between">
          <div>
            <p className="text-lg font-medium text-primary">Get credits</p>
            <p className="text-sm text-tertiary">
              An account may use up to $100 of credits in a calendar month. This
              is calculated based on API usage and the age of your account. The
              monthly usage limit will increase over time and with usage.
            </p>
          </div>
          <div>
            <IconButton onClick={handleCloseModal} size="small">
              <Close fontSize="small" />
            </IconButton>
          </div>
        </div>
        <div className="py-5 flex items-center gap-4">
          <div className="w-[30%]">
            <p className="text-sm-regular text-primary">
              Enter amount in dollar
            </p>
            {/* <TextField
              selectClassName={"bg-primary"}
              menuItems={numberOfCredits}
              value={noOfTokens}
              onChange={(e) => setNoOfTokens(e.target.value)}
              valueKey={"value"}
              labelKey={"label"}
            /> */}
            <CustomTextField
              id="outlined-basic"
              // label="Enter feed link"
              placeholder="Enter amount"
              variant="outlined"
              fullWidth
              type="number"
              value={noOfTokens}
              onChange={(e) => {
                setNoOfTokens(e.target.value);
              }}
            />
          </div>
          {!!noOfTokens && (
            <div className="font-medium text-base mt-4 text-primary">
              Token: {convertToToken(noOfTokens)}
            </div>
          )}
        </div>
        <div>
          {cards.map((item) => {
            return (
              <div className="flex gap-2 w-full">
                <div
                  className={` w-full ${Style.outerBox} ${
                    checkedCartId == item?.id ? Style.outerSelectedBorder : ""
                  }`}
                  onClick={() => {
                    setCheckedCartId(item?.id);
                  }}
                >
                  <div className="flex justify-between ">
                    <div className="flex gap-3 pl-4">
                      <div className={`${Style.cardLogo}`}>
                        {item.card_type != "visa" ? <MasterCard /> : <Visa />}
                      </div>
                      <div>
                        <div className="text-sm-medium">
                          {item?.card_type} ending in {item?.card_no}
                        </div>
                        <div className="text-sm-regular">
                          Expiry {item.expires}
                        </div>
                        <div className="text-sm-semibold mt-2 hidden">
                          Set as default
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="">
                        <FormControlLabel
                          control={<MuiCheckbox />}
                          checked={checkedCartId == item?.id ? true : false}
                          // onChange={(e) => alert(e.target.checked)}
                          // label={`Organization changed`}
                          className="text-sm text-tertiary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    // disabled={checkedCartId == item?.id ? true : false}
                    sx={{ color: "#E57878", minWidth: "10px" }}
                    onClick={() => handleDeleteCard(item?.id)}
                    startIcon={<TrashIcon />}
                  ></Button>
                </div>
              </div>
            );
          })}
        </div>
        {cards?.length == 0 ? (
          <div className="my-8">
            Please add New cart first then click on add payment.
          </div>
        ) : null}
        <div className="mt-4">
          <Button
            variant="textWhite"
            startIcon={<AddIcon />}
            onClick={handleAddCards}
          >
            Add new payment method
          </Button>
        </div>
        <div className="border-t-1 pt-8 border-primary flex justify-end gap-4 ">
          <Button
            sx={
              mode === "light" && {
                color: "#344054",
                borderColor: "#D0D5DD",
              }
            }
            variant="outlined"
            color="secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            onClick={handleProcessPayment}
            disabled={!noOfTokens || !checkedCartId}
            variant="contained"
          >
            Proceed to payment
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

export default GetCredits;
