const AUTH_TOKEN_KEY = "__token";

const EMAIL_KEY = "__email";
const FIRST_NAME_KEY = "__first_name";
const LAST_NAME_KEY = "__last_name";
const WORKER_ID_KEY = "__worker_id";
const ID_KEY = "__id";
const IMAGE_URL = "__image_url";
const PageId = "__page_id";
const NEW_BRAND_ID = "__new_brand_id";
const USER_ROLE = "__user_role";
const BRAND_ROLE = "__brand_role";
const STORYE_TEMPLATE_ID = "__storye_template_id";
const STORYE_PAGE_ID = "__storye_page_id";
const STORYE_PAGE_PREVIEW = "__storye_page_PREVIEW";
const SET_CONFIG_VARIABLES = "__SET_CONFIG_VARIABLES";
const CURRENT_PAGE_TYPE = "__CURRENT_PAGE_TYPE";
const BRANDING_COMPLETED = "__BRANDING_COMPLETED";
const STORYE_BRAND = "__storye_brand";
const STORYE_DATA = "__storye_data";
const PAGE_DATA = "__page_data";
const PERCENTAGE = "__percentage_data";
const cancelTocken = "__cancel_data";
const cancelTockenFunction = "__cancelTockenFunction";
const OPEN_BRAND_NAME = "__open_brand_name";
const OPEN_BRAND_CATEGORY = "__open_brand_category";
const OPEN_BRAND_STEP1 = "__open_brand_step1";
const OPEN_BRAND_EMAIL = "__open_brand_email";
const OPEN_BRAND_BRAND_ID = "__open_brand_brand_id";
const OPEN_BRAND_USER_ID = "__open_brand_user_id";
const PAGE_DATA_OPEN = "__page_data_open";
const HOME_PAGE_DATA = "__home_page_data";
const CANCELREQUEST = "__cancel_request";
const GET_FIRST_STEP_STATUS = "__get_first_step_status";
const CHAT_STEP = "__chat_step";
const CHATE = "__chate";
const THEME_ID = "__theme_id";
const GENERAATED_FROM = "__generated_from";
const PREVIEW_KEY = "__gpreview_key";
const USER_INPUT_DATA = "__user_input_data";
const ACTIVE_TEAM = "__active_team";
const ADMIN_TEAM = "__admin_team";

class Storage {
  clear = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
  };

  setAuthToken = (token) => {
    return window.localStorage.setItem(AUTH_TOKEN_KEY, token);
  };

  getAuthToken = () => {
    return window.localStorage.getItem(AUTH_TOKEN_KEY);
  };

  setEmail = (email) => {
    return window.localStorage.setItem(EMAIL_KEY, email);
  };

  getEmail = () => {
    return window.localStorage.getItem(EMAIL_KEY);
  };

  setFirstName = (firstName) => {
    return window.localStorage.setItem(FIRST_NAME_KEY, firstName);
  };

  getFirstName = () => {
    return window.localStorage.getItem(FIRST_NAME_KEY);
  };

  setLastName = (lastName) => {
    return window.localStorage.setItem(LAST_NAME_KEY, lastName);
  };

  getLastName = () => {
    return window.localStorage.getItem(LAST_NAME_KEY);
  };

  getWorkerId = () => {
    return window.localStorage.getItem(WORKER_ID_KEY);
  };
  setWorkerId = (workerId) => {
    return window.localStorage.setItem(WORKER_ID_KEY, workerId);
  };

  redirectTologin = (url) => {
    window.location.href = url;
  };

  setId = (id) => {
    return window.localStorage.setItem(ID_KEY, id);
  };

  getid = () => {
    return window.localStorage.getItem(ID_KEY);
  };

  setImageurl = (url) => {
    if (window.localStorage.getItem(IMAGE_URL)) {
      return sessionStorage.setItem(IMAGE_URL, url);
    } else {
      sessionStorage.setItem(IMAGE_URL, url);
      return localStorage.setItem(IMAGE_URL, url);
    }
  };

  getImageurl = () => {
    if (window.sessionStorage.getItem(IMAGE_URL)) {
      return sessionStorage.getItem(IMAGE_URL);
    } else {
      return sessionStorage.setItem(IMAGE_URL, localStorage.getItem(IMAGE_URL));
    }
  };

  SetPageID = (ID) => {
    return localStorage.setItem(PageId, ID);
  };
  getPageId = () => {
    return window.localStorage.getItem(PageId);
  };

  SetNewBrandLocalStorageID = (ID) => {
    if (window.localStorage.getItem(NEW_BRAND_ID)) {
      return sessionStorage.setItem(NEW_BRAND_ID, ID);
    } else {
      sessionStorage.setItem(NEW_BRAND_ID, ID);
      return localStorage.setItem(NEW_BRAND_ID, ID);
    }
  };
  getNewBrandLocalStorageID = () => {
    if (
      window.sessionStorage.getItem(NEW_BRAND_ID) &&
      window.sessionStorage.getItem(NEW_BRAND_ID) != undefined &&
      window.sessionStorage.getItem(NEW_BRAND_ID) != "undefined"
    ) {
      return sessionStorage.getItem(NEW_BRAND_ID);
    } else {
      return sessionStorage.setItem(
        NEW_BRAND_ID,
        localStorage.getItem(NEW_BRAND_ID)
      );
    }
  };

  setUserRole = (role) => {
    return window.localStorage.setItem(USER_ROLE, role);
  };

  getUserRole = () => {
    return window.localStorage.getItem(USER_ROLE);
  };

  setBrandRole = (role) => {
    return window.localStorage.setItem(BRAND_ROLE, role);
  };

  getBrandRole = () => {
    return window.localStorage.getItem(BRAND_ROLE);
  };

  setStoryeTemplateId = (id) => {
    return window.localStorage.setItem(STORYE_TEMPLATE_ID, id);
  };

  getStoryeTemplateId = () => {
    return window.localStorage.getItem(STORYE_TEMPLATE_ID);
  };

  setStoryePageId = (id) => {
    return window.localStorage.setItem(STORYE_PAGE_ID, id);
  };

  getStoryePageId = () => {
    return window.localStorage.getItem(STORYE_PAGE_ID);
  };

  setPrevevieData = (data) => {
    return window.localStorage.setItem(STORYE_PAGE_PREVIEW, data);
  };

  getPrevevieData = (data) => {
    return window.localStorage.getItem(STORYE_PAGE_PREVIEW);
  };
  setConfigData = (data) => {
    return window.localStorage.setItem(SET_CONFIG_VARIABLES, data);
  };

  getConfigData = (data) => {
    return window.localStorage.getItem(SET_CONFIG_VARIABLES);
  };

  setCurrentPageToShow = (data) => {
    return window.localStorage.setItem(CURRENT_PAGE_TYPE, data);
  };

  getCurrentPageToShow = () => {
    return window.localStorage.getItem(CURRENT_PAGE_TYPE);
  };

  setBrandingCompleted = (status) => {
    return window.localStorage.setItem(BRANDING_COMPLETED, status);
  };

  getBrandingCompleted = () => {
    return window.localStorage.getItem(BRANDING_COMPLETED);
  };

  setStoryeBrand = (val) => {
    return window.localStorage.setItem(STORYE_BRAND, val);
  };
  getSetStoryeBrand = () => {
    return window.localStorage.getItem(STORYE_BRAND);
  };
  setPercentage = (val) => {
    return window.localStorage.setItem(PERCENTAGE, val);
  };
  getPercentage = () => {
    return window.localStorage.getItem(PERCENTAGE);
  };
  setStorydata = (val) => {
    var data = JSON.stringify(val);
    return window.sessionStorage.setItem(STORYE_DATA, data);
  };
  getStorydata = () => {
    var dataStorye = window.sessionStorage.getItem(STORYE_DATA);

    return JSON.parse(dataStorye);
  };

  setPageData = (val) => {
    var data = JSON.stringify(val);
    return window.sessionStorage.setItem(PAGE_DATA, data);
  };
  getPageData = () => {
    var dataStorye = window.sessionStorage.getItem(PAGE_DATA);

    return JSON.parse(dataStorye);
  };

  setcancelTocken = (val) => {
    console.log(val);
    window.localStorage.setItem(cancelTockenFunction, val.cancel.toString());
    return window.localStorage.setItem(cancelTocken, JSON.stringify(val));
  };
  getCancelTocken = () => {
    var Cancel = Cancel;
    var datav = JSON.parse(window.localStorage.getItem(cancelTocken));
    var token = datav.token;
    var cancel = eval(
      "(" + window.localStorage.getItem(cancelTockenFunction) + ")"
    );

    cancel();
    // console.log(myFunc, myFunc.token.reason, "KKKKKKKKKKKKKKKKKKKKKKKKKKKK");
    return [datav, cancel];
  };

  setOpenBrandName = (name) => {
    return window.localStorage.setItem(OPEN_BRAND_NAME, name);
  };

  getOpenBrandName = () => {
    return window.localStorage.getItem(OPEN_BRAND_NAME);
  };

  setOpenCategoryName = (category) => {
    return window.localStorage.setItem(OPEN_BRAND_CATEGORY, category);
  };

  getOpenCategoryName = () => {
    return window.localStorage.getItem(OPEN_BRAND_CATEGORY);
  };

  setOpenStep = (step1_value) => {
    return window.localStorage.setItem(OPEN_BRAND_STEP1, step1_value);
  };

  getOpenStep = () => {
    return window.localStorage.getItem(OPEN_BRAND_STEP1);
  };

  setOpenEmail = (email) => {
    return window.localStorage.setItem(OPEN_BRAND_EMAIL, email);
  };

  getOpenEmail = () => {
    return window.localStorage.getItem(OPEN_BRAND_EMAIL);
  };

  setOpenBrandID = (brand_id) => {
    return window.localStorage.setItem(OPEN_BRAND_BRAND_ID, brand_id);
  };

  getOpenBrandID = () => {
    return window.localStorage.getItem(OPEN_BRAND_BRAND_ID);
  };
  setOpenUserID = (user_id) => {
    return window.localStorage.setItem(OPEN_BRAND_USER_ID, user_id);
  };

  getOpenUserID = () => {
    return window.localStorage.getItem(OPEN_BRAND_USER_ID);
  };

  setPageDataOpen = (val) => {
    var data = JSON.stringify(val);
    return window.localStorage.setItem(PAGE_DATA_OPEN, data);
  };
  getPagedataOpen = () => {
    var dataStorye = window.localStorage.getItem(PAGE_DATA_OPEN);

    return JSON.parse(dataStorye);
  };

  setHomePageData = (val) => {
    var data = JSON.stringify(val);
    return window.localStorage.setItem(HOME_PAGE_DATA, data);
  };
  getHOmePageData = () => {
    var dataStorye = window.localStorage.getItem(HOME_PAGE_DATA);

    return JSON.parse(dataStorye);
  };

  setCancelRequestStatus = (status) => {
    return window.localStorage.setItem(CANCELREQUEST, status);
  };

  getCancelRequestStatus = () => {
    return window.localStorage.getItem(CANCELREQUEST);
  };

  // new Banding chat flow

  setFirstStateStatus = (status) => {
    return window.localStorage.setItem(GET_FIRST_STEP_STATUS, status);
  };

  getFirstStateStatus = () => {
    return window.localStorage.getItem(GET_FIRST_STEP_STATUS);
  };

  setChatStep = (step) => {
    return window.localStorage.setItem(CHAT_STEP, step);
  };

  getChatStep = () => {
    var numbe = window.localStorage.getItem(CHAT_STEP);
    if (isNaN(numbe)) {
      return 0;
    } else {
      if (numbe == null) {
        return 0;
      }
      return parseInt(numbe);
    }
  };

  setBrandCategory = (cate) => {
    return window.localStorage.setItem(CHATE, cate);
  };

  getBrandCategory = () => {
    return window.localStorage.getItem(CHATE);
  };
  setThemeId = (id) => {
    return window.localStorage.setItem(THEME_ID, id);
  };

  getThemeId = () => {
    return window.localStorage.getItem(THEME_ID);
  };

  setGeneratedGromFlow = (type) => {
    return window.localStorage.setItem(GENERAATED_FROM, type);
  };

  getGeneratedGromFlow = () => {
    return window.localStorage.getItem(GENERAATED_FROM);
  };

  setPreviewPageId = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 36; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return window.localStorage.setItem(PREVIEW_KEY, result);
  };

  getPreviewPageID = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 36; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (window.localStorage.getItem(PREVIEW_KEY)) {
      return window.localStorage.getItem(PREVIEW_KEY);
    } else {
      return result;
    }
  };

  setUserInputs = (val) => {
    var dataInput = JSON.stringify(val);
    return window.localStorage.setItem(USER_INPUT_DATA, dataInput);
  };
  getUserInputs = () => {
    var data_from = window.localStorage.getItem(USER_INPUT_DATA);
    if (data_from) {
      return JSON.parse(data_from);
    } else {
      return [];
    }
  };

  setActiveTeam = (val) => {
    return window.localStorage.setItem(ACTIVE_TEAM, val);
  };
  getActiveTeam = () => {
    return window.localStorage.getItem(ACTIVE_TEAM);
  };
  setIsAdminTeam = (val) => {
    return window.localStorage.setItem(ADMIN_TEAM, val);
  };
  getIsAdminTeam = () => {
    return window.localStorage.getItem(ADMIN_TEAM);
  };
}

export default new Storage();
