export const MuiSwitch = {
  styleOverrides: {
    root: {
      width: 36,
      height: 20,
      padding: 0,
      marginRight: 10,
      marginLeft: 10,
      "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            opacity: 1,
            border: "none",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        width: 16,
        height: 16,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#9E9E9E",
        opacity: 1,
        transition: "background-color 0.3s",
      },
    },
  },
};
