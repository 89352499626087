import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import TextEditor from "../../../UI/TextEditor/TextEditor";

const Document = () => {
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const [value, setValue] = useState(promptData?.description);
  useEffect(() => {
    setValue(promptData?.description ? promptData?.description : "");
  }, [promptData?.id]);

  const handleChange = (value) => {
    setValue(value);
    let data = {
      ...promptData,
      description: value,
    };
    dispatch(handleChangEditPrompt(data));
  };
  return (
    <div className="bg-primary rounded-lg m-2 h-[90vh] text-primary">
      <TextEditor value={value} handleChange={handleChange} />
    </div>
  );
};

export default Document;
