import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as Terminal } from "../../../../Images/TerminalWindow.svg";
import { imageToBase64Image } from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { downloadImage } from "../../../../services/generalFunctions";
import { handleLoading } from "../../../../store/collectionsSlice";
import { DownloadIcon } from "../../../UI/IconPack";

const ImageOutput = (props) => {
  const { promptData } = props;
  const dispatch = useDispatch();

  const saveImage = (item, index) => {
    dispatch(handleLoading(true));
    imageToBase64Image({ data: { url: item?.url } })
      .then((res) => {
        downloadImage(res?.data, `${promptData?.name}_op_${index + 1}`);
      })
      .catch((error) => {
        snackbar.error({
          title: "Failed to download image",
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  const ImageCard = ({ item, index }) => {
    const [imageUrlError, setImageUrlError] = useState(false);
    return (
      <div className="bg-bgSecondary border-1 border-borderSecondary p-2 relative h-auto w-[24%] gap-2  bg-cover rounded-lg overflow-hidden ease-in-out ">
        {imageUrlError ? (
          <>
            <div className="w-full flex justify-center">
              <Terminal />
            </div>
            <p className="text-center">Invalid URL</p>
          </>
        ) : (
          <>
            <img
              src={item?.url}
              alt={item?.url}
              className="h-auto w-full bg-cover rounded-lg "
              onError={(event) => {
                setImageUrlError(true);
              }}
            />
            <div className="w-full mt-2">
              <Button
                onClick={() => saveImage(item, index)}
                fullWidth
                color="secondary"
                startIcon={<DownloadIcon />}
                sx={{ color: "#94969C" }}
              >
                Download
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full flex flex-wrap justify-between">
        {Array.isArray(promptData?.promptOutput) &&
          promptData?.promptOutput?.map((item, index) => (
            <ImageCard item={item} index={index} key={index} />
          ))}
      </div>
    </div>
  );
};

export default ImageOutput;
