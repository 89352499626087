import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";

const XmlFormatter = (props) => {
  const { activeScreen = "Preview" } = props;
  const { colors, mode } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const dispatch = useDispatch();
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const outputData = promptData.output_keys[promptData?.outputFormat];

  return (
    <div>
      {typeof promptData?.promptOutput === "string" &&
      activeScreen !== promptData?.outputFormat ? (
        <pre
          onBlur={(e) => {
            let data = {
              ...promptData,
              output_keys: {
                ...promptData.output_keys,
                xml: e.target.innerText,
              },
            };
            dispatch(handleChangEditPrompt(data));
          }}
          contentEditable={true}
          style={{ minHeight: "100px" }}
          className="w-full h-auto overflow-auto p-4 outline-none"
        >
          {outputData}
        </pre>
      ) : (
        <div>
          <CodeHighlighter
            customStyle={{ background: "transparent" }}
            style={mode === "dark" ? "vs2015" : "a11yLight"}
            language={"xml"}
            wrapLongLines={true}
            wrapLines={true}
          >
            {outputData}
          </CodeHighlighter>
        </div>
      )}
    </div>
  );
};

export default XmlFormatter;
