import util from "@Services.Core/util";
import { INFO, SUCCESS } from "@Components/UI/DialogManager";
let manager;
const objectify = (props) =>
  util.isString(props) ? { content: props } : props;
const setManager = (mgr) => (manager = mgr);

const info = (props) => {
  const _props = Object.assign({ type: INFO, title: "Info" }, objectify(props));
  return new Promise((resolve) => {
    _props.onClose = () => resolve();
    manager.showModal(_props);
  });
};

const success = (props) =>
  info(Object.assign({ type: SUCCESS, title: "Success" }, objectify(props)));

export default { setManager, info, success };
