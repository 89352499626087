import React from "react";
import Snackbar from "@mui/material/Snackbar";
import util from "@Services.Core/util";

import MuiAlert from "@mui/material/Alert";
export const INFO = "info";
export const WARNING = "warning";
export const SUCCESS = "success";
export const ERROR = "error";
export const CONFIRM = "confirm";

export const CANCEL = "cancel";
export const SUBMIT = "submit";
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class SnackBarManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { snackbars: [] };
  }

  newKey() {
    return `snackbar__${util.generateGuid()}`;
  }

  closeSnackbar(e, { key, onClose } = {}) {
    if (!key) return;
    onClose && onClose(e);
    this.setState({
      snackbars: this.getSnackbars().filter((snackbar) => snackbar.key !== key),
    });
  }

  showSnackbar(props = {}) {
    const key = this.newKey();
    this.setState({
      snackbars: this.getSnackbars().concat({ ...props, key, valid: true }),
    });
    return { key };
  }

  valid({ key } = {}, valid = true) {
    this.setState({
      snackbars: this.getSnackbars().map((snackbar) =>
        snackbar.key === key ? { ...snackbar, valid } : snackbar
      ),
    });
  }

  getSnackbars() {
    return this.state.snackbars;
  }

  getTitle(snackbar = {}) {
    return snackbar.title || "SnackBar";
  }
  renderTitle(snackbar = {}) {
    return this.getTitle(snackbar);
  }

  getSnackBarType(snackbar = {}) {
    return snackbar.type;
  }

  renderSnackbar(snackbar = {}) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={(e) => this.closeSnackbar(e, snackbar)}
      >
        <Alert
          onClose={(e) => this.closeSnackbar(e, snackbar)}
          severity={this.getSnackBarType(snackbar)}
        >
          {this.renderTitle(snackbar)}
        </Alert>
      </Snackbar>
    );
  }

  render() {
    console.debug("==================== SnackbarManager");
    console.debug("snackbars", this.state.snackbars);
    return this.getSnackbars().map((snackbar) => this.renderSnackbar(snackbar));
  }
}

export default SnackBarManager;
