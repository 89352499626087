import {
  login as doLogin,
  signUp as doSignUp,
  socialLogin as doSocialLogin,
  signUpOnInvitation,
} from "@Services.App/api";
import React, { createContext, useContext, useState } from "react";
import storage from "../../services/app/storage";

// Ref: https://reactrouter.com/web/example/auth-workflow

export const AuthContext = createContext();

export const useAuthProvider = () => {
  const [token, setToken] = useState(storage.getAuthToken());
  const [email, setEmail] = useState(storage.getEmail());
  const [firstName, setFirstName] = useState(storage.getFirstName());
  const [lastName, setLastName] = useState(storage.getLastName());
  const [activeTeam, setActiveTeam] = useState(storage.getActiveTeam());
  const [isAdminTeam, setIsAdminTeam] = useState(storage.getIsAdminTeam());
  // const [id, setId] = useState(storage.getid());

  const [id, setId] = useState(storage.getid() ? storage.getid() : "");
  const [imageUrl, setImageUrl] = useState(
    storage.getImageurl() ? storage.getImageurl() : ""
  );
  const [userRole, setUserRole] = useState(
    storage.getImageurl() ? storage.getImageurl() : ""
  );
  const isAuthenticated = () => {
    if (Boolean(storage.getAuthToken())) {
      return Boolean(storage.getAuthToken());
    } else {
      return false;
    }
  };
  const login = (user) => {
    return new Promise((resolve, reject) => {
      doLogin({ data: user })
        .then((response = {}) => {
          const { token, email, first_name, last_name, id, teams } = response;

          storage.setAuthToken(token);
          storage.setEmail(email);
          storage.setFirstName(first_name);
          storage.setLastName(last_name);
          storage.setId(id ? id : "");
          if (teams?.length > 0) {
            storage.setActiveTeam(teams[0]?.id);
            setActiveTeam(teams[0]?.id);
          }
          setToken(token);
          setEmail(email);
          setFirstName(first_name);
          setLastName(last_name);
          setId(id);

          resolve(response);
        })
        .catch(reject);
    });
  };

  const SociaLogin = (user) => {
    return new Promise((resolve, reject) => {
      doSocialLogin({ data: user })
        .then((response = {}) => {
          const { token, email, first_name, last_name, id, teams } = response;

          storage.setAuthToken(token);
          storage.setEmail(email);
          storage.setFirstName(first_name);
          storage.setLastName(last_name);
          storage.setId(id ? id : "");
          if (teams?.length > 0) {
            storage.setActiveTeam(teams[0]?.id);
            setActiveTeam(teams[0]?.id);
            storage.setIsAdminTeam(!!teams[0]?.admin_team ? "yes" : "no");
            setIsAdminTeam(!!teams[0]?.admin_team ? "yes" : "no");
          }

          setToken(token);
          setEmail(email);
          setFirstName(first_name);
          setLastName(last_name);
          setId(id);

          resolve(response);
        })
        .catch(reject);
    });
  };

  const signUP = (user, isInvitation = false) => {
    return new Promise((resolve, reject) => {
      const callSignUp = isInvitation ? signUpOnInvitation : doSignUp;
      callSignUp({ data: user })
        .then((response = {}) => {
          const { token, email, first_name, last_name, id } = response;
          storage.setAuthToken(token);
          storage.setEmail(email);
          storage.setFirstName(first_name);
          storage.setLastName(last_name);
          storage.setId(id ? id : "");
          setToken(token);
          setEmail(email);
          setFirstName(first_name);
          setLastName(last_name);
          setId(id);

          resolve(response);
        })
        .catch(reject);
    });
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      storage.clear();
      resolve();
    });
  };

  const handleSetActiveTeam = (id) => {
    storage.setActiveTeam(id);
    setActiveTeam(id);
  };
  const handleSetIsAdminTeam = (bool) => {
    storage.setIsAdminTeam(bool);
    setIsAdminTeam(bool);
  };

  return {
    token,
    email,
    firstName,
    lastName,
    userRole,
    isAuthenticated,
    login,
    signUP,
    SociaLogin,
    logout,
    id,
    imageUrl,
    activeTeam,
    setActiveTeam: handleSetActiveTeam,
    isAdminTeam,
    setIsAdminTeam: handleSetIsAdminTeam,
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
