import { KeyboardArrowDown } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const MuiSelect = (props) => {
  const {
    size = "small",
    label = "",
    value,
    onChange,
    showSelect = false,
    menuItems = [],
    valueKey = "value",
    labelKey = "label",
    menuItemClass = "",
    disabled = false,
    selectClassName = "bg-secondary",
    formControlSx = {},
    ...rest
  } = props;
  const { colors, mode } = useThemeContext();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  return (
    <FormControl size={size} className="w-full" sx={formControlSx}>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        IconComponent={() => (
          <span className="text-tertiary mr-2 cursor-pointer">
            <KeyboardArrowDown
              fontSize="small"
              sx={{ color: formControlSx?.color }}
            />
          </span>
        )}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        className={` w-full ${selectClassName}`}
        size={size}
        fullWidth
        disabled={disabled}
        {...rest}
        sx={{
          borderRadius: "8px",
        }}
        MenuProps={MenuProps}
      >
        {showSelect && !!menuItems?.length && (
          <MenuItem
            sx={{
              background: colors.borderSecondary,
              color: colors.textSecondary,
              "&:hover": {
                background: colors.bgSecondary,
              },
            }}
            title={"Select"}
            value={""}
          >
            Select
          </MenuItem>
        )}
        {!!menuItems?.length ? (
          menuItems?.map((item, index) => (
            <MenuItem
              title={item[labelKey] || item}
              key={index}
              value={item[valueKey] || item}
              sx={{
                background: colors.borderSecondary,
                color: colors.textSecondary,
                "&:hover": {
                  background: colors.bgSecondary,
                },
                "&.Mui-selected": {
                  background: colors.bgPrimary,
                  color: colors.textPrimary,
                },
              }}
            >
              <p
                className={`text-ellipsis  overflow-hidden whitespace-nowrap ${menuItemClass}`}
              >
                {item[labelKey] || item}
              </p>
            </MenuItem>
          ))
        ) : (
          <MenuItem
            sx={{
              background: colors.borderSecondary,
              color: colors.textSecondary,
              "&:hover": {
                background: colors.bgSecondary,
              },
              "&.Mui-selected": {
                background: colors.bgPrimary,
                color: colors.textPrimary,
              },
            }}
            title={"No Options"}
            value={""}
          >
            No Options
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default MuiSelect;
