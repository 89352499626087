import { Close } from "@mui/icons-material";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const FileUploadButton = (props) => {
  const {
    value,
    children,
    onChange,
    accept = "image/*",
    onBlur = () => {},
    disabled = false,
    id = "",
    variant = "secondary",
    showFilename = true,
    onClear = null,
  } = props;
  const { colors } = useThemeContext();
  const style = {
    secondary: {
      padding: "8px 15px",
      borderRadius: "8px",
      color: colors.textSecondary,
      background: colors.bgSecondary,
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      border: `1px solid ${colors.borderSecondary}`,
      fontSize: "14px",
      lineHeight: 1.5,
    },
    primary: {
      padding: "8px 15px",
      borderRadius: "8px",
      color: colors.textPrimary,
      background: colors.bgPrimary,
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      border: `1px solid ${colors.borderPrimary}`,
      fontSize: "14px",
      lineHeight: 1.5,
    },
    outlined: {
      padding: "8px 15px",
      borderRadius: "8px",
      color: colors.textPrimary,
      background: colors.bgPrimary,
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      border: `1px solid ${colors.borderPrimary}`,
      fontSize: "14px",
      lineHeight: 1.5,
    },
  };
  return (
    <div className="w-full">
      <div className="w-full flex">
        <label htmlFor={id} style={style[variant]}>
          {" "}
          {children}
        </label>

        <input
          hidden
          type="file"
          id={id}
          accept={accept}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          style={{
            width: "100%",
          }}
        />
      </div>
      {!!value?.name && showFilename && (
        <p className="text-sm mt-2">
          {value?.name}{" "}
          {onClear && (
            <span className="cursor-pointer" onClick={onClear}>
              <Close fontSize="small" />
            </span>
          )}
        </p>
      )}
    </div>
  );
};

export default FileUploadButton;
