import { LinearProgress, Modal, Paper, Typography } from "@mui/material";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import { Isotype } from "./IconPack";

const BlockingLoader = (props) => {
  const { colors } = useThemeContext();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    p: 4,
    bgcolor: colors.bgPrimary,
    boxShadow: 24,
    borderRadius: "8px",
  };

  return (
    <Modal
      disableAutoFocus={true}
      open={props.loading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper className="bg-primary" sx={style}>
        <div className="flex justify-center items-center w-full mb-5">
          <Isotype height={80} width={280} />
        </div>
        <LinearProgress sx={{ borderRadius: "8px", height: "8px" }} />
        {props.message ? (
          <Typography className="mt-m">{props.message}</Typography>
        ) : null}
      </Paper>
    </Modal>
  );
};

export default BlockingLoader;

// import { CircularProgress } from "@mui/material";
// import "@Styles/index.css";
// import LogoSqure from "@Images/logo_squre.png";
// const BlockingLoader = (props) => {
//   if (!props.loading) return null;

//   return (
//     <div className="blocking-loader">
//       <CircularProgress />
//     </div>
//   );
// };

// const BlockingLoaderScrapping = (props) => {
//   if (!props.loading) return null;

//   return (
//     <div className="blocking-loader">
//       <div>
//         <div className="  flex flex-col ">
//           <div className="flex justify-center items-center  my-auto h-full">
//             <img style={{ height: "48px", width: "48px" }} src={LogoSqure} />{" "}
//           </div>
//           <p className="lg-semibold p-700 my-4 flex gap-3 justify-center ">
//             Scanning website
//             <div className="mt-3">
//               <div class="loaderball"></div>
//             </div>
//           </p>
//         </div>
//         <div className="sm-normal  g-500 mb-4  flex flex-col  justify-center  items-center text-justify">
//           <div className="w-80 items-center flex   justify-center ">
//             Please wait a few seconds while we scan this
//           </div>
//           <div>website for an article that you can save.</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BlockingLoader;
// export { BlockingLoaderScrapping };
