import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DashboardAnalytics from "../../common/DashboardAnalytics";
import PromptLogs from "./PromptLogs";

const PromptDashboard = () => {
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice?.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  return (
    <div>
      <DashboardAnalytics promptId={promptData?.id} />
      <Box p={5} pt={0} maxWidth={1250} m={"auto"} overflow={"auto"}>
        <div className="bg-secondary border-1 rounded-lg border-secondary p-5">
          <div className="bg-primary rounded-lg">
            <PromptLogs />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default PromptDashboard;
