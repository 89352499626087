import snackbarService from "@Services.App/snackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleLoading } from "../../../../store/collectionsSlice";

import { Button, CircularProgress } from "@mui/material";
import {
  getAssistanceVersion,
  getPromptVersions,
} from "../../../../services/app/api";
import MuiSelect from "../../../UI/MuiSelect";
import AddToWeb from "../../assistant/AddToWeb";
const ExtensionsSelect = ({ onclose }) => {
  const [editMode, setEditMode] = useState(false);
  const [allPromptVersion, setAllPromptVersion] = useState([]);
  const [selectedVerion, setSelectedVersion] = useState("");
  const [htmlCode, setHtmlCode] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [openAddToWeb, setOpenAddToWeb] = useState(false);
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (promptData?.id?.includes("newPromptId")) {
      setEditMode(true);
    } else {
      setLoading(true);
      getPromptVersions(promptData?.id)
        .then((res) => {
          var data_list = [];
          res.items.map((item) => {
            var em = {
              name: item.version_info?.version_name,
              value: item?.prompt_id,
            };
            data_list.push(em);
          });
          setAllPromptVersion([...data_list]);
        })
        .catch((error) => {
          snackbarService.error({
            title: `${error.error && error.error.toString()}`,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [promptData?.id]);

  const handleGetAssistance = () => {
    setHtmlCode("");
    dispatch(handleLoading(true));
    getAssistanceVersion(selectedVerion, selectedType)
      .then((res) => {
        setHtmlCode(res.tag_html);
      })
      .catch((error) => {
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  if (loading) {
    return (
      <div className="h-60 flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bg-secondary rounded-lg m-2 h-[90vh] text-primary">
      <AddToWeb
        open={openAddToWeb}
        handleClose={() => setOpenAddToWeb(false)}
      />
      {editMode && !loading ? (
        <div className="bg-primary m-2 rounded-lg p-6 h-96 w-[94%] flex items-center justify-center">
          <p className=" text-center text-tertiary text-sm">
            Prompt is not saved to Add prompt to a website please save and
            publish collection version
          </p>
        </div>
      ) : allPromptVersion.length == 0 ? (
        <div>
          <div className=" text-center bg-primary m-2 rounded-lg p-6 h-96 w-[94%] flex items-center justify-center">
            <p className=" text-tertiary text-sm">
              Please publish version of collection to generate add prompt to a
              website
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-3">
            <div className="text-textSecondary text-sm font-medium mb-1">
              Select version of Prompt
            </div>
            <MuiSelect
              label={""}
              menuItems={allPromptVersion}
              value={selectedVerion}
              onChange={(e) => setSelectedVersion(e.target.value)}
              // error={errors?.llmModel}
              // onFocus={() => setErrors((ini) => ({ ...ini, llmModel: false }))}
              valueKey={"value"}
              selectClassName={"bg-primary"}
              labelKey={"name"}
            />
          </div>

          <div className=" mt-4 flex  items-center w-full gap-2 text-center">
            <Button
              onClick={() => setOpenAddToWeb(selectedVerion)}
              disabled={!selectedVerion}
              variant="contained"
              fullWidth
            >
              Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtensionsSelect;