import snackbarService from "@Services.App/snackbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteContext } from "../../../../services/app/api";
import {
  addToEditContext,
  fetchContexts,
  handleLoading,
  removeFromEditContext,
  setSearchContext,
} from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import FilterField from "../../../UI/FilterField";
import { DuplicateIcon, EditIcon, TrashIcon } from "../../../UI/IconPack";
import MuiMenu from "../../../UI/MuiMenu";
import MuiModal from "../../../UI/MuiModal";
import SimplePagination from "../../../UI/SimplePagination";

const ContextList = () => {
  const newContextData = useSelector((state) => state.collectionsSlice);
  const { allContexts, searchContext } = newContextData;
  const editContextList = useSelector(
    (state) => state.collectionsSlice.editContextList
  );
  const contextData =
    editContextList.filter((context) => context?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const params = useParams();
  const [openDeleteContext, setOpenDeleteContext] = useState(false);
  const { activeTeam } = useAuth();

  const ContextItem = (context) => {
    const handelAddToEdit = () => {
      dispatch(addToEditContext(context));
    };

    const handleDuplicate = () => {
      dispatch(
        addToEditContext({
          ...context,
          id: `newAssistant${Math.random()}`,
          name: `${context?.name}-Copy`,
        })
      );
    };
    const handleRename = () => {
      handelAddToEdit();
    };
    const handleRemove = () => {
      setOpenDeleteContext(context?.id);
    };

    const menuListItems = [
      {
        icon: <EditIcon />,
        title: "Rename",
        onClick: handleRename,
      },
      { icon: <DuplicateIcon />, title: "Duplicate", onClick: handleDuplicate },
      {
        icon: <TrashIcon color={"#F97066"} />,
        title: <span className="text-error">Remove</span>,
        onClick: handleRemove,
      },
    ];
    return (
      <div
        key={context?.id}
        title={context?.name}
        onClick={handelAddToEdit}
        className={`group/item border-y-[0.5px] border-primary pl-4 cursor-pointer ${
          contextData?.id === context?.id && "bg-quaternary"
        } `}
      >
        <div className="flex items-center my-2 justify-between h-8 w-64">
          <p className="text-sm text-primary  whitespace-nowrap overflow-hidden text-ellipsis">
            {context?.name}
          </p>
          <div className="invisible group-hover/item:visible">
            <MuiMenu menuListItems={menuListItems} />
          </div>
        </div>
      </div>
    );
  };

  const handleNextPageContext = () => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: allContexts?.page + 1,
        limit: 10,
        searchKey: searchContext,
      },
    };
    dispatch(fetchContexts(payload));
  };
  const handlePrevPagePrompts = () => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: allContexts?.page - 1,
        limit: 10,
        searchKey: searchContext,
      },
    };
    dispatch(fetchContexts(payload));
  };

  const handleDeleteContext = () => {
    dispatch(handleLoading(true));
    deleteContext(openDeleteContext)
      .then((res) => {
        var payload = {
          team_id: activeTeam,
          params: {
            page: allContexts?.page || 1,
            limit: 10,
            searchKey: searchContext,
          },
        };
        dispatch(fetchContexts(payload));
        dispatch(removeFromEditContext(openDeleteContext));
      })
      .catch((error) => {
        console.log(error);
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {
        dispatch(handleLoading(false));
        setOpenDeleteContext(false);
      });
  };

  const handleResetSearch = () => {
    dispatch(setSearchContext(""));
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    dispatch(fetchContexts(payload));
  };
  return (
    <div className="relative top-0 border-1 h-full border-primary">
      <div className="p-2  w-full flex gap-2 sticky top-0 z-50 bg-secondary">
        <FilterField
          value={searchContext}
          onChange={(e) => dispatch(setSearchContext(e.target.value))}
          onReset={handleResetSearch}
        />
      </div>
      <MuiModal
        open={!!openDeleteContext}
        handleClose={() => setOpenDeleteContext(false)}
        title={"Delete Context"}
        message={
          "Are you sure you want to delete this context? This action cannot be undone."
        }
        handleConfirm={handleDeleteContext}
        showDeleteIcon={true}
      />
      <div style={{ maxWidth: "100%" }}>
        {allContexts?.items?.length > 0 &&
          allContexts?.items?.map((prompt, index) => (
            <ContextItem key={prompt?.id} {...{ ...prompt, index }} />
          ))}
      </div>
      {allContexts?.pages > 1 && (
        <div className=" absolute bottom-0 z-50 flex justify-around items-center w-full border-t-1 bg-secondary border-primary">
          <SimplePagination
            onPrevious={handlePrevPagePrompts}
            onNext={handleNextPageContext}
            pagination={allContexts}
          />
        </div>
      )}
    </div>
  );
};

export default ContextList;
