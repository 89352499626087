import util from "@Services.Core/util";
import {
    INFO,
    WARNING,
    SUCCESS,
    ERROR,
    CONFIRM,
    CANCEL,
    SUBMIT,
} from "@Components/UI/DialogManager";

let manager;

const objectify = (props) =>
    util.isString(props) ? { content: props } : props;

const setManager = (mgr) => (manager = mgr);

const custom = (props) => manager.showDialog(props);

const info = (props) => {
    const _props = Object.assign({ type: INFO, title: "Info", actions: [{ type: SUBMIT, label: "OK" }] },
        objectify(props)
    );
    return new Promise((resolve) => {
        _props.onClose = () => resolve();
        manager.showDialog(_props);
    });
};

const warning = (props) =>
    info(Object.assign({ type: WARNING, title: "Warning" }, objectify(props)));

const success = (props) =>
    info(Object.assign({ type: SUCCESS, title: "Success" }, objectify(props)));
const planDialog = (props) =>
    info(Object.assign({ type: "NONE", title: "NONE" }, objectify(props)));

const planDialogscrollEnable = (props) =>
    info(Object.assign({ type: "NONE", title: "NONEK" ,scroll:true}, objectify(props)));
const blackDialog = (props) =>
    info(
        Object.assign({ type: "NONE", title: "NONE", dark: true }, objectify(props))
    );

const error = (props) =>
    info(Object.assign({ type: ERROR, title: "Error" }, objectify(props)));

const confirm = (props) => {
    let isConfirmed = false;
    const _props = Object.assign({
            title: "Confirm",
            actions: [
                { type: CANCEL, label: "Cancel" },
                { type: SUBMIT, label: "OK", onClick: () => (isConfirmed = true) },
            ],
        },
        objectify(props)
    );

    return new Promise((resolve) => {
        _props.onClose = () => resolve(isConfirmed);
        manager.showDialog({..._props, type: CONFIRM });
    });
};

const close = (dialog) => manager.close(dialog);

const subscribe = (dialog, subscriber) => manager.subscribe(dialog, subscriber);

const unsubscribe = (dialog) => manager.unsubscribe(dialog);

const valid = (dialog, valid) => manager.valid(dialog, valid);

export default {
    setManager,
    planDialog,
    custom,
    info,
    warning,
    success,
    error,
    confirm,
    close,
    subscribe,
    unsubscribe,
    valid,
    blackDialog,
    planDialogscrollEnable
};