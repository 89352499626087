import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { FileTypeIcon } from "../../../UI/IconPack";
import CsvOutput from "../formatters/CsvOutput";
import ImageOutput from "../formatters/ImageOutput";
import JsonOutput from "../formatters/JsonOutput";
import OutputFormat from "../formatters/OutputFormat";
import XmlOutput from "../formatters/XmlOutput";

const PromptOutput = (props) => {
  const { outputFormatError, outputFormatOnFocus, isReadOnlyMode } = props;
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  return (
    <>
      <Grid container px={10} py={2} spacing={2}>
        {!isReadOnlyMode && (
          <>
            <Grid item md={3.5}>
              <div className={"text-secondary font-semibold text-sm"}>
                Response format
              </div>
              <div className={"text-tertiary text-sm"}>
                Set output format to get results accordingly
              </div>
            </Grid>
            <Grid item md={8.5}>
              <OutputFormat
                error={outputFormatError}
                onFocus={outputFormatOnFocus}
              />
            </Grid>
          </>
        )}

        {!!promptData?.promptOutput && (
          <>
            {!isReadOnlyMode && (
              <div className="w-full border-t-1 border-secondary mt-4" />
            )}
            <Grid item md={3.5}>
              <div className={"text-secondary font-semibold text-sm"}>
                Response
              </div>
              <div className={"text-tertiary text-sm"}>
                Response for given prompt
              </div>
            </Grid>
            <Grid item md={8.5}>
              <div className={`relative`}>
                <div>
                  {promptData?.content_type === "Image" && (
                    <ImageOutput promptData={promptData} />
                  )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "json" && (
                      <JsonOutput promptData={promptData} />
                    )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "csv" && (
                      <CsvOutput promptData={promptData} />
                    )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "xml" && (
                      <XmlOutput promptData={promptData} />
                    )}
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>

      {isReadOnlyMode && !promptData?.promptOutput && (
        <div className="relative flex items-center justify-center ">
          <FileTypeIcon />
          <div className="absolute bottom-32 text-base text-center  text-primary">
            Run prompt to see response
          </div>
        </div>
      )}
    </>
  );
};

export default PromptOutput;
