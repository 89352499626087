import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { emptyContextData } from "../../../../services/constants";
import {
  addToEditContext,
  handleChangEditContext,
  removeFromEditContext,
} from "../../../../store/collectionsSlice";
import AppTabs from "../../../UI/AppTabs";
import { FileTypeIcon } from "../../../UI/IconPack";
import NewContext from "./NewContext";

const ContextEditor = () => {
  const newContextData = useSelector((state) => state.collectionsSlice);
  const { editContextList, allContexts, searchContext } = newContextData;
  const contextData =
    editContextList.filter((context) => context?.isActiveTab)[0] || {};
  const dispatch = useDispatch();

  const handleCloseContext = (e, context) => {
    dispatch(removeFromEditContext(context?.id));
    e.preventDefault();
  };

  const handleSetActiveTab = (e, context) => {
    dispatch(addToEditContext(context));
    e.preventDefault();
  };

  const handleDispatchContextName = (e) => {
    let data = {
      ...contextData,
      name: e.target.value,
    };
    dispatch(handleChangEditContext(data));
  };

  return (
    <div className="h-full">
      <AppTabs
        list={editContextList}
        onBlur={handleDispatchContextName}
        handleAddNew={(e, scroll) => handleSetActiveTab(e, emptyContextData())}
        handleCloseTab={handleCloseContext}
        handleSetActiveTab={handleSetActiveTab}
      />
      {editContextList?.length === 0 ? (
        <div className="text-4xl text-center h-[93.5%] bg-primary flex flex-col justify-center items-center">
          <div className="relative flex flex-col items-center justify-center">
            <FileTypeIcon />
            <div className="absolute bottom-14">
              <p className="text-sm-semibold  text-primary mt-4">
                Nothing to see here
              </p>
              <p className="text-sm text-tertiary z-10">
                Provide the details below to get started.
              </p>
              <div className="my-4 flex items-center justify-center">
                <Button
                  variant="contained"
                  onClick={(e, scroll) =>
                    handleSetActiveTab(e, emptyContextData())
                  }
                >
                  Create context
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <NewContext />
        </>
      )}
    </div>
  );
};

export default ContextEditor;
