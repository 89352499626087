import PubSub from "pubsub-js";
import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import util from "@Services.Core/util";

export const INFO = "info";
export const WARNING = "warning";
export const SUCCESS = "success";
export const ERROR = "error";
export const CONFIRM = "confirm";

export const CANCEL = "cancel";
export const SUBMIT = "submit";

class DialogManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogs: [] };
  }

  newKey() {
    return `dialog__${util.generateGuid()}`;
  }

  subscribe({ key } = {}, subscriber) {
    if (!key || !subscriber) return;
    const token = PubSub.subscribe(key, subscriber);
    this.setState({
      dialogs: this.getDialogs().map((dialog) =>
        dialog.key === key ? { ...dialog, token } : dialog
      ),
    });
  }

  unsubscribe({ key } = {}) {
    if (!key) return;
    const dialog = this.getDialogs().find((dialog) => dialog.key === key);
    // Imp: Necessary to delay the unsubscribe
    ((dialog) =>
      window.setTimeout(
        () => dialog && dialog.token && PubSub.unsubscribe(dialog.token),
        0
      ))(dialog);
  }

  closeDialog(e, { key, onClose } = {}, reason = "") {
    if (!key) return;
    if (
      this.getDialogs().filter(
        (dialog) => dialog.contentProps?.escapable != true
      ).length != 0 ||
      reason !== "backdropClick"
    ) {
      this.unsubscribe({ key });
      onClose && onClose(e);

      this.setState({
        dialogs: this.getDialogs().filter((dialog) => dialog.key !== key),
      });
    }
  }

  showDialog(props = {}) {
    const key = this.newKey();
    this.setState({
      dialogs: this.getDialogs().concat({ ...props, key, valid: true }),
    });
    return { key };
  }

  valid({ key } = {}, valid = true) {
    this.setState({
      dialogs: this.getDialogs().map((dialog) =>
        dialog.key === key ? { ...dialog, valid } : dialog
      ),
    });
  }

  getDialogs() {
    return this.state.dialogs;
  }

  getTitle(dialog = {}) {
    return dialog.title || "Dialog";
  }

  getContent(dialog = {}) {
    const { key, content, contentProps = {} } = dialog;
    const dialogProps = {
      dialog: {
        key,
        subscribe: this.subscribe.bind(this, dialog),
        unsubscribe: this.unsubscribe.bind(this, dialog),
        valid: this.valid.bind(this, dialog),
        close: this.closeDialog.bind(this, null, dialog),
      },
    };

    if (util.isString(content)) {
      return <Typography>{content}</Typography>;
    } else if (React.isValidElement(content)) {
      return React.cloneElement(dialog.content, {
        ...contentProps,
        ...dialogProps,
      });
    } else {
      return React.cloneElement(<dialog.content {...contentProps} />, {
        ...dialogProps,
      });
    }
  }

  getActions(dialog = {}) {
    const { actions = [] } = dialog;

    let cancelAction = { type: CANCEL, label: "Cancel", color: "primary" };
    let submitAction = {
      type: SUBMIT,
      label: "Submit",
      color: "primary",
      variant: "contained",
      disabled: !Boolean(dialog.valid),
    };

    return actions.length
      ? actions.map((action) =>
          action.type === CANCEL
            ? { ...cancelAction, ...action }
            : action.type === SUBMIT
            ? { ...submitAction, ...action }
            : action
        )
      : [cancelAction, submitAction];
  }

  renderTitle(dialog = {}) {
    return <DialogTitle>{this.getTitle(dialog)}</DialogTitle>;
  }

  renderContent(dialog = {}) {
    return (
      <DialogContent
        style={{
          padding: "0px",
          border: "none",
          borderRadius: "8px",
        }}
        dividers={true}
      >
        {this.getContent(dialog)}
      </DialogContent>
    );
  }

  renderActions(dialog = {}) {
    const buttons = this.getActions(dialog).map((action) => (
      <Button
        style={{ backgroundColor: "#2C2F33" }}
        {...action}
        onClick={(e) => {
          PubSub.publish(dialog.key, { e, action });
          if (action.onClick) action.onClick(e);
          if (
            dialog.type === INFO ||
            dialog.type === WARNING ||
            dialog.type === SUCCESS ||
            dialog.type === ERROR ||
            dialog.type === CONFIRM ||
            action.type === CANCEL
          )
            this.closeDialog(e, dialog);
        }}
      >
        {action.label}
      </Button>
    ));
    return <DialogActions className="bg-gray-50">{buttons}</DialogActions>;
  }

  renderDialog(dialog = {}) {
    return (
      <Dialog
        disableScrollLock={dialog.title == "NONEK" ? true : false}
        maxWidth="xl"
        key={dialog.key}
        open={true}
        fullScreen={Boolean(dialog.fullScreen)}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={(e, reason) => this.closeDialog(e, dialog, reason)}
      >
        {dialog.title != "NONEK" && dialog.title != "NONE"
          ? this.renderTitle(dialog)
          : null}
        {this.renderContent(dialog)}
        {dialog.type != "NONE" ? this.renderActions(dialog) : null}
      </Dialog>
    );
  }

  render() {
    console.debug("==================== DialogManager");
    console.debug("dialogs", this.state.dialogs);
    return this.getDialogs().map((dialog) => this.renderDialog(dialog));
  }
}

export default DialogManager;
