import { ReactComponent as SaveIcon } from "@Images/FloppyDisk.svg";
import { Button } from "@mui/material";
import React from "react";
import { downloadJSON } from "../../../../services/generalFunctions";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";

const JsonOutput = (props) => {
  const { promptData } = props;
  const { mode } = useThemeContext();

  return (
    <div className={`p-3  bg-secondary border-1 border-secondary rounded-lg`}>
      <div
        className={`overflow-auto bg-primary border-1 border-primary rounded-lg h-96`}
      >
        <CodeHighlighter
          customStyle={{ background: "transparent" }}
          style={mode === "dark" ? "vs2015" : "a11yLight"}
          language={"json"}
          wrapLongLines={true}
          wrapLines={true}
        >
          {JSON.stringify(promptData?.promptOutput, undefined, 2)}
        </CodeHighlighter>
      </div>

      <div className="flex justify-end mt-3">
        <Button
          disabled={!promptData?.promptOutput}
          onClick={() =>
            downloadJSON(promptData?.promptOutput, promptData?.name)
          }
          variant="outlined"
          color="secondary"
          endIcon={<SaveIcon />}
        >
          Save output
        </Button>
      </div>
    </div>
  );
};

export default JsonOutput;
