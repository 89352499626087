import React from "react";

import CloseIcon from "@mui/icons-material/ExpandMore";
import {
  MenuItem,
  Divider,
  MenuProps,
  styled,
  alpha,
  Menu,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropsSelectBox = {
  PaperProps: {
    square: true,
    elevation: 1,
    sx: {
      "&::-webkit-scrollbar": {
        width: 2,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: `1px solid slategrey`,
      },
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
const StyledMenu = styled((MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...MenuProps}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenu2 = styled((MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...MenuProps}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,

    border: "1px solid #F2F4F7",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    minWidth: 240,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledMenuItem = styled((MenuProps) => <MenuItem {...MenuProps} />)(
  ({ theme }) => ({
    padding: "10px 16px",
    borderRadius: "6px",
    marginTop: "0.2rem",
    "&.Mui-selected": {
      // this is to refer to the prop provided by M-UI
      backgroundColor: "#E2E8F3", // updated backgroundColor

      // fontFamily: "Inter",
      // fontStyle: "normal",
      // fontWeight: "500",
      // fontSize: "14px",
      // lineHeight: "20px",
      color: "#000000",
    },
    "&:hover": {
      backgroundColor: "#E2E8F3",
      color: "#344054",
    },
  })
);

const BlackThemeBtn = styled((BtnProps) => <Button {...BtnProps} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: "14px",
    padding: "10px 16px",
    border: "1px solid #525252",
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    lineHeight: "20px",
    backgroundColor: "#292929",
    fontWeight: "500",
    fontStyle: "normal",
    color: "#D6D6D6",
    // borderColor: '#0063cc',
    fontFamily: [
      "Inter",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#FAFAFA",
      borderColor: "#FAFAFA",
      color: "#141414",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    },
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#0062cc',
    //   borderColor: '#005cbf',
    // },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
  })
);

const WhiteBlueOverBtn = styled((BtnProps) => <Button {...BtnProps} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: "14px",
    padding: "10px 16px",
    border: "1px solid #525252",
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    lineHeight: "20px",
    backgroundColor: "#292929",
    fontWeight: "500",
    fontStyle: "normal",
    color: "#D6D6D6",
    // borderColor: '#0063cc',
    fontFamily: [
      "Inter",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#FAFAFA",
      borderColor: "#FAFAFA",
      color: "#141414",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    },
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#0062cc',
    //   borderColor: '#005cbf',
    // },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
  })
);

const CustomTextField = styled((BtnProps) => (
  <TextField
    inputProps={{
      style: {
        fontFamily: [
          "Inter",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 14,
        // height: 44,
        padding: "10px 7px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",

        // borderRadius: "56px",
      },
    }}
    fullWidth
    variant="outlined"
    {...BtnProps}
  />
))(({ theme }) => ({
  // padding: "10px 14px",
  // border: "1px solid #D0D5DD",

  "& fieldset": {
    border: "1px solid rgba(51, 55, 65, 1)",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:hover + fieldset": {
    // border: "1px solid rgba(51, 55, 65, 1)",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:focus + fieldset": {
    // border: `2px solid yellow`,
    borderRadius: "8px",
  },
  // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  // color: "#D6D6D6",
}));

const NormalCoustomTextField = styled((BtnProps) => (
  <TextField
    inputProps={{
      style: {
        fontFamily: [
          "Inter",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 10,
        // height: 44,

        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "10px",

        // borderRadius: "56px",
      },
    }}
    fullWidth
    variant="outlined"
    {...BtnProps}
  />
))(({ theme }) => ({
  // padding: "10px 14px",
  // border: "1px solid #D0D5DD",

  "& fieldset": {
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:hover + fieldset": {
    // border: `2px solid green`,
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:focus + fieldset": {
    // border: `2px solid yellow`,
    borderRadius: "8px",
  },
  // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  // color: "#D6D6D6",
}));

const StyledMenu1 = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    style={{ zIndex: 4200 }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 10,
    },
    "& .MuiMenuItem-root": {
      fontSize: 13,
      fontWeight: "200",
      "& .MuiSvgIcon-root": {
        fontSize: 10,

        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomeSelectToolbar = ({
  valueObj,
  handleChange,
  placeHolder,
  dataObj,
  renderSelectfunc,

  keyEle,
  disableValueList = [],
}) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-multiple-checkbox-labeaaal"
        id="demo-multiple-checkbox66"
        multiple
        value={valueObj}
        onChange={handleChange}
        placeholder="select style"
        input={<OutlinedInput size="small" sx={{ borderRadius: "8px" }} />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="text-xs">{placeHolder}</em>;
          }

          return selected.map(renderSelectfunc).join(", ");
        }}
        displayEmpty
        MenuProps={MenuPropsSelectBox}
        // inputProps={{ "aria-label": "Without label" }}
        sx={{
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.3)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
          },
          padding: "0px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "20px",
        }}
      >
        {dataObj.map((item) => (
          <MenuItem
            key={item.id}
            value={item}
            sx={{
              padding: "0px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "12px",
              lineHeight: "20px",
            }}
            disabled={
              disableValueList.map(renderSelectfunc).indexOf(item[keyEle]) > -1
            }
          >
            <Checkbox
              size="small"
              checked={
                valueObj.map(renderSelectfunc).indexOf(item[keyEle]) > -1
              }
            />
            <ListItemText
              sx={{
                padding: "0px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "12px",
                lineHeight: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                {item[keyEle]}
              </div>
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CustomeSingleToolbarObject = ({
  valueObj,
  handleChange,
  placeHolder,
  dataObj,
  renderSelectfunc,

  keyEle,
  disableValueList = [],
}) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-multiple-checkbox-labeaaal"
        id="demo-multiple-checkbox66"
        value={valueObj}
        onChange={handleChange}
        placeholder="select style"
        input={<OutlinedInput size="small" sx={{ borderRadius: "8px" }} />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="text-xs">{placeHolder}</em>;
          }

          return selected[keyEle];
        }}
        displayEmpty
        MenuProps={MenuPropsSelectBox}
        // inputProps={{ "aria-label": "Without label" }}
        sx={{
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.3)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
          },
          padding: "0px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "20px",
        }}
      >
        {dataObj.map((item) => (
          <MenuItem
            key={item.id}
            value={item}
            sx={{
              padding: "0px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "12px",
              lineHeight: "20px",
            }}
            disabled={
              disableValueList.map(renderSelectfunc).indexOf(item[keyEle]) > -1
            }
          >
            <Checkbox
              size="small"
              checked={valueObj[keyEle]?.indexOf(item[keyEle]) > -1}
            />
            <ListItemText
              sx={{
                padding: "0px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "12px",
                lineHeight: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                {item[keyEle]}
              </div>
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CustomeSelectSingleToolbar = ({
  valueObj,
  handleChange,
  placeHolder,
  dataObj,
}) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-multiple-checkbox-labeaaal"
        id="demo-multiple-checkbox66"
        value={valueObj}
        onChange={handleChange}
        placeholder="select style"
        input={<OutlinedInput size="small" sx={{ borderRadius: "8px" }} />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="text-xs">{placeHolder}</em>;
          }

          return selected.join(", ");
        }}
        displayEmpty
        MenuProps={MenuPropsSelectBox}
        // inputProps={{ "aria-label": "Without label" }}
        sx={{
          "&::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.3)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
          },
          padding: "0px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "12px",
          lineHeight: "20px",
        }}
      >
        {dataObj.map((item) => (
          <MenuItem
            key={item.id}
            value={item}
            sx={{
              padding: "0px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "12px",
              lineHeight: "20px",
            }}
          >
            <Checkbox size="small" checked={valueObj.indexOf(item) > -1} />
            <ListItemText
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "12px",
                lineHeight: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                {item}
              </div>
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Accordion1 = styled((AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...AccordionProps} />
))(({ theme }) => ({
  border: `1px solid #D0D5DD`,
  border: "1px solid #D0D5DD",
  borderRadius: "8px",
  padding: "0px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  "&:before": {
    display: "none",
  },
  backgroundColor: "white",
}));
const AccordionSummary1 = styled((AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<GroupAddIcon sx={{ fontSize: "0.9rem" }} />}
    {...AccordionSummaryProps}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#F7FBFB" : "white",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(0),
  },
  paddingLeft: theme.spacing(2),
  borderRadius: "8px",
}));
const AccordionDetails1 = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));
export default StyledMenu;
export {
  StyledMenu2,
  StyledMenuItem,
  BlackThemeBtn,
  CustomTextField,
  NormalCoustomTextField,
  StyledMenu1,
  CustomeSelectToolbar,
  CustomeSelectSingleToolbar,
  AccordionDetails1,
  AccordionSummary1,
  Accordion1,
  CustomeSingleToolbarObject,
};
