import { Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import ContextEditor from "./context/ContextEditor";
import EnvironmentEditor from "./environment/EnvironmentEditor";
import PromptDrawer from "./prompt/PromptDrawer";
import PromptEditor from "./prompt/PromptEditor";
import CollectionDrawer from "./sidebar/CollectionDrawer";

const Collections = () => {
  const [openCollectionDrawer, setOpenCollectionDrawer] = useState(null);
  const [openPromptDrawer, setOpenPromptDrawer] = useState(true);
  const activeWindow = useSelector(
    (state) => state.collectionsSlice.activeWindow
  );

  const handleOpenCollectionDrawer = (name) => {
    setOpenCollectionDrawer(name);
  };
  const handleCloseCollectionDrawer = () => {
    setOpenCollectionDrawer(null);
  };
  const handlePromptDrawer = () => setOpenPromptDrawer(!openPromptDrawer);
  const handleOpenPromptDrawer = () => setOpenPromptDrawer(true);

  const containerSize =
    openCollectionDrawer && openPromptDrawer
      ? 6
      : openCollectionDrawer || openPromptDrawer
      ? 8.5
      : 11;

  return (
    <div className="w-full h-full">
      <Grid container>
        <Grid item md={0.5}>
          <PromptDrawer
            handlePromptDrawer={handlePromptDrawer}
            openPromptDrawer={openPromptDrawer}
            handleOpenPromptDrawer={handleOpenPromptDrawer}
          />
        </Grid>
        <Grid className="bg-primary" item md={11}>
          {activeWindow === "collection" && <PromptEditor />}
          {activeWindow === "environment" && <EnvironmentEditor />}
          {activeWindow === "context" && <ContextEditor />}
        </Grid>
        <Grid item md={0.5}>
          <CollectionDrawer
            openCollectionDrawer={openCollectionDrawer}
            handleOpenCollectionDrawer={handleOpenCollectionDrawer}
            handleCloseCollectionDrawer={handleCloseCollectionDrawer}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Collections;
