import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../Images/search-lg.svg";
import { getCollectionVersions } from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import { formatTimestampToDate } from "../../../services/generalFunctions";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import FilledTextInput from "../../UI/FilledTextField";
import { ClockCountIcon, ClockIcon, FileIcon } from "../../UI/IconPack";
import MuiModal from "../../UI/MuiModal";
import SimplePagination from "../../UI/SimplePagination";

const CollectionVersions = (props) => {
  const { handleModal, open, collectionId = null, collectionName = "" } = props;
  const params = useParams();
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [searchVersion, setSearchVersion] = useState("");
  const { mode } = useThemeContext();

  const callVersions = (apiParams) => {
    setLoading(true);
    getCollectionVersions({
      collection_Id: !!collectionId ? collectionId : params?.collectionId,
      params: {
        limit: 5,
        ...apiParams,
      },
    })
      .then((res) => {
        setPaginationData(res);
        if (res?.items) {
          setVersions(res?.items);
        }
      })
      .catch((error) => {
        snackbar.error({
          title: `${error?.error && error?.error?.toString()}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (collectionId || params?.collectionId) {
      callVersions();
    }
  }, [params?.collectionId, open]);
  const handleNext = () => {
    let params = { page: paginationData?.page + 1 };
    callVersions(params);
  };
  const handlePrevious = () => {
    let params = { page: paginationData?.page - 1 };
    callVersions(params);
  };
  useEffect(() => {
    setVersions([]);
    let search = setTimeout(() => {
      if (collectionId || params?.collectionId) {
        let params = { page: 1, searchKey: searchVersion };
        callVersions(params);
      }
    }, 600);
    return () => clearTimeout(search);
  }, [searchVersion]);

  return (
    <MuiModal
      open={open}
      handleClose={() => {
        setVersions([]);
        handleModal();
      }}
    >
      <div elevation={2} className="w-full">
        <div className="relative">
          <div className="p-4 flex justify-between items-start m-2">
            <div className="flex items-center gap-4">
              <div className="rounded-full p-4 text-brandPrimary-light dark:text-textPrimary bg-brandSecondary-light dark:bg-brandSecondary">
                <ClockCountIcon height={24} width={24} />
              </div>
              <div>
                <Typography
                  id="modal-modal-title"
                  variant="p"
                  className="text-[26px] text-primary"
                >
                  Version history
                </Typography>
                <p className="text-sm-regular text-tertiary">
                  {collectionName}
                </p>
              </div>
            </div>
            <div>
              <IconButton onClick={handleModal} size="small">
                <CloseIcon className="text-tertiary" />
              </IconButton>
            </div>
          </div>
          <div className="px-6 flex justify-end items-center ">
            <div>
              <FilledTextInput
                placeholder={"Search"}
                type="text"
                name="title"
                fullWidth
                variant="outlined"
                size="small"
                value={searchVersion}
                onChange={(e) => setSearchVersion(e.target.value)}
                startIcon={<SearchIcon height={18} width={18} />}
              />
            </div>
          </div>
          <div>
            <div className="p-10 pb-4 max-h-72 overflow-y-auto ">
              {!loading ? (
                versions?.map((version, index) => (
                  <div
                    key={version?.id}
                    className="flex items-center justify-between mb-6"
                  >
                    <div className="flex items-center gap-4">
                      <div className="relative">
                        <ClockIcon
                          color={
                            version?.current_active ? "#00FF75" : "#8996AF"
                          }
                        />
                        {versions?.length - 1 !== index && (
                          <div className="border-[1px] border-borderColor h-10 absolute top-6 left-[7px] " />
                        )}
                      </div>
                      <div>
                        <p>
                          {version?.version_name}{" "}
                          <span className="text-grayEditor400 text-sm">
                            {formatTimestampToDate(
                              version?.updated_at,
                              "MM/DD/YYYY - hh:mm A"
                            )}
                          </span>{" "}
                        </p>
                        <p className="text-base text-grayEditor400">
                          {version?.change_log}
                        </p>
                      </div>
                    </div>
                    {version?.current_active ? (
                      <Button
                        sx={
                          mode === "light" && {
                            color: "#344054",
                            borderColor: "#D0D5DD",
                          }
                        }
                        variant="outlined"
                        color="secondary"
                      >
                        Current
                      </Button>
                    ) : (
                      <Button
                        LinkComponent={Link}
                        href={version?.documentaion_url}
                        target="_blank"
                        variant="outlined"
                        color="secondary"
                        className=" hover:text-textSecondary-light"
                        startIcon={<FileIcon />}
                        sx={
                          mode === "light" && {
                            color: "#344054",
                            borderColor: "#D0D5DD",
                          }
                        }
                      >
                        Documentation
                      </Button>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex w-full justify-center p-10">
                  <CircularProgress color="secondary" />
                </div>
              )}
            </div>

            <div className="flex justify-around items-center w-full border-b-[1px] border-borderColo">
              <SimplePagination
                pagination={paginationData}
                onNext={handleNext}
                onPrevious={handlePrevious}
              />
            </div>

            <div className="flex justify-end m-4 mr-2 sm:mr-6 gap-4 pb-4 ">
              <Button
                onClick={handleModal}
                variant="outlined"
                color="secondary"
                sx={
                  mode === "light" && {
                    color: "#344054",
                    borderColor: "#D0D5DD",
                  }
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MuiModal>
  );
};

export default CollectionVersions;
