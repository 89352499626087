import snackbar from "@Services.App/snackbar";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createContexts, updateContexts } from "../../../../services/app/api";
import {
  fetchContexts,
  handleChangEditContext,
  handleLoading,
  handleUpdateContextId,
} from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import { SaveIcon, TagIcon } from "../../../UI/IconPack";
import MuiSelect from "../../../UI/MuiSelect";
import SuggestionField from "../../../UI/SuggestionField";

const NewContext = () => {
  const newContextData = useSelector((state) => state.collectionsSlice);
  const { editContextList, allContexts, searchContext } = newContextData;
  const contextData =
    editContextList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors, mode } = useThemeContext();
  const { activeTeam } = useAuth();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [variableMenu, setVariableMenu] = useState([]);

  useEffect(() => {
    contextData?.variables?.length &&
      setVariableMenu(
        contextData?.variables.map(({ variable_key }) => ({
          title: variable_key,
          onClick: () => {},
          icon: (
            <span className="text-tertiary">
              {" "}
              <TagIcon />{" "}
            </span>
          ),
        }))
      );
  }, [contextData?.id, contextData?.variables?.length]);

  const validateContext = () => {
    let hasError = false;
    Object.entries(contextData).forEach(([key, value]) => {
      if (value === "") {
        setErrors((ini) => ({ ...ini, [key]: `Please enter ${key}` }));
        hasError = true;
      }
    });
    return hasError;
  };

  const handleSaveContext = async () => {
    if (validateContext()) return;

    let payload = {
      data: {
        name: contextData?.name,
        context: contextData?.context,
        key: contextData?.key,
        role: contextData?.role,
      },
    };
    if (!!contextData?.variables.length) {
      var all_variable = [];
      contextData?.variables.forEach((elem) => {
        var ele = {
          variable_key: elem.variable_key,
          description: elem?.description,
          type: elem.type,
          label: elem.label,
        };
        all_variable.push(ele);
      });
      payload["data"]["variables"] = all_variable;
    }
    let successMessage = "";
    let contextApi = undefined;
    if (contextData?.id?.includes("newContextId")) {
      contextApi = createContexts;
      successMessage = `Context Added`;
      payload["team_id"] = activeTeam;
    } else {
      payload["context_id"] = contextData?.id;
      contextApi = updateContexts;
      successMessage = "Context updated";
    }

    dispatch(handleLoading(true));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    try {
      const res = await contextApi(payload);
      if (successMessage === "Context Added") {
        let data = {
          oldId: contextData?.id,
          ...contextData,
          ...res,
        };
        dispatch(handleUpdateContextId(data));
      }
    } catch (error) {
      console.log(error);
      snackbar.error({
        title: `${error.error && error.error.toString()}`,
      });
    } finally {
      var payload1 = {
        team_id: activeTeam,
        params: {
          page: 1,
          limit: 10,
          searchKey: searchContext,
        },
      };
      dispatch(fetchContexts(payload1));
      dispatch(handleLoading(false));
    }
  };

  return (
    <div className="bg-primary h-[calc(100%-100px)]">
      <div className="w-full border-b-1 border-primary flex justify-end items-center h-16 gap-4 px-4 bg-secondary">
        <Button
          sx={{ background: colors.bgPrimary, height: "40px" }}
          variant="outlined"
          color={"secondary"}
          startIcon={<HorizontalRuleIcon className="-rotate-[70deg]" />}
          onClick={() => navigate("context-variables")}
        >
          Variables
        </Button>
        <Button
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={handleSaveContext}
        >
          Save context
        </Button>
      </div>
      <Grid container spacing={2} px={10} py={5}>
        <Grid item md={4}>
          <div className={"text-secondary font-semibold text-sm"}>
            {t("context")}
          </div>
          <div className={"text-tertiary text-sm"}>{t("what is context")}</div>
        </Grid>
        <Grid item md={8}>
          <SuggestionField
            multiline={true}
            menuListItems={variableMenu}
            label={""}
            minRows={4}
            maxRows={5}
            style={{ background: colors.bgPrimary, padding: "0px" }}
            placeholder={"Enter a context..."}
            value={contextData?.context}
            onChange={(e, value = "") => {
              dispatch(
                handleChangEditContext({
                  ...contextData,
                  context: value ? value : e.target.value,
                })
              );
            }}
            error={errors?.context}
            onFocus={() => setErrors({ ...errors, context: "" })}
            helperText={"Add context"}
          />
        </Grid>
        <Grid item md={4}>
          <div className={"text-secondary font-semibold text-sm"}>
            {t("advance settings")}
          </div>
          <div className={"text-tertiary text-sm"}>Set role</div>
        </Grid>
        <Grid item md={8}>
          <div className="flex gap-4 items-center">
            <div className="w-[30%] text-sm text-tertiary">Role</div>
            <div className="w-[70%]">
              <MuiSelect
                menuItems={[
                  { label: "System", value: "system" },
                  { label: "User", value: "user" },
                ]}
                value={contextData?.role}
                onChange={(e) => {
                  dispatch(
                    handleChangEditContext({
                      ...contextData,
                      role: e.target.value,
                    })
                  );
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewContext;