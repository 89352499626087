import util from "@Services.Core/util";
import {
  INFO,
  WARNING,
  SUCCESS,
  ERROR,
  CONFIRM,
  CANCEL,
  SUBMIT,
} from "@Components/UI/SnackBarManager";

let manager;

const objectify = (props) =>
  util.isString(props) ? { content: props } : props;

const setManager = (mgr) => (manager = mgr);

const info = (props) => {
  const _props = Object.assign({ type: INFO, title: "Info" }, objectify(props));
  return new Promise((resolve) => {
    _props.onClose = () => resolve();
    manager.showSnackbar(_props);
  });
};

const warning = (props) =>
  info(Object.assign({ type: WARNING, title: "Warning" }, objectify(props)));

const success = (props) =>
  info(Object.assign({ type: SUCCESS, title: "Success" }, objectify(props)));

const error = (props) =>
  info(Object.assign({ type: ERROR, title: "Error" }, objectify(props)));

const close = (snackBar) => manager.close(snackBar);

const valid = (snackBar, valid) => manager.valid(snackBar, valid);

export default { setManager, info, warning, success, error, close, valid };
