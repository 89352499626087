import React from "react";
import PubSub from "pubsub-js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Drawer,
} from "@mui/material";

import util from "@Services.Core/util";

export const LEFT = "left";
export const RIGHT = "right";
export const TOP = "top";
export const BOTTOM = "bottom";

class DrawerManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { drawers: [] };
  }

  newKey() {
    return `drawer__${util.generateGuid()}`;
  }

  closeDrawer(e, { key, onClose } = {}) {
    if (!key) return;

    onClose && onClose(e);
    this.setState({
      drawers: this.getDrawers().filter((drawer) => drawer.key !== key),
    });
  }

  showDrawer(props = {}) {
    const key = this.newKey();
    this.setState({
      drawers: this.getDrawers().concat({ ...props, key, valid: true }),
    });
    return { key };
  }

  getDrawers() {
    return this.state.drawers;
  }

  getContent(drawer = {}) {
    const { key, content, contentProps = {} } = drawer;
    const drawerProps = {
      drawer: {
        key,
        close: this.closeDrawer.bind(this, null, drawer),
        onClose: this.closeDrawer.bind(this, null, drawer),
      },
    };

    if (util.isString(content)) {
      return <Typography>{content}</Typography>;
    } else if (React.isValidElement(content)) {
      return React.cloneElement(drawer.content, {
        ...contentProps,
        ...drawerProps,
      });
    } else {
      return React.cloneElement(<drawer.content {...contentProps} />, {
        ...drawerProps,
      });
    }
  }

  renderContent(drawer = {}) {
    return <>{this.getContent(drawer)}</>;
  }

  renderDrawer(drawer = {}) {
    return (
      <Drawer
        key={drawer.key}
        onClose={(e) => this.closeDrawer(e, drawer)}
        anchor={drawer.anchor}
        open={true}
      >
        {this.renderContent(drawer)}
      </Drawer>
    );
  }

  render() {
    console.debug("==================== DrawerManager");
    console.debug("drawers", this.state.drawers);
    return this.getDrawers().map((drawer) => this.renderDrawer(drawer));
  }
}

export default DrawerManager;
