import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "../../../styles/quill-editor.css";

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["link"],
  [{ header: 1 }, { header: 2 }],
];
const modules = {
  toolbar: toolbarOptions,
};
const TextEditor = (props) => {
  const { value, handleChange } = props;
  const [editorValue, setEditorValue] = useState(value || "");
  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  useMemo(() => handleChange(editorValue), [editorValue]);

  return (
    <ReactQuill
      id={"editor-container"}
      value={editorValue}
      theme="bubble"
      onChange={(v) => setEditorValue(v)}
      onBlur={() => handleChange(editorValue)}
      modules={modules}
    />
  );
};

export default TextEditor;
