import { CustomTextField } from "@Components/UI/StyledComponets";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Chip } from "@mui/material";
import React from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const TagsField = (props) => {
  const { onChange, value, label, options = [] } = props;
  const { colors } = useThemeContext();
  return (
    <div>
      {label && <span className="text-tertiary text-sm">{label}</span>}
      <Autocomplete
        clearIcon={false}
        options={options}
        freeSolo
        size="small"
        multiple
        value={value}
        onChange={(e, v) => {
          onChange(v);
        }}
        renderTags={(value, props) =>
          value.map((option, index) => (
            <Chip
              sx={{
                background: colors.bgPrimary,
                borderRadius: "8px",
                border: `1px solid ${colors.borderPrimary}`,
              }}
              label={option}
              {...props({ index })}
              deleteIcon={<CloseIcon fontSize="small" />}
            />
          ))
        }
        renderInput={(params) => (
          <CustomTextField placeholder={"Add tags"} {...params} />
        )}
      />
    </div>
  );
};

export default TagsField;
