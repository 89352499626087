import { ReactComponent as SearchIcon } from "@Images/search-lg.svg";
import { FilterList, FilterListOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import FilledTextField from "./FilledTextField";

const FilterField = (props) => {
  const { onChange, value, onReset, placeholder = "Search" } = props;
  const { colors, mode } = useThemeContext();
  return (
    <div className="w-full flex items-center gap-2">
      <FilledTextField
        placeholder={placeholder}
        type="text"
        name="title"
        fullWidth
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        startIcon={<SearchIcon />}
      />
      <IconButton
        sx={{
          borderRadius: "8px",
          border: `1px solid ${colors.borderPrimary}`,
          height: 40,
          width: 40,
          color: mode === "dark" ? colors.textPrimary : colors.brandPrimary,
        }}
        onClick={onReset}
      >
        {!!value ? (
          <FilterListOff fontSize="small" />
        ) : (
          <FilterList fontSize="small" />
        )}
      </IconButton>
    </div>
  );
};

export default FilterField;
