import { TextField, outlinedInputClasses } from "@mui/material";
import React from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const Textarea = (props) => {
  const {
    label = "",
    style,
    className,
    error,
    helperText = "",
    endIcon = null,
    startIcon = null,
    minRows = 2,
    maxRows = 100,
    ...rest
  } = props;
  const { colors } = useThemeContext();
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={`filledInput${label}`} className="text-[#828282] mb-1">
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        {startIcon && <div className="absolute left-2 ">{startIcon}</div>}
        <TextField
          multiline
          minRows={minRows}
          maxRows={maxRows}
          id={`filledInput${label}`}
          sx={{
            [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
              {
                borderRadius: "8px",
                borderColor: error && colors.error,
              },
          }}
          style={{
            background: "#22242D",
            outline: "none",
            padding: endIcon
              ? "10px 40px 10px 14px"
              : startIcon
              ? "10px 14px 10px 40px"
              : "10px 14px",
            color: "#F1F2F6",
            fontSize: "16px",
            width: "100%",
            ...style,
          }}
          className={`border-[1px] border-transparent hover:border-white rounded-[6px] focus:border-primary ${className} ${
            error && "border-error-light dark:border-error"
          } `}
          {...rest}
        />

        {endIcon && <div className="absolute right-2">{endIcon}</div>}
      </div>
      <p
        className={`text-[10px] ${
          error ? "text-error-light dark:text-error" : "text-tertiary"
        } min-h-3`}
      >
        {helperText}
      </p>
    </div>
  );
};

export default Textarea;
