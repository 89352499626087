import { Button } from "@mui/material";

const SimplePagination = (props) => {
  const { onNext, onPrevious, pagination } = props;
  return (
    <>
      {pagination?.pages > 1 && (
        <>
          <Button
            variant="text"
            disabled={!pagination?.has_prev}
            onClick={onPrevious}
            color="secondary"
          >
            Previous
          </Button>
          {!!pagination?.pages && (
            <p className="text-xs text-primary">
              {` ${pagination?.page} of ${pagination?.pages}`}
            </p>
          )}
          <Button
            onClick={onNext}
            variant="text"
            disabled={!pagination?.has_next}
            color="secondary"
          >
            Next
          </Button>
        </>
      )}
    </>
  );
};

export default SimplePagination;
