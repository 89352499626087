const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  openDataRoomFile: {},
  selectedFile: [],
  fileUploadProgress: {},
  editDataFile: {},
};

const dataRoomSlice = createSlice({
  name: "data room slice",
  initialState,
  reducers: {
    setOpenDataRoomFiles: (state, action) => {
      state.openDataRoomFile = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setFileUploadProgress: (state, action) => {
      state.fileUploadProgress = {
        ...state.fileUploadProgress,
        ...action.payload,
      };
    },
    setClearUploadProgress: (state, action) => {
      state.fileUploadProgress = {};
    },
    addToEditDataFile: (state, action) => {
      state.editDataFile = action?.payload;
    },

    handleChangEditEditDataFile: (state, action) => {
      state.editDataFile = { ...action.payload, isEdited: true };
    },
  },
});

export const {
  setOpenDataRoomFiles,
  setSelectedFile,
  setFileUploadProgress,
  addToEditDataFile,
  handleChangEditEditDataFile,
  setClearUploadProgress,
} = dataRoomSlice.actions;

export default dataRoomSlice.reducer;
