import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  TextField,
  outlinedInputClasses,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import getCaretCoordinates from "textarea-caret";
import { useThemeContext } from "../Themes/ThemeContextProvider";

const SuggestionField = (props) => {
  const { colors } = useThemeContext();
  const {
    menuListItems = [],
    iconsPosition = "start",
    style = {},
    label = "",
    className,
    error,
    helperText = "",
    endIcon = null,
    startIcon = null,
    minRows = 2,
    maxRows = 100,
    onChange,
    value,
    multiline = false,
    onBlur = () => {},
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSlashInput = (event) => {
    const inputText = event.target.value;
    onChange(event);
    if (inputText.endsWith(" /")) {
      const inputRef = event.target;
      const { left, top } = getCaretCoordinates(
        inputRef,
        inputRef.selectionEnd
      );
      const { x, y } = inputRef.getBoundingClientRect();
      setMenuPosition({
        top: top + y - inputRef.scrollTop,
        left: left + x - inputRef.scrollLeft,
      });
      setAnchorEl(event.currentTarget);
    } else {
      handleClose();
    }
  };

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={`filledInput${label}`} className="text-tertiary mb-1">
          {label}
        </label>
      )}
      <TextField
        sx={{
          [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderRadius: "8px",
            },
        }}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        onChange={handleSlashInput}
        fullWidth
        onBlur={(e) => {
          onBlur(e);
        }}
        {...rest}
        style={{ background: colors.bgPrimary, padding: "0px", ...style }}
        value={value}
        InputProps={{
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
      />

      <Menu
        id="basic-menu"
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            border: `1px solid ${colors.borderPrimary}`,
            // marginLeft: "-100px",
            background: "#0C111D",
            color: colors.textPrimary,
          },
        }}
        sx={{
          ".MuiList-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          minWidth: "140px",
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {menuListItems.map((item) => (
          <MenuItem
            sx={{
              borderBottom: "none",
              "&:last-child": {
                borderTop: `1px solid ${colors.borderSecondary}`,
              },
              "&:hover": {
                background: colors.bgSecondary,
              },
              padding: "12px 14px 12px 14px",

              minWidth: "240px",
              background: colors.bgPrimary,
            }}
            onClick={(e) => {
              item?.onClick(item?.title);
              onChange(null, `${value?.slice(0, -1)}{{${item?.title}}}`);
              handleClose();
            }}
            className="bg-bgPrimary"
          >
            {item?.icon && iconsPosition === "start" && (
              <ListItemIcon
                sx={{
                  minWidth: "20px !important",
                  ".MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.icon}
              </ListItemIcon>
            )}
            <ListItemText>
              <div
                className="text-sm-medium mx-1"
                style={{
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.title}
              </div>
            </ListItemText>
            {item?.icon && iconsPosition === "end" && (
              <ListItemIcon
                sx={{
                  minWidth: "20px !important",
                  ".MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                  color: item?.colorRed ? "rgba(249, 112, 102, 1)" : "",
                }}
              >
                {item?.icon}
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SuggestionField;
