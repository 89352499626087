import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

const ColorPicker = (props) => {
  const {
    value = "#7A5AF8",
    setValue = () => {},
    onBlur = () => {},
    pickerId = Math.random(),
    ...rest
  } = props;

  return (
    <>
      <input
        type="color"
        value={value}
        id={"color-picker" + pickerId}
        onChange={(e) => setValue(e.target.value)}
        className={`invisible h-0 w-0`}
        {...rest}
      />
      <label
        htmlFor={"color-picker" + pickerId}
        onBlur={onBlur}
        className={`p-1 rounded-lg cursor-pointer flex gap-2 items-center  border-1 border-primary`}
      >
        <div className="h-8 w-8 rounded-md" style={{ backgroundColor: value }}>
          {" "}
        </div>
        <span>
          <KeyboardArrowDown />{" "}
        </span>
      </label>
    </>
  );
};

export default ColorPicker;
