import {
  Add as AddIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TrashSimple } from "../../../../Images/TrashSimple.svg";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";
import FilledTextField from "../../../UI/FilledTextField";
const CsvFormatter = (props) => {
  const { activeScreen = "Preview" } = props;
  const { colors, mode } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const dispatch = useDispatch();
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const outputData = promptData.output_keys[promptData?.outputFormat];

  const Column = ({ name, index }) => {
    const [columnName, setColumnName] = useState(name);
    let arr = [...outputData];
    const swapUp = (index) => {
      if (index > 0 && index < arr.length) {
        const temp = arr[index];
        arr[index] = arr[index - 1];
        arr[index - 1] = temp;
      }
      let data = {
        ...promptData,
        output_keys: {
          ...promptData.output_keys,
          csv: arr,
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    const swapDown = (index) => {
      if (index >= 0 && index < arr.length - 1) {
        const temp = arr[index];
        arr[index] = arr[index + 1];
        arr[index + 1] = temp;
      }
      let data = {
        ...promptData,
        output_keys: {
          ...promptData.output_keys,
          csv: arr,
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    const deleteColumn = (index) => {
      let data = {
        ...promptData,
        output_keys: {
          ...promptData.output_keys,
          csv: arr.filter((n, i) => i !== index),
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    const handleChangeColumnName = (e, index) => {
      arr[index] = columnName;
      let data = {
        ...promptData,
        output_keys: {
          ...promptData.output_keys,
          csv: arr,
        },
      };
      dispatch(handleChangEditPrompt(data));
    };
    return (
      <div className="w-full px-10 py-4 flex items-center  justify-between">
        <div className="flex items-center w-[60%] gap-2">
          <p>{index + 1}.</p>
          <div className="w-full">
            <FilledTextField
              value={columnName}
              onChange={(e) => setColumnName(e.target.value)}
              onBlur={(e) => handleChangeColumnName(e, index)}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <IconButton disabled={!index} onClick={() => swapUp(index)}>
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            disabled={arr?.length == index + 1}
            onClick={() => swapDown(index)}
          >
            <KeyboardArrowDown />
          </IconButton>
          <IconButton onClick={() => deleteColumn(index)}>
            <TrashSimple />
          </IconButton>
        </div>
      </div>
    );
  };
  const handleAddMoreColumn = () => {
    let data = {
      ...promptData,
      output_keys: {
        ...promptData.output_keys,
        csv: outputData?.length ? [...outputData, ""] : [""],
      },
    };
    dispatch(handleChangEditPrompt(data));
  };
  return (
    <>
      {activeScreen !== promptData?.outputFormat ? (
        <>
          {Array.isArray(outputData) &&
            outputData?.map((column, index) => (
              <Column key={index} name={column} index={index} />
            ))}
          <div className="m-auto  py-4 flex flex-col items-center justify-center w-40 text-center">
            <Button
              onClick={handleAddMoreColumn}
              startIcon={<AddIcon />}
              variant="outlined"
              color="secondary"
              className="text-[#595E76] text-[11px] mt-2"
            >
              Add Column
            </Button>
          </div>
        </>
      ) : (
        <CodeHighlighter
          customStyle={{ background: "transparent" }}
          style={mode === "dark" ? "vs2015" : "a11yLight"}
          language={"json"}
          wrapLongLines={true}
          wrapLines={true}
        >
          {JSON.stringify(outputData)}
        </CodeHighlighter>
      )}
    </>
  );
};

export default CsvFormatter;
