import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllContexts,
  getAllPrompts,
  getMyEnvironments,
  getTokenTrack,
} from "../services/app/api";

export const fetchAvailableCredits = createAsyncThunk(
  "fetchCredits",
  (payload) =>
    getTokenTrack(payload)
      .then((res) => res)
      .catch((error) => error)
);

export const fetchPrompts = createAsyncThunk("fetchPrompts", (payload) =>
  getAllPrompts(payload)
    .then((res) => res)
    .catch((error) => error)
);
export const fetchContexts = createAsyncThunk("fetchContexts", (payload) =>
  getAllContexts(payload)
    .then((res) => res)
    .catch((error) => error)
);
export const fetchEnvironments = createAsyncThunk(
  "fetchEnvironments",
  (payload) =>
    getMyEnvironments(payload)
      .then((res) => res)
      .catch((error) => error)
);

const initialState = {
  isLoading: false,
  allPromptsData: {},
  error: false,
  editPromptsList: [],
  editEnvironmentsList: [],
  editContextList: [],
  allContexts: {},
  activeWindow: "collection",
  allEnvironments: {},
  aiModelsList: [],
  modelsVersionsList: [],
  searchPrompt: "",
  searchEnvironment: "",
  searchContext: "",
  availableCredits: 0,
  all_objectives: [],
};
export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    handleLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    handleResetEdit: (state) => {
      state.editPromptsList = [];
      state.editEnvironmentsList = [];
      state.searchEnvironment = "";
      state.searchPrompt = "";
    },
    setSearchPrompt: (state, action) => {
      state.searchPrompt = action.payload;
    },
    setSearchEnvironment: (state, action) => {
      state.searchEnvironment = action.payload;
    },
    setAIModelsList: (state, action) => {
      state.aiModelsList = action.payload;
    },
    setObjectives: (state, action) => {
      state.all_objectives = action.payload;
    },
    setModelsVersionsList: (state, action) => {
      state.modelsVersionsList = action.payload;
    },
    addToEditPrompt: (state, action) => {
      let isDummyData = false;
      var newData = [];
      if (state.editPromptsList.length > 0) {
        newData = state.editPromptsList?.map((prompt) => {
          if (prompt?.id === action.payload?.id) {
            isDummyData = true;
            return { ...prompt, isActiveTab: true };
          } else {
            return { ...prompt, isActiveTab: false };
          }
        });
      }
      if (!isDummyData) {
        newData.push({ ...action?.payload, isActiveTab: true });
      }
      state.editPromptsList = newData;
    },
    removeFromEditPrompt: (state, action) => {
      let removePrompt = { index: -1, isActiveTab: false };
      state.editPromptsList?.forEach((prompt, index) => {
        if (prompt?.id === action.payload) {
          removePrompt.index = index;
          removePrompt.isActiveTab = prompt?.isActiveTab;
        }
      });
      let editablePrompts = [];
      state.editPromptsList?.forEach((prompt, index) => {
        if (
          removePrompt?.index > 0 &&
          removePrompt?.index < state.editPromptsList?.length
        ) {
          if (index == removePrompt?.index) {
          } else if (index == removePrompt?.index - 1) {
            editablePrompts.push({
              ...prompt,
              isActiveTab: removePrompt?.isActiveTab
                ? true
                : prompt?.isActiveTab,
            });
          } else {
            editablePrompts.push(prompt);
          }
        } else {
          if (index == removePrompt?.index) {
          } else if (index == removePrompt?.index + 1) {
            editablePrompts.push({
              ...prompt,
              isActiveTab: removePrompt?.isActiveTab
                ? true
                : prompt?.isActiveTab,
            });
          } else {
            editablePrompts.push(prompt);
          }
        }
      });
      state.editPromptsList = editablePrompts;
    },
    handleChangEditPrompt: (state, action) => {
      state.editPromptsList = state.editPromptsList?.map((prompt) => {
        if (prompt?.id === action.payload?.id) {
          return { ...action.payload, isEdited: true };
        } else {
          return prompt;
        }
      });
    },
    handleUpdatePromptId: (state, action) => {
      state.editPromptsList = state.editPromptsList?.map((prompt) => {
        if (prompt?.id === action.payload?.oldId) {
          return { ...action.payload, isEdited: false };
        } else {
          return prompt;
        }
      });
    },
    handleUpdateEnvironmentId: (state, action) => {
      state.editEnvironmentsList = state.editEnvironmentsList?.map((prompt) => {
        if (prompt?.id === action.payload?.oldId) {
          return { ...action.payload, isEdited: false };
        } else {
          return prompt;
        }
      });
    },
    changeActiveWindow: (state, action) => {
      state.activeWindow = action.payload;
    },
    addToEditEnv: (state, action) => {
      let isDummyData = false;
      var newData = [];
      if (state.editEnvironmentsList.length > 0) {
        newData = state.editEnvironmentsList?.map((prompt) => {
          if (prompt?.id === action.payload?.id) {
            isDummyData = true;
            return { ...prompt, isActiveTab: true };
          } else {
            return { ...prompt, isActiveTab: false };
          }
        });
      }
      if (!isDummyData) {
        newData.push({ ...action?.payload, isActiveTab: true });
      }
      state.editEnvironmentsList = newData;
    },
    removeFromEditEnv: (state, action) => {
      let removeEnv = { index: -1, isActiveTab: false };
      state.editEnvironmentsList?.forEach((prompt, index) => {
        if (prompt?.id === action.payload) {
          removeEnv.index = index;
          removeEnv.isActiveTab = prompt?.isActiveTab;
        }
      });
      let editablePrompts = [];
      state.editEnvironmentsList?.forEach((prompt, index) => {
        if (
          removeEnv?.index > 0 &&
          removeEnv?.index < state.editEnvironmentsList?.length
        ) {
          if (index == removeEnv?.index) {
          } else if (index == removeEnv?.index - 1) {
            editablePrompts.push({
              ...prompt,
              isActiveTab: removeEnv?.isActiveTab ? true : prompt?.isActiveTab,
            });
          } else {
            editablePrompts.push(prompt);
          }
        } else {
          if (index == removeEnv?.index) {
          } else if (index == removeEnv?.index + 1) {
            editablePrompts.push({
              ...prompt,
              isActiveTab: removeEnv?.isActiveTab ? true : prompt?.isActiveTab,
            });
          } else {
            editablePrompts.push(prompt);
          }
        }
      });
      state.editEnvironmentsList = editablePrompts;
    },
    handleChangEditEnv: (state, action) => {
      state.editEnvironmentsList = state.editEnvironmentsList?.map((prompt) => {
        if (prompt?.id === action.payload?.id) {
          return { ...action.payload, isEdited: true };
        } else {
          return prompt;
        }
      });
    },
    setSearchContext: (state, action) => {
      state.searchContext = action.payload;
    },
    addToEditContext: (state, action) => {
      let isDummyData = false;
      var newData = [];
      if (state.editContextList.length > 0) {
        newData = state.editContextList?.map((context) => {
          if (context?.id === action.payload?.id) {
            isDummyData = true;
            return { ...context, isActiveTab: true };
          } else {
            return { ...context, isActiveTab: false };
          }
        });
      }
      if (!isDummyData) {
        newData.push({ ...action?.payload, isActiveTab: true });
      }
      state.editContextList = newData;
    },
    removeFromEditContext: (state, action) => {
      let removeContext = { index: -1, isActiveTab: false };
      state.editContextList?.forEach((context, index) => {
        if (context?.id === action.payload) {
          removeContext.index = index;
          removeContext.isActiveTab = context?.isActiveTab;
        }
      });
      let editableContext = [];
      state.editContextList?.forEach((context, index) => {
        if (
          removeContext?.index > 0 &&
          removeContext?.index < state.editContextList?.length
        ) {
          if (index == removeContext?.index) {
          } else if (index == removeContext?.index - 1) {
            editableContext.push({
              ...context,
              isActiveTab: removeContext?.isActiveTab
                ? true
                : context?.isActiveTab,
            });
          } else {
            editableContext.push(context);
          }
        } else {
          if (index == removeContext?.index) {
          } else if (index == removeContext?.index + 1) {
            editableContext.push({
              ...context,
              isActiveTab: removeContext?.isActiveTab
                ? true
                : context?.isActiveTab,
            });
          } else {
            editableContext.push(context);
          }
        }
      });
      state.editContextList = editableContext;
    },
    handleChangEditContext: (state, action) => {
      state.editContextList = state.editContextList?.map((context) => {
        if (context?.id === action.payload?.id) {
          return { ...action.payload, isEdited: true };
        } else {
          return context;
        }
      });
    },
    handleUpdateContextId: (state, action) => {
      state.editContextList = state.editContextList?.map((context) => {
        if (context?.id === action.payload?.oldId) {
          return { ...action.payload, isEdited: false };
        } else {
          return context;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrompts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrompts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPromptsData = action.payload;
    });
    builder.addCase(fetchPrompts.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchContexts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContexts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allContexts = action.payload;
    });
    builder.addCase(fetchContexts.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchEnvironments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEnvironments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEnvironments = action.payload;
    });
    builder.addCase(fetchEnvironments.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchAvailableCredits.fulfilled, (state, action) => {
      state.availableCredits = action.payload?.tokens || 0;
    });
  },
});

export const {
  addToEditPrompt,
  removeFromEditPrompt,
  handleChangEditPrompt,
  handleUpdatePromptId,
  changeActiveWindow,
  handleChangEditEnv,
  removeFromEditEnv,
  addToEditEnv,
  handleLoading,
  handleUpdateEnvironmentId,
  setAIModelsList,
  setModelsVersionsList,
  handleResetEdit,
  setSearchPrompt,
  setSearchEnvironment,
  handleUpdateContextId,
  handleChangEditContext,
  removeFromEditContext,
  addToEditContext,
  setSearchContext,
  setObjectives,
} = collectionsSlice.actions;

export default collectionsSlice.reducer;
