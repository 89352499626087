const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  allAIAssistants: {},
  editAIAssistant: {},
  searchAssistant: "",
  defaultExpanded: [],
};

const assistantSlice = createSlice({
  name: "data room slice",
  initialState,
  reducers: {
    setAllAIAssistants: (state, action) => {
      state.allAIAssistants = action.payload;
    },
    setSearchAssistant: (state, action) => {
      state.searchAssistant = action.payload;
    },
    setDefaultExpanded: (state, action) => {
      state.defaultExpanded = action.payload;
    },

    handleUpdateAssistantId: (state, action) => {
      state.editAIAssistant =
        state.editAIAssistant?.id === action.payload?.oldId
          ? { ...action.payload, isEdited: false }
          : state.editAIAssistant;
    },

    addToEditAssistant: (state, action) => {
      state.editAIAssistant = action?.payload;
    },
    handleChangEditAssistant: (state, action) => {
      if (action?.payload?.notEditing) {
        state.editAIAssistant = { ...action.payload, notEditing: false };
      } else {
        state.editAIAssistant = { ...action.payload, isEdited: true };
      }
    },
  },
});

export const {
  setAllAIAssistants,
  setOpenDataRoomFiles,
  addToEditAssistant,
  handleChangEditAssistant,
  removeFromEditAssistant,
  setSearchAssistant,
  handleUpdateAssistantId,
  setDefaultExpanded,
} = assistantSlice.actions;

export default assistantSlice.reducer;
