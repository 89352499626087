import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Pagination } from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
const PaginationElement = ({ handleChangeInPagination, total, page }) => {
  const previewButton = () => {
    return (
      <div className=" flex gap-2 items-center ">
        <ArrowBackIcon sx={{ fontSize: 20 }} />
        <div className="text-sm-medium">Previous</div>
      </div>
    );
  };

  const nextButton = () => {
    return (
      <div className="flex gap-2 items-center   ">
        <div className="  text-sm-medium">Next</div>
        <div>
          <ArrowForwardIcon sx={{ fontSize: 20 }} />
        </div>
      </div>
    );
  };
  return (
    <div className="relative mt-4 mb-4">
      <Pagination
        sx={{
          // background: "green",
          justifyContent: "center",
          alignItems: "center",

          ".MuiPagination-ul": {
            justifyContent: "center",
          },
          ".MuiPagination-ul >  li:last-child": {
            position: "absolute",
            top: "0",
            right: "0",
            justifyContent: "right",
          },
          ".MuiPagination-ul >  li:first-child": {
            position: "absolute",
            top: "0",
            left: "0",

            justifyContent: "right",
          },
          ".MuiPaginationItem-previousNext": {
            // background: "#FFFFFF",
            // border: "1px solid #D0D5DD",
            // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            // borderRadius: "8px",
            padding: "15px 10px",
          },
        }}
        shape="rounded"
        labelRowsPerPage={""}
        // color="whitish"
        count={total}
        size="medium"
        page={page}
        onChange={handleChangeInPagination}
        renderItem={(item) => (
          <PaginationItem
            size="medium"
            variant="outlined"
            components={{
              previous: previewButton,
              next: nextButton,
            }}
            type="start-ellipsis"
            {...item}
          />
        )}
      />
    </div>
  );
};

export default PaginationElement;
