import { ReactComponent as Align } from "@Images/align-left.svg";
import ContentCarrom from "@Images/contentcarrom.png";
import { ReactComponent as Image03 } from "@Images/image-03.svg";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import { Isotype } from "../../UI/IconPack";
const TokenExpire = (props) => {
  const { open, onClose } = props;
  const { mode, colors } = useThemeContext();
  const [showEmail, setShowEmail] = useState(false);
  const navigate = useNavigate();
  const { activeTeam } = useAuth();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
  };

  const InfoBox = () => {
    return (
      <div>
        <div className="grid grid-cols-2  bg-brandSecondary dark:bg-bgPrimary rounded-3xl">
          <div className="w-full p-10 flex flex-col items-start justify-center gap-10">
            <div className="text-textPrimary">
              <Isotype height={55} width={66} />
            </div>
            <div className=" display-sm-semibold  text-textPrimary">
              <div>You have run out of credits</div>
            </div>
            <div className=" text-sm-regular text-textPrimary dark:text-tertiary">
              <div>
                You have used all your free credits. Please get more or connect
                an API to continue.
              </div>
            </div>

            <div className="flex w-full justify-between gap-5">
              <div className="w-1/2 bg-bgPrimary-light dark:bg-bgPrimary p-3 rounded-lg">
                <div className="flex gap-4  items-center">
                  <div>
                    <Image03 />
                  </div>

                  <div>
                    <div className="text-xs-medium text-secondary">
                      Image generation
                    </div>
                    <div className="text-sm-regular line-through  text-primary  ">
                      10 credits
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 bg-bgPrimary-light dark:bg-bgPrimary p-3 rounded-lg">
                <div className="flex gap-4 items-center">
                  <div>
                    <Align />
                  </div>

                  <div>
                    <div className="text-xs-medium text-secondary">
                      Text generation
                    </div>
                    <div className="text-sm-regular line-through  text-primary  ">
                      5,000 credits
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between  w-full items-center">
              <div className="w-1/2">
                <Button
                  onClick={() => {
                    navigate("/onboard/settings", { replace: true });
                  }}
                  fullWidth
                  variant="contained"
                >
                  Connect an Api
                </Button>
              </div>
              <div className="text-sm text-tertiary mx-4">or</div>
              <div className="w-1/2">
                <a
                  className="border-none"
                  href="mailto:Info@scalaix.com?subject=Request for credits."
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    sx={
                      mode === "light" && {
                        color: "#344054",
                        background: "#FFF",
                      }
                    }
                  >
                    Request Credits
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div
            className="relative w-full h-full rounded-r-3xl"
            style={{
              backgroundImage: `url(${ContentCarrom})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute top-2 right-2">
              <IconButton onClick={onClose}>
                <Close className="text-textSecondary" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <InfoBox />
      </Box>
    </Modal>
  );
};

export default TokenExpire;
