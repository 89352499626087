import { createTheme, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { getDesignTokens } from "./Theme";
import { darkThemColors, lightThemeColors } from "./colors";
export const ThemeContext = createContext({
  mode: "dark",
  toggleColorMode: () => {},
  theme: createTheme(),
});

export const ThemeContextProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState(
    createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light"))
  );
  const [mode, setMode] = useState(prefersDarkMode ? "dark" : "light");

  const toggleColorMode = () => {
    localStorage.setItem("themeMode", mode === "dark" ? "light" : "dark");
    setMode((ini) => (ini === "dark" ? "light" : "dark"));
  };

  useMemo(() => {
    setTheme(createTheme(getDesignTokens(mode)));
    if (mode == "dark") {
      document.documentElement.classList.add("dark");
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, [mode]);

  useLayoutEffect(() => {
    const themeMode = localStorage.getItem("themeMode");
    themeMode === "dark" || themeMode === "light"
      ? setMode(themeMode)
      : setMode(prefersDarkMode ? "dark" : "light");
  }, []);

  const initialValue = {
    mode,
    theme,
    toggleColorMode,
    colors: mode === "light" ? lightThemeColors : darkThemColors,
  };
  return (
    <ThemeContext.Provider value={initialValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
