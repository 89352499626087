class Env {
  getEnvironment = () => process.env.NODE_ENV;
  getApiBaseUrl = () => process.env.REACT_APP_API_BASE_URL;
  getPapiBaseUrl = () => process.env.REACT_APP_PAPI_BASE_URL;
  getAapiBaseUrl = () => process.env.REACT_APP_AAPI_BASE_URL;
  getSapiBaseUrl = () => process.env.REACT_APP_SAPI_BASE_URL;
  getIapiBaseUrl = () => process.env.REACT_APP_IAPI_BASE_URL;
  getGraphBaseUrl = () => process.env.REACT_APP_GRAPH_BASE_URL;
  getBaseDomain = () => process.env.REACT_APP_DOMAIN_BASE_URL;
  getGoogleKey = () => process.env.REACT_APP_GOOGLE_KEY_URL;
  getFaceBookAppId = () => process.env.REACT_APP_FACE_BOOK_APP_ID;
  getSubmitEmailUrl = () => process.env.REACT_APP_EMAIL_APP_URL;
  getUnsplashBaseUrl = () => process.env.REACT_APP_UNS_PLASH_URL;
  getUnsPlashKey = () => process.env.REACT_APP_UNSPLASH_KEY;
  getPixelKey = () => process.env.REACT_APP_PIXEL_KEY;
  getloggerpass = () => process.env.REACT_APP_LOGGER_PASS;
  getloggeruserName = () => process.env.REACT_APP_LOGGER_USER_NAME;
  getConfluenceClientKey = () => process.env.REACT_APP_CONFLUENCE_CLIENT_KEY;
  getConfluenceClientSecret = () =>
    process.env.REACT_APP_CONFLUENCE_CLIENT_SECRET;
  getConfluenceAuthorizationUrl = () =>
    process.env.REACT_APP_CONFLUENCE_AUTHORIZATION_URL;
  getConfluenceTokenUrl = () => process.env.REACT_APP_CONFLUENCE_TOKEN_URL;
  getConfluenceCallbackUrl = () =>
    process.env.REACT_APP_CONFLUENCE_CALLBACK_URL;
  getConfluenceState = () => process.env.REACT_APP_CONFLUENCE_STATE;
}

export default new Env();
