import FilledTextField from "@Components/UI/FilledTextField";
import { ArrowForward } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";

import { MUISTYleTab, MUITabsList } from "@Components/Themes/CustomComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../Images/search-lg.svg";
import {
  addTemplateCollection,
  getTemplatesCollections,
  getTemplatesTags,
} from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import {
  handleLoading,
  handleResetEdit,
} from "../../../store/collectionsSlice";
import { useAuth } from "../../Auth/AuthContext";
import Welcome from "../common/Welcome";

import { useThemeContext } from "../../Themes/ThemeContextProvider";
import { FileTypeIcon } from "../../UI/IconPack";
import "./Templates.scss";
const Templates = (props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(2);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const { showWelcome = true, handleChangeScreen = () => {} } = props;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [collections, setCollections] = useState({});
  const [activeTag, setActiveTag] = useState("");
  const [tags, setTags] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const hasPageRender = useRef({ effect1: false });
  const { mode, colors } = useThemeContext();

  useEffect(() => {
    // dispatch(handleLoading(true));

    getTemplatesTags().then(({ items }) => {
      if (items?.length) {
        setTags(items);
        fetchCollections(items[0]);
        setSelectedTag(items[0]);
      }
    });
    // dispatch(handleLoading(false));
  }, []);

  const handleTabChange = (e, v) => {
    setActiveTag(v);
  };

  const handleNextPageCollection = () => {
    fetchCollections(selectedTag, searchKey, page + 1);
  };

  const handlePrevPageCollection = () => {
    fetchCollections(selectedTag, searchKey, page - 1);
  };

  const handleSearchCollection = (e) => {
    setSearchKey(e.target.value);
  };

  const fetchCollections = (tag, search = "", page = 1) => {
    // dispatch(handleLoading(true));
    setLoading(true);
    getTemplatesCollections({
      params: {
        tag,
        limit: 8,
        page: page,
        searchKey: search,
      },
    })
      .then((res) => {
        setCollections(res?.items);
        setPages(res?.pages);
        setPage(res?.page);
        setHasNext(res?.has_next);
        setHasPrev(res?.has_prev);
      })
      .catch(() => {})
      .finally(() => {
        // dispatch(handleLoading(false));
        setLoading(false);
      });
  };

  return (
    <div className={`${!showWelcome && "h-96 rounded-lg overflow-auto"} `}>
      {showWelcome && <Welcome />}
      {!!tags?.length && (
        <div className={`w-full  py-8 px-12 block-tags `}>
          <div className="  ">
            <MUITabsList
              value={value}
              onChange={() => {}}
              scrollbarFadeDuration="0"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
                  opacity: "1 !important",
                },
              }}
            >
              {tags.map((tag, index) => {
                return (
                  <MUISTYleTab
                    onClick={() => {
                      setValue(index);
                      setSelectedTag(tag);
                      setSearchKey("");

                      fetchCollections(tag, "", 1);
                    }}
                    sx={{
                      color:
                        mode === "dark"
                          ? colors.textPrimary
                          : colors.textQuaternary,
                      fontWidth: "600",
                      "&.Mui-selected": {
                        color:
                          mode === "dark"
                            ? colors.textPrimary
                            : colors.brandPrimary,
                        backgroundColor: colors.bgSecondary,
                      },
                    }}
                    label={
                      <div name={tag} style={{ textTransform: "capitalize" }}>
                        {tag}
                      </div>
                    }
                  ></MUISTYleTab>
                );
              })}{" "}
            </MUITabsList>
          </div>
          <div className={`flex w-full   `}>
            <div className="w-full">
              <FilledTextField
                placeholder={"Search"}
                type="text"
                name="title"
                fullWidth
                variant="outlined"
                size="small"
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value);

                  fetchCollections(selectedTag, e.target.value, 1);
                }}
                startIcon={<SearchIcon />}
              />
            </div>
            {!showWelcome && (
              <div onClick={handleChangeScreen} className="cursor-pointer">
                Create empty collection <ArrowForward fontSize="small" />
              </div>
            )}
          </div>
        </div>
      )}

      <div className={" py-8 px-12"}>
        <div style={{ minHeight: "200px" }}>
          {!!collections?.length && !loading ? (
            <div className="main-grid">
              {collections?.map((collection) => (
                <CollectionCard
                  key={collection?.id}
                  {...{ ...collection, showWelcome }}
                />
              ))}
            </div>
          ) : (
            <div>
              <div className="text-4xl w-full text-center h-[93.5%] flex flex-col justify-center items-center">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <div className="text-4xl text-center h-[93.5%]  flex flex-col justify-center items-center">
                      <div className="relative flex flex-col items-center justify-center">
                        <FileTypeIcon />
                        <div className="absolute bottom-28">
                          <p className="text-sm-semibold  text-primary    mt-4">
                            No search result
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {!!collections?.length && pages > 1 && (
          <div
            className="flex justify-between items-center w-full mt-20 pt-6 "
            style={{
              borderTop: "1px solid rgba(31, 36, 47, 1) ",
            }}
          >
            <Button
              variant="text"
              disabled={!hasPrev}
              onClick={handlePrevPageCollection}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Previous
            </Button>
            {!!pages && (
              <p style={{ color: "#575B6E", color: "#fff", fontSize: "11px" }}>
                {` ${page} of ${pages}`}
              </p>
            )}
            <Button
              onClick={handleNextPageCollection}
              variant="text"
              disabled={!hasNext}
              color="secondary"
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Templates;

const CollectionCard = (collection) => {
  const { activeTeam } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddToCollection = (collection) => {
    const payload = {
      team_id: activeTeam,
      data: {
        collection_id: collection?.id,
      },
    };
    dispatch(handleLoading(true));
    addTemplateCollection(payload)
      .then((res) => {
        dispatch(handleResetEdit());
        snackbar.success({ title: "Collection added" });
        navigate(`/onboard/collections/${res?.id}`, {
          state: { collection: res },
          replace: true,
        });
      })
      .catch((error) =>
        snackbar.error({ title: `${error.error && error.error.toString()}` })
      )
      .finally(() => dispatch(handleLoading(false)));
  };
  return (
    <div className={`  tag-block-main bg-secondary `}>
      <div className="">
        <div className="text-xl-semibold">{collection?.name}</div>
        <p
          style={{
            display: "-webkit-box",
            maxWidth: "100%",
            WebkitLineClamp: 5,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
          className="text-tertiary text-md-regular overflow-hidden mt-4"
        >
          {collection?.description}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <div
          onClick={() => handleAddToCollection(collection)}
          className="text-brandPrimary-light dark:text-textSecondary font-semibold dark:text-md-semibold cursor-pointer"
        >
          Start building <ArrowForward fontSize="small" />
        </div>
        <Button
          onClick={() => {
            dispatch(handleResetEdit());
            navigate(
              `/onboard/collections/${collection?.id}?previewMode=true`,
              {
                state: { collection: collection, previewMode: true },
                replace: true,
              }
            );
          }}
          variant="outlined"
          color="secondary"
        >
          Preview
        </Button>
      </div>
    </div>
  );
};
