import { amber, red } from "@mui/material/colors";
import { MuiSwitch } from "./componentsTheme";

const Theme = {
  palette: {
    primary: amber,
  },
};
const p_colors = {
  600: "#233554",
};
const white_color = "#FFFFFF";
const black_color = "#1C1C1E";

export const text_md_medium = {
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
};
const text_sm_medium = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
};

export const text_md_normal = {
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
};
export const text_sm_normal = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#6938EF",
            contrastText: "#FFFFFF",
          },
          secondary: {
            main: "#6938EF",
            contrastText: "#D92D20",
          },
          gray: {
            main: "#D0D5DD",
            contrastText: "#000",
          },
          error: {
            main: "#D92D20",
          },
          borderPrimary: "#D0D5DD",
          divider: amber[200],
          text: {
            primary: "#101828",
            secondary: "#344054",
            tertiary: "#CECFD2",
          },

          background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
          },
        }
      : {
          primary: {
            main: "#6626D2",
            mainGradient: "linear-gradient(0deg, #5F25D3 2.38%, #7825D0 100%)",
            contrastText: "#FFFFFF",
          },
          secondary: {
            main: "#CECFD2",
            contrastText: "#CECFD2",
          },
          gray: {
            main: "#D0D5DD",
            contrastText: "#000000",
          },
          error: {
            main: "#E57878",
          },
          errorPrimary: {
            main: "#F04438",
          },
          errorSecondary: {
            main: "#D92D20",
          },
          borderPrimary: "#333741",
          text: {
            primary: "#F5F5F6",
            secondary: "#CECFD2",
            tertiary: "#94969C",
          },
          background: {
            default: "#0C111D",
            paper: "#0C111D",
          },
        }),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "none",
          borderRadius: "8px",
          fontFamily: "Inter",
          // backgroundImage: "linear-gradient(0deg, #5F25D3 2.38%, #7825D0 100%)",
        },
        outlined: {
          textTransform: "none",
          borderRadius: "8px",
          fontFamily: "Inter",
          //border: `1px solid ${mode === "dark" ? "#333741" : "#6938EF"}`,
          //color: mode === "dark" ? "#CECFD2" : "#6938EF",
          fontWeight: 500,
          fontSize: "14px",
        },

        secondaryblack: {
          background: "linear-gradient(0deg, #161B26, #161B26)",
          boxShadow: "0px 1px 2px 0px #1018280D",

          textTransform: "none",
          borderRadius: "8px",
          fontFamily: "Inter",
          border: "1px solid #333741",
          color: "#CECFD2",
          fontWeight: 500,
          fontSize: "14px",
          "&:hover": {
            background: "linear-gradient(0deg, #333741, #333741)",
            color: "#FFFFFF",
          },
        },
        textWhite: {
          textTransform: "none",
          fontFamily: "Inter",
          color: "#94969C",
        },
        text: {
          textTransform: "none",
          fontFamily: "Inter",
        },
      },

      variants: [
        {
          props: { variant: "gray" },
          style: {
            textTransform: "none",
            border: `1px solid #D0D5DD}`,
            background: "#ffffff",
            borderRadius: "8px",
            color: "#344054",
            padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #1018280D",
            ...text_md_medium,

            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0",
            },
          },
        },
        {
          props: { variant: "gray" },
          style: {
            textTransform: "none",
            border: `1px solid #D0D5DD}`,
            background: "#ffffff",
            borderRadius: "8px",
            color: "#344054",
            padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #1018280D",
            ...text_md_medium,

            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0",
            },
          },
        },
        {
          props: { variant: "whitebtn" },
          style: {
            textTransform: "none",
            border: `1px solid ${p_colors["600"]}`,
            background: white_color,
            borderRadius: "8px",
            color: black_color,
            padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #1018280D",
            ...text_md_medium,
            "&:hover": {
              backgroundColor: p_colors["600"],
              color: black_color,
            },
            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0",
            },
          },
        },
        {
          props: { variant: "whiteIconBtn" },
          style: {
            textTransform: "none",
            border: `1px solid ${p_colors["600"]}`,
            background: white_color,
            borderRadius: "8px",
            color: black_color,
            // padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #1018280D",
            ...text_md_medium,
            minWidth: "5px",

            "&:hover": {
              backgroundColor: p_colors["600"],
              color: black_color,
            },
            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0",
            },
          },
        },
        {
          props: { variant: "whiteIconBtnDenger" },
          style: {
            textTransform: "none",
            border: `1px solid ${p_colors["600"]}`,
            background: white_color,
            borderRadius: "8px",
            color: "#B42318",
            // padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #101828",
            ...text_md_medium,
            minWidth: "5px",

            "&:hover": {
              backgroundColor: p_colors["600"],
              color: black_color,
            },
            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0",
            },
          },
        },
        {
          props: { variant: "whitebtnOutline" },
          style: {
            textTransform: "none",
            // border: `1px solid ${p_colors["600"]}`,

            borderRadius: "8px",

            padding: "10px 18px 10px 18px",
            boxShadow: "0px 1px 2px 0px #1018280D",
            ...text_md_medium,
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed ${red[500]}`,
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "text_md_medium" },
          style: {
            ...text_md_medium,
          },
        },
        {
          props: { variant: "text_sm_medium" },
          style: {
            ...text_sm_medium,
          },
        },
        {
          props: { variant: "text_sm_normal" },
          style: {
            ...text_sm_normal,
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          borderRadius: "8px",
        },
      },
    },
    MuiSwitch: MuiSwitch,
  },

  custom: {
    text_md_normal: {
      ...text_md_normal,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
});
export default Theme;
