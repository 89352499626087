import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as InlineEmbed } from "../../../Images/InlineEmbed.svg";
import { ReactComponent as InlineEmbedLight } from "../../../Images/InlineEmbedLight.svg";
import { ReactComponent as PopupAssistant } from "../../../Images/PopupAssistant.svg";
import { ReactComponent as PopupAssistantLight } from "../../../Images/PopupAssistantLight.svg";
import { ReactComponent as PopupTooltip } from "../../../Images/PopupTooltip.svg";
import { ReactComponent as PopupTooltipLight } from "../../../Images/PopupTooltipLight.svg";
import {
  getAssistanceVersion,
  getAssistantEmbeddedCode,
} from "../../../services/app/api";
import snackbar from "../../../services/app/snackbar";
import {
  bubblePosition,
  overlaysOption,
  themeFontFamilies,
  themeFontSize,
} from "../../../services/constants";
import { copyToClipboard } from "../../../services/generalFunctions";
import { handleLoading } from "../../../store/collectionsSlice";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../UI/CodeHighlighter";
import ColorPicker from "../../UI/ColorPicker";
import { CodeIcon } from "../../UI/IconPack";
import MuiModal from "../../UI/MuiModal";
import MuiSelect from "../../UI/MuiSelect";

const Selector = (props) => {
  const { handleClose, handleScreenChange, selectedType, handelSelectedType } =
    props;
  const { mode } = useThemeContext();

  const attachTypes = [
    {
      icon: mode === "dark" ? <PopupAssistant /> : <PopupAssistantLight />,
      title: window.location.pathname.includes("/onboard/collections/")
        ? "Popup prompt"
        : "Popup assistant",
      description:
        "Adds a floating button to your site that launches the assistant as a popup.",
    },
    {
      icon: mode === "dark" ? <InlineEmbed /> : <InlineEmbedLight />,
      title: "Inline embed",
      description: "Loads the assistant chat directly into your website.",
    },
    {
      icon: mode === "dark" ? <PopupTooltip /> : <PopupTooltipLight />,
      title: "Popup tooltip",
      description:
        "Link the assistant to an info icon to respond to queries related to that element.",
    },
  ];
  const handleCloseModal = () => {
    handelSelectedType(null);
    handleClose();
  };
  return (
    <div>
      <div className="flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div className="h-14 w-14 rounded-full text-brandPrimary-light dark:text-textPrimary bg-brand-secondary flex items-center justify-center">
            <CodeIcon height={24} width={24} />
          </div>
          <div>
            <p className="text-lg text-primary">Add assistant to a website</p>
            <p className="text-sm text-tertiary">
              How do you want to add the assistant to your website?
            </p>
          </div>
        </div>
        <div>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon className="text-tertiary" />
          </IconButton>
        </div>
      </div>
      <div className="flex items-center gap-5 justify-center p-5">
        {attachTypes.map((item) => (
          <div
            key={item.title}
            onClick={() => handelSelectedType(item?.title)}
            className={`border-1 w-[30%] cursor-pointer h-64 ${
              selectedType === item.title
                ? "border-brand-primary"
                : "border-primary"
            } rounded-lg p-2 flex flex-col gap-1 items-center`}
          >
            <div>{item.icon}</div>
            <p className="text-base text-primary">{item.title}</p>
            <p className="text-sm text-tertiary text-center">
              {item.description}
            </p>
          </div>
        ))}
      </div>
      {window.location.pathname.includes("/onboard/collections/") && (
        <div className="px-10">
          <RadioGroup
            value={selectedType}
            onChange={(e) => handelSelectedType(e?.target.value)}
          >
            <FormControlLabel
              control={<Radio />}
              label={"Integration by own function"}
              value={"Integration by own function"}
              className="text-base text-primary"
            />
          </RadioGroup>
        </div>
      )}
      <div className="flex justify-end gap-4 p-5 border-t-1 border-primary ">
        <Button
          sx={
            mode === "light" && {
              color: "#344054",
              borderColor: "#D0D5DD",
            }
          }
          variant="outlined"
          color="secondary"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!selectedType}
          onClick={handleScreenChange}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const ThemeCreator = (props) => {
  const {
    handleClose,
    handleScreenChange,
    title,
    handelSelectedType,
    htmlTag,
  } = props;
  const { colors, mode } = useThemeContext();
  const [bgColor, setBgColor] = useState("#F5F5F6");
  const [primary, setPrimary] = useState("#7A5AF8");
  const [secondary, setSecondary] = useState("#161B26");
  const [fontSize, setFontSize] = useState("16px");
  const [scriptTag, setScriptTag] = useState(htmlTag);
  const [fontFamily, setFontFamily] = useState("Inter");
  const [position, setPosition] = useState("bottom-right");
  const [overlay, setOverlay] = useState("over-page");

  const formattedScript = () => {
    const theme = btoa(
      JSON.stringify({
        backgroundColor: bgColor,
        primaryChatBubbleColor: primary,
        secondaryChatBubbleColor: secondary,
        fontSize: fontSize,
        fontFamily: fontFamily,
        position: position,
        overlay: overlay,
      })
    );
    const mainDiv = document.createElement("div");
    mainDiv.innerHTML = htmlTag;
    let script = mainDiv.children[0];
    script.setAttribute("data-theme", theme);
    let serializer = new XMLSerializer();
    let htmlString = serializer.serializeToString(script);
    setScriptTag(htmlString);
  };

  useEffect(() => {
    formattedScript();
  }, [
    htmlTag,
    bgColor,
    primary,
    secondary,
    fontFamily,
    fontSize,
    position,
    overlay,
  ]);

  const handleCloseModal = () => {
    handelSelectedType(null);
    handleClose();
  };

  const handleCopyToClipboard = () => {
    formattedScript();
    copyToClipboard(scriptTag);
    handleCloseModal();
    handleScreenChange();
  };

  const showPositionMenu =
    title === "Popup prompt" || title === "Popup assistant";

  return (
    <div>
      <div className="flex justify-between items-center p-5">
        <div className="flex items-center gap-4">
          <div>
            <p className="text-lg text-primary">{title}</p>
            <p className="text-sm text-tertiary">
              Customize the look of your assistant to fit seamlessly into your
              website.
            </p>
          </div>
        </div>
        <div>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon className="text-tertiary" />
          </IconButton>
        </div>
      </div>
      <div className="flex items-start gap-5  pl-5 border-y-1 border-primary">
        <div className="w-[40%] p-4">
          <div className="text-sm text-primary  mb-2">Text style</div>
          <div className="text-xs my-1 text-tertiary">Typography</div>
          <MuiSelect
            menuItems={themeFontFamilies}
            value={fontFamily}
            onChange={(e) => {
              setFontFamily(e.target.value);
            }}
            selectClassName={"bg-primary"}
          />
          <div className="text-xs my-1 mt-4 text-tertiary">Font size</div>
          <MuiSelect
            selectClassName={"bg-primary"}
            menuItems={themeFontSize}
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          />
          <div className="text-sm text-primary  my-2">Color palette</div>
          <div className="flex gap-4 mb-2 items-center justify-between text-sm text-tertiary">
            Background Color{" "}
            <ColorPicker value={bgColor} setValue={(v) => setBgColor(v)} />
          </div>
          <div className="flex gap-4 mb-2 items-center justify-between text-sm text-tertiary">
            Primary chat bubble{" "}
            <ColorPicker value={primary} setValue={(v) => setPrimary(v)} />
          </div>
          <div className="flex gap-4 items-center justify-between text-sm text-tertiary">
            Secondary chat bubble{" "}
            <ColorPicker value={secondary} setValue={(v) => setSecondary(v)} />
          </div>
          {showPositionMenu && (
            <>
              <div className="text-xs my-1 text-tertiary">Location</div>
              <MuiSelect
                menuItems={bubblePosition}
                selectClassName={"bg-primary"}
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
              />
              <div className="text-xs  text-tertiary">Overlays</div>
              <MuiSelect
                menuItems={overlaysOption}
                value={overlay}
                selectClassName={"bg-primary"}
                onChange={(e) => {
                  setOverlay(e.target.value);
                }}
              />
            </>
          )}
        </div>
        <div className="w-[60%] bg-secondary p-4 h-full">
          <div>
            <p className="text-base text-primary">Embed Code</p>
            <p className="text-xs text-tertiary">
              Place this code in your HTML where you want your Scalaix Assistant
              widget to appear.
            </p>
          </div>
          <div className="mt-5 border-1 border-primary rounded-lg">
            <CodeHighlighter
              customStyle={{
                background: colors.bgPrimary,
                height: showPositionMenu ? "385px" : "300px",
                overflow: "auto",
              }}
              style={mode === "dark" ? "vs2015" : "a11yLight"}
              language={"xml"}
              wrapLongLines={false}
              wrapLines={false}
            >
              {scriptTag}
            </CodeHighlighter>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 p-5 ">
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleScreenChange}
          sx={
            mode === "light" && {
              color: "#344054",
              borderColor: "#D0D5DD",
            }
          }
        >
          Back
        </Button>
        <Button variant="contained" onClick={handleCopyToClipboard}>
          Copy Code
        </Button>
      </div>
    </div>
  );
};
const AddToWeb = (props) => {
  const { open, handleClose } = props;
  const [activeScreen, setActiveScreen] = useState("selector");
  const [selectedType, setSelectedType] = useState(null);
  const [htmlTag, setHtmlTag] = useState("");
  const activeAssistant = useSelector(
    (state) => state?.assistantSlice?.editAIAssistant
  );
  const dispatch = useDispatch();
  const handleScreenChange = () => {
    if (activeScreen === "selector") {
      if (window.location.pathname.includes("/onboard/collections/")) {
        const tags = {
          "Popup prompt": "pop_up",
          "Inline embed": "inline_html",
          "Popup tooltip": "cta_pop_up",
          "Integration by own function": "executer_by_own_function_calback",
        };
        dispatch(handleLoading(true));
        getAssistanceVersion(open, tags[selectedType])
          .then((res) => {
            setHtmlTag(res.tag_html);
            setActiveScreen("theme");
          })
          .catch((error) => {
            snackbar.error({
              title: `${error.error && error.error.toString()}`,
            });
          })
          .finally(() => dispatch(handleLoading(false)));
      } else {
        const tags = {
          "Popup assistant": "assistance_pop_up",
          "Inline embed": "assistance_inline_html",
          "Popup tooltip": "assistance_cta_pop_up",
        };
        dispatch(handleLoading(true));
        getAssistantEmbeddedCode(activeAssistant?.id, tags[selectedType])
          .then((res) => {
            setHtmlTag(res?.tag_html);
            setActiveScreen("theme");
          })
          .catch((error) => {
            snackbar.error({
              title: `${error.error && error.error.toString()}`,
            });
          })
          .finally(() => {
            dispatch(handleLoading(false));
          });
      }
    } else {
      setActiveScreen("selector");
    }
  };
  const handelSelectedType = (type) => setSelectedType(type);

  const screens = {
    selector: (
      <Selector
        handleScreenChange={handleScreenChange}
        handleClose={handleClose}
        selectedType={selectedType}
        handelSelectedType={handelSelectedType}
      />
    ),
    theme: (
      <ThemeCreator
        handleScreenChange={handleScreenChange}
        handleClose={handleClose}
        title={selectedType}
        handelSelectedType={handelSelectedType}
        htmlTag={htmlTag}
      />
    ),
  };

  return (
    <>
      <MuiModal open={open}>{screens[activeScreen]}</MuiModal>
    </>
  );
};

export default AddToWeb;
