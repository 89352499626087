import { useAuth } from "@Components/Auth/AuthContext";
import { savePrompt, updatePrompt } from "@Services.App/api";
import snackbarService from "@Services.App/snackbar";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatOutputKeysToEdit,
  formatOutputKeysToSave,
  formatTimestampToDate,
  isValidXML,
} from "../../../services/generalFunctions";
import {
  fetchContexts,
  fetchEnvironments,
  fetchPrompts,
  handleChangEditPrompt,
  handleLoading,
  handleUpdatePromptId,
} from "../../../store/collectionsSlice";
import {
  CheckCircleBrokenIcon,
  ClockCountIcon,
  FileDraftIcon,
} from "../../UI/IconPack";
import CollectionVersions from "../common/CollectionVersions";
import Collections from "./Collections";
import PublishCollection from "./PublishCollection";

const PostmanInterface = () => {
  const dispatch = useDispatch();
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const {
    allPromptsData,
    allEnvironments,
    searchEnvironment,
    searchPrompt,
    searchContext,
  } = newPromptsData;
  const { editPromptsList } = newPromptsData;
  const location = useLocation();
  const { activeTeam } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [openPublishCollection, setOpenPublishCollection] = useState(false);
  const activeWindow = useSelector(
    (state) => state.collectionsSlice.activeWindow
  );
  const [storeTeamId, setStoreTeamId] = useState(activeTeam);
  const [openCollectionVersions, setOpenCollectionVersions] = useState(false);
  const [selectedCollectionName, setSelectedCollectionName] = useState("");

  const handelOpenCollectionVersion = () => setOpenCollectionVersions(true);
  const handelCloseCollectionVersion = () => {
    setOpenCollectionVersions(false);
    setSelectedCollectionName("");
  };

  useEffect(() => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    dispatch(fetchContexts(payload));
  }, [activeTeam]);

  useEffect(() => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    if (searchContext) {
      payload["params"]["searchKey"] = searchContext;
    }

    let debouncePrompt = setTimeout(() => {
      dispatch(fetchContexts(payload));
    }, 600);
    return () => clearTimeout(debouncePrompt);
  }, [searchContext]);

  useEffect(() => {
    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: 1,
        limit: 10,
      },
    };
    dispatch(fetchPrompts(payload));
  }, []);

  useEffect(() => {
    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: 1,
        limit: 10,
      },
    };
    if (searchPrompt) {
      payload["params"]["searchKey"] = searchPrompt;
    }

    let debouncePrompt = setTimeout(() => {
      dispatch(fetchPrompts(payload));
    }, 600);
    return () => clearTimeout(debouncePrompt);
  }, [searchPrompt]);

  useEffect(() => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    if (!allEnvironments?.items) {
      dispatch(fetchEnvironments(payload));
    }
  }, []);

  useEffect(() => {
    var payload = {
      team_id: activeTeam,
      params: {
        page: 1,
        limit: 10,
      },
    };
    if (searchEnvironment) {
      payload["params"]["searchKey"] = searchEnvironment;
    }
    let debounceEnv = setTimeout(() => {
      dispatch(fetchEnvironments(payload));
    }, 600);
    return () => clearTimeout(debounceEnv);
  }, [searchEnvironment]);

  useEffect(() => {
    if (storeTeamId !== activeTeam || !location.state?.collection?.name) {
      navigate("/onboard/collections", { replace: true });
    }
  }, [activeTeam, location.state]);

  const validatePromptSelect = (promptData) => {
    let hasErrors = false;
    let allErrors = [...promptData?.errors] || [];
    if (!promptData?.llmModel) {
      hasErrors = true;
      allErrors.push({
        title: "LLM model",
        description: "Please select LLM model before saving prompt",
        type: "error",
      });
    }
    if (!promptData?.llmVersion) {
      hasErrors = true;
      allErrors.push({
        title: "LLM version model",
        description: "Please select LLM model before saving prompt",
        type: "error",
      });
    }
    if (promptData?.model_type === "Image") {
      if (!promptData?.imageSize) {
        hasErrors = true;
        allErrors.push({
          title: "Image size",
          description: "Please select image size",
          type: "error",
        });
      }
    }

    if (promptData?.model_type === "Image") {
      if (!promptData?.processType) {
        hasErrors = true;
        allErrors.push({
          title: "Process type model",
          description: "Please select process type",
          type: "error",
        });
      }
    } else {
      if (!promptData?.outputFormat) {
        let data = {
          ...promptData,
          activeScreen: "Output",
        };
        dispatch(handleChangEditPrompt(data));

        hasErrors = true;
        allErrors.push({
          title: "Output format",
          description: "Please select output format",
          type: "error",
        });
      }
    }
    const promptList = promptData?.prompt_text?.prompt_data;
    if (promptList?.some((item) => !item?.action_val)) {
      allErrors.push({
        title: "Invalid Value",
        description: "You can not keep empty value parameter of prompt.",
        type: "warning",
      });
      hasErrors = true;
    }
    if (promptList?.some((item) => !item?.name)) {
      allErrors.push({
        title: "Invalid Key",
        description: "You can not keep empty key parameter of prompt.",
        type: "warning",
      });
      hasErrors = true;
    }
    let data = {
      ...promptData,
      errors: allErrors,
    };
    dispatch(handleChangEditPrompt(data));
    return hasErrors;
  };

  const savePromptHandler = async () => {
    const promises = editPromptsList?.map(async (promptData) => {
      if (validatePromptSelect(promptData)) {
        return;
      }
      let outputKeys = formatOutputKeysToSave(promptData);
      if (promptData?.outputFormat === "xml") {
        if (!isValidXML(promptData?.output_keys["xml"])) {
          snackbarService.warning({
            title: `${promptData?.name} - Please add valid XML format`,
          });
          dispatch(handleLoading(false));
          return;
        }
      }
      if (promptData?.isEdited) {
        let payload = {
          collection_id: params?.collectionId,
          data: {
            name: promptData?.name,
            prompt_text: {
              prompt_data: promptData?.prompt_text?.prompt_data,
            },
            tags: promptData?.tags,
            prompt_data: promptData?.prompt_data,
            category: "base",
            llm_version_id: promptData?.llmVersion,
            output_format: promptData?.outputFormat,
            output_keys: outputKeys,
            cache_setting: promptData?.cache_setting,
          },
        };
        if (!!promptData?.prompt_data) {
          payload["data"]["prompt_data"] = promptData?.prompt_data;
        }
        if (!!promptData?.context) {
          payload["data"]["context"] = promptData?.context;
        }
        if (!!promptData?.variables) {
          var all_variable = [];
          promptData?.variables.map((elem) => {
            var ele = {
              variable_key: elem.variable_key,
              description: elem?.description,
              type: elem.type,
            };
            if (elem.type === "file") {
              ele["file_id"] = elem?.file_id;
              ele["transformer_id"] = elem?.transformer_id;
            }
            all_variable.push(ele);
          });
          payload["data"]["variables"] = all_variable;
        }
        if (!!promptData?.description) {
          payload["data"]["description"] = promptData?.description;
        }
        if (!!promptData?.environment_id) {
          payload["data"]["environment_id"] = promptData?.environment_id;
        }
        if (promptData?.model_type === "Image") {
          //image genration
          payload["data"]["prompt_type"] = promptData?.processType || "create";
          payload["data"]["variation_count"] = promptData?.variationCount;
        } else {
          //text
          payload["data"]["prompt_type"] = "text";
          payload["data"]["variation_count"] = 1;
        }
        if (!!promptData?.assistantId) {
          payload["data"]["assistance_id"] = promptData?.assistantId;
          payload["data"]["variation_count"] = 1;
          payload["data"]["prompt_type"] = "conversation";
          payload["data"]["file_ids"] = promptData?.file_ids;
        }

        let successMessage = "";
        let promptApi = undefined;
        if (promptData?.id?.includes("newPromptId")) {
          promptApi = savePrompt;
          successMessage = `Prompt Added`;
        } else {
          payload = { ...payload, prompt_id: promptData.id };
          promptApi = updatePrompt;
          successMessage = "Prompt updated";
        }

        dispatch(handleLoading(true));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        try {
          const res = await promptApi(payload);
          let outputKeys = formatOutputKeysToEdit(res);
          if (successMessage === "Prompt Added") {
            let data = {
              oldId: promptData?.id,
              ...promptData,
              ...res,
              output_keys: outputKeys,
            };
            dispatch(handleUpdatePromptId(data));
          } else {
            let data = {
              ...promptData,
              ...res,
              output_keys: outputKeys,
            };
            dispatch(handleChangEditPrompt(data));
          }
        } catch (error) {
          console.log(error);
          snackbarService.error({
            title: `${error.error && error.error.toString()}`,
          });
        } finally {
          dispatch(handleLoading(false));
        }
      }
    });

    await Promise.all(promises);

    var payload = {
      collection_id: params?.collectionId,
      params: {
        page: allPromptsData?.page || 1,
        limit: 10,
        searchKey: searchPrompt,
      },
    };

    await dispatch(fetchPrompts(payload));
  };

  const handleOpenPublishCollection = () => setOpenPublishCollection(true);
  const handleClosePublishCollection = () => setOpenPublishCollection(false);

  return (
    <div className="bg-secondary">
      <PublishCollection
        open={openPublishCollection}
        handleClose={handleClosePublishCollection}
      />
      <div
        style={{
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            borderRadius: "8px",
            paddingTop: "1px",
            width: "100%",
          }}
        >
          <div className="flex items-center justify-between my-4 mx-8">
            <div>
              <div className="flex gap-4 items-center">
                <p className="display-sm-semibold  text-primary  ">
                  {location.state?.collection?.name || ""}
                </p>
              </div>
              <p className="text-sm-regular text-tertiary ">
                Last edited:{" "}
                {formatTimestampToDate(
                  location.state?.collection?.updated_at,
                  "MM-DD-YYYY"
                )}
                {" at "}
                {formatTimestampToDate(
                  location.state?.collection?.updated_at,
                  "hh:mm A"
                )}
              </p>
            </div>
            {activeWindow === "collection" && (
              <div className="flex items-center gap-2">
                <CollectionVersions
                  open={openCollectionVersions}
                  handleModal={handelCloseCollectionVersion}
                  collectionName={selectedCollectionName}
                />
                <Button
                  onClick={savePromptHandler}
                  variant="outlined"
                  color="secondary"
                  startIcon={<FileDraftIcon />}
                >
                  Save as Draft
                </Button>
                <Button
                  onClick={() => {
                    handelOpenCollectionVersion();
                    setSelectedCollectionName(location.state?.collection?.name);
                  }}
                  variant="outlined"
                  color="secondary"
                  startIcon={<ClockCountIcon />}
                >
                  Version history
                </Button>
                <Button
                  onClick={handleOpenPublishCollection}
                  variant="outlined"
                  color={"secondary"}
                  sx={{
                    width: "120px",
                  }}
                  endIcon={<CheckCircleBrokenIcon />}
                >
                  Deploy
                </Button>
              </div>
            )}
          </div>
          <div className=" w-full"></div>
          <Collections />
        </div>
      </div>
    </div>
  );
};

export default PostmanInterface;
