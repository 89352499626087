import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import { TrashIcon } from "./IconPack";

export default function MuiModal(props) {
  const { colors, mode } = useThemeContext();
  const {
    handleClose,
    open,
    title,
    message,
    handleConfirm,
    width = "55%",
    hideBackdrop = false,
    bgcolor,
    borderRadius = 2,
    showDeleteIcon = false,
    confirmLabel = "Confirm",
    cancelLabel = "Cancel",
    showCloseIcon = true,
  } = props;
  const backColor = bgcolor ? bgcolor : colors.bgPrimary;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : 400,
    bgcolor: backColor || "background.paper",
    boxShadow: 24,
    borderRadius: borderRadius || 0,
  };

  const MessageBox = () => (
    <>
      <div className={showDeleteIcon && "flex items-center"}>
        {showDeleteIcon && (
          <div className="h-14 w-14 ml-4 text-textPrimary rounded-full flex items-center justify-center bg-bgErrorSecondary">
            <TrashIcon height={20} width={20} />
          </div>
        )}
        <div>
          <div className=" flex flex-col px-4 text-white h-16 w-full pt-10">
            {showCloseIcon && (
              <div className="absolute top-2 right-2">
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon className="text-tertiary" />
                </IconButton>
              </div>
            )}
            <Typography
              id="modal-modal-title"
              variant="p"
              className="text-[26px] text-primary"
            >
              {title}
            </Typography>
          </div>
          {message && (
            <div className=" m-2 sm:m-6 mb-3 pb-1">
              <Typography
                id="modal-modal-title"
                variant="p"
                className="text-base text-tertiary  "
              >
                {message}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end m-4 mr-2 sm:mr-6 gap-4 ">
        <Button
          sx={
            mode === "light" && {
              color: "#344054",
              borderColor: "#D0D5DD",
            }
          }
          onClick={handleClose}
          variant="outlined"
          color="secondary"
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color={showDeleteIcon ? "error" : "primary"}
        >
          {confirmLabel}
        </Button>
      </div>
    </>
  );
  return (
    <div>
      <Modal
        disableAutoFocus={true}
        hideBackdrop={hideBackdrop}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.children ? props.children : <MessageBox />}</Box>
      </Modal>
    </div>
  );
}
