import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import PlanetScreen from "../UI/PlanetScreen";

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);
  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
}

class ErrorBoundaryInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, _previousState) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(_error, _errorInfo) {
    this.props.setHasError(true);
  }

  handleGoBack = () => {
    window.history.back();
  }

  render() {
    return this.state.hasError ? (
      <PlanetScreen>
        <p className="text-5xl">Something went wrong...! :(</p>
        <p className="text-grayEditor400 mt-4">
          Please report this issue to admin
        </p>
        <p className="bg-white text-black w-16 mt-4 cursor-pointer text-base" onClick={this.handleGoBack}>Go back</p>
      </PlanetScreen>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
