import FilledTextField from "@UI/FilledTextField";
import { ReloadIcon } from "@UI/IconPack";
import { Button } from "@mui/material";
import jsPDF from "jspdf";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { llmModelsIcon } from "../../../../services/constants";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CompareData, { AverageResult } from "./CompareData";
const PromptCompare = ({ handleComparePrompt, handleResetCompare }) => {
  const templateRef = useRef(null);
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList, modelsVersionsList, aiModelsList, allPromptsData } =
    newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const averageData = promptData?.compareResultResponse?.compare_data;
  const dispatch = useDispatch();
  const { colors } = useThemeContext();

  const handleIterationChange = (_, v) => {
    let data = {
      ...promptData,
      iteration_cnt: v,
    };
    dispatch(handleChangEditPrompt(data));
  };
  const createPdg = () => {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        format: "letter",
        unit: "px",
        hotfixes: ["px_scaling"],
      });

      const html = templateRef.current;

      var tr_div = document.createElement("div");
      tr_div.style.color = "blck";
      tr_div.innerHTML = document.getElementById("sssssssssssssss").innerHTML;
      doc.html(tr_div, {
        callback(doc) {
          doc.save("result");
        },
        margin: [40, 20, 40, 20],
      });
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div className="p-5  w-full">
      <div
        className="bg-secondary border-1 rounded-lg border-secondary py-6 px-5 flex gap-2 items-center justify-between"
        display={"flex"}
        // alignItems={"center"}
        // gap={2}
        // justifyContent={"space-between"}
        px={10}
        // width={"60%"}
        m={"auto"}
      >
        <div className="flex gap-2 items-center">
          <div className="text-md-medium w-36"> Iteration count:</div>
          <div className="w-">
            <FilledTextField
              // label="Response Instruction"
              disabled={promptData?.comparisonProgress}
              value={promptData?.iteration_cnt}
              type="number"
              onChange={(e) => handleIterationChange("value", e.target.value)}
              // onBlur={(e) => handleChangeFields(e, "value")}
              // placeholder={"Enter response instruction"}
            />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          {averageData ? (
            <div className="flex gap-2 items-center">
              <Button
                startIcon={<ReloadIcon />}
                variant="outlined"
                color="secondary"
                disabled={promptData?.comparisonProgress}
                onClick={handleResetCompare}
                sx={{ background: colors.bgPrimary }}
              >
                Reset Comparison
              </Button>
              {/* <Button
                variant="contained"
                onClick={createPdg}
                startIcon={<DownloadIcon />}
                disabled={promptData?.comparisonProgress}
              >
                Download Results
              </Button> */}
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                handleComparePrompt();
              }}
              // startIcon={<DownloadIcon />}
              disabled={promptData?.comparisonProgress}
            >
              Compare Models
            </Button>
          )}
        </div>
        {/* <FormLabel
          htmlFor="variation_count"
          sx={{ color: colors.textTertiary, fontSize: 14 }}
        >
          Iteration count:
        </FormLabel>
        <div className="w-[70%]">
          <Slider
            defaultValue={1}
            step={1}
            max={30}
            min={1}
            aria-label="response"
            name="variation_count"
            valueLabelDisplay="auto"
            value={promptData?.iteration_cnt}
            onChange={handleIterationChange}
          />
        </div> */}
      </div>
      <div
        ref={templateRef}
        className="fffff"
        id="sssssssssssssss"
        style={{ display: "none" }}
      >
        <div className="my-5 grid grid-cols-2 gap-6 w-full">
          {promptData?.compare_data.map(
            (compare, index) =>
              !!averageData && (
                <div className="w-full">
                  <div>
                    <div className="text-md-medium flex  gap-2 items-center">
                      {
                        llmModelsIcon("40px")[
                          compare?.aiModelsList?.filter((item) => {
                            return item.id == compare?.llmModel;
                          })?.[0]?.name
                        ]
                      }
                      <p>
                        {
                          compare?.aiModelsList?.filter((item) => {
                            return item.id == compare?.llmModel;
                          })?.[0]?.name
                        }
                      </p>
                      <p>
                        {
                          compare?.modelsVersionsList?.filter((item) => {
                            return item.id == compare?.model_version_id;
                          })?.[0]?.name
                        }
                      </p>
                    </div>
                    <div className="border-t-1 border-primary my-5" />
                  </div>
                  <div>
                    <p className=" ">Result</p>

                    <AverageResult
                      data={averageData[index]}
                      data1={averageData[index == 0 ? 1 : 0]}
                    />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div
        // className="border-t-1 border-primary my-5"
        className=" py-5"
      />

      <div className="w-full flex gap-8 pb-12">
        {promptData?.compare_data.map((compare, index) => (
          <CompareData key={index} compare={compare} index={index} />
        ))}
      </div>
    </div>
  );
};

export default PromptCompare;
