import React from "react";
import util from "@Services.Core/util";
import { Paper, Popover, Typography } from "@mui/material";

export const INFO = "info";
export const TOP = "top";
export const BOTTOM = "bottom";
export const LEFT = "left";
export const RIGHT = "right";

export const CANCEL = "cancel";

class PopoverManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popovers: [], anchorEl: null };
  }

  newKey() {
    return `popover__${util.generateGuid()}`;
  }

  getPopovers() {
    return this.state.popovers;
  }

  closePopover(e, { key, onClose } = {}) {
    if (!key) return;
    onClose && onClose(e);
    this.setState({
      popovers: this.getPopovers().filter((popover) => popover.key !== key),
    });
  }

  customClosePopover(e, anchorEl) {
    this.closePopover(
      e,
      this.getPopovers().filter((popover) => popover.anchorEl == anchorEl)
    );
  }

  showPopover(props = {}) {
    const key = this.newKey();
    this.setState({
      popovers: this.getPopovers().concat({ ...props, key, valid: true }),
    });
    return { key };
  }

  valid({ key } = {}, valid = true) {
    this.setState({
      popovers: this.getPopovers().map((popover) =>
        popover.key === key ? { ...popover, valid } : popover
      ),
    });
  }

  getPopoverAnchorEl(popover = {}) {
    return popover.anchorEl;
  }

  getText(popover = {}) {
    return popover.text || "Popover";
  }
  renderText(popover = {}) {
    return this.getText(popover);
  }
  renderPopovers(popover = {}) {
    return (
      <Popover
        id="mouse-over-popover"
        //       className={classes.popover}
        //       classes={{
        //           paper: classes.paper
        //       }}
        open={Boolean(popover)}
        anchorEl={this.getPopoverAnchorEl(popover)}
        anchorOrigin={{
          vertical: popover.anchorVertical,
          horizontal: popover.anchorHorizontal,
        }}
        transformOrigin={{
          vertical: popover.transformVertical,
          horizontal: popover.transformHorizontal,
        }}
        //       onMouseLeave={(e) => this.closePopover(e, popover)}
        onClose={(e) => this.closePopover(e, popover)}
      >
        <Paper className="p-3">
          <Typography> {this.renderText(popover)}.</Typography>
        </Paper>
      </Popover>
    );
  }

  render() {
    return this.getPopovers().map((popover) => this.renderPopovers(popover));
  }
}

export default PopoverManager;
