import storage from "@Services.App/storage";
import axios, {CancelToken, isCancel } from "axios";

const TIMEOUT = 3600000; // 6 seconds

const CONTEN_TYPE_JSON = "application/json";

axios.defaults.headers.post["Content-Type"] = CONTEN_TYPE_JSON;
axios.defaults.timeout = TIMEOUT;
const UNKNOWN_ERR_MSG =
  "An unknown server error has occurred or maybe the server is unreachable. Please get in touch with the admin team.";
axios.interceptors.request.use((config) => {
  const token = storage.getAuthToken();
  const brand_id = storage.getNewBrandLocalStorageID();
  if (brand_id != "null" && brand_id != null) {
    token && (config.headers.Authorization = `Bearer ${token} ${brand_id}`);
  } else {
    token && (config.headers.Authorization = `Bearer ${token}`);
  }
  // token && (config.headers.Authorization = `Bearer ${token} ${brand_id}`);
  return config;
});

class Http {
  makeRequest = ({
    method = "get",
    headers = {},
    url = "/",
    data = {},
    params = {},
    onUploadProgress = () => {},
    cancelToken = new CancelToken((cance) => {
    }),
  }) => {
    return new Promise((resolve, reject) => {
      axios({
        method,
        headers,
        url,
        data,
        params,
        onUploadProgress,
        cancelToken,
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          if (isCancel(error)) {
            reject({
              status: error?.response?.status,
              data: error?.response?.data,
              error: "File upload canceled.",
            });
          }
          console.log(error);
          if (error.response) {
            console.error(error.response);
            if (error.response.status === 403) {
              storage.clear();
              storage.redirectTologin("/");
            }
            if (error.response.status === 500) {
              reject({
                status: error.response.status,
                data: error.response.data,
                error:
                  "Unfortunately, something went wrong. We apologize for the inconvenience and kindly request that you try again later.",
              });
            } else {
              reject({
                status: error.response.status,
                data: error.response.data,
                error:
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : UNKNOWN_ERR_MSG,
              });
            }
          } else if (error.request) {
            console.error(error.request);
            reject({
              error:
                error.request && error.message
                  ? error.message
                  : UNKNOWN_ERR_MSG,
            });
            // reject({ error: new Error(UNKNOWN_ERR_MSG) });
          } else {
            // console.error(error.message);
            reject({ error: UNKNOWN_ERR_MSG });
          }
        });
    });
  };

  get = (params) => {
    return this.makeRequest({ ...params, method: "get" });
  };

  post = (params) => {
    return this.makeRequest({ ...params, method: "post" });
  };

  put = (params) => {
    return this.makeRequest({ ...params, method: "put" });
  };

  delete = (params) => {
    return this.makeRequest({ ...params, method: "delete" });
  };
}

export default new Http();
