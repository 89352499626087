export default {
  "what is prompt":
    'A prompt is a written instruction or query given to a language model, guiding it to generate a specific type of response; for example, "Translate the following English text into French."',
  "what is mask image":
    "Mask image is an input that guides the model in specific regions, influencing the content or appearance of the generated images.",
  "what is input image":
    "Input image is a reference image that serves as a textual prompt for generating a diverse set of images",
  "what is prompt key":
    '"key" is typically refers to the input or query provided to the model',
  "what is prompt value":
    '"value" parameter refers to the desired output or response expected from the model based on that input or query',
  "what is constraint":
    "Define constrains, rules and conditions for how the AI assistant should behave in different scenarios.",
  "rules and conditions": "Rules and conditions",
  "constraint helpers":
    "Identify the topics and subjects to which you do not want the assistant to respond.",
  "what is instruction":
    "Determine the specific tasks and functions you want the AI assistant to perform.",
  "general instruction": "General Instructions",
  "advance assistant settings":
    "Configure the languages supported by the assistant, the tone and the tools.",
  "advance settings": "Advance settings",
  "what is retrieval":
    "Retrieval: Retrieval enables the assistant with knowledge from files that you or your users upload. Once a file is uploaded, the assistant automatically decides when to retrieve content based on user requests.",
  "what is code interpreter":
    "Code Interpreter: Code Interpreter enables the assistant to write and run code. This tool can process files with diverse data and formatting, and generate files such as graphs",
  "what is function":
    "Function: Function calling lets you describe custom functions of your app or external APIs to the assistant. This allows the assistant to intelligently call those functions by outputting a JSON object containing relevant arguments",
  "collect user feedback": "Collect user feedback",
  "link assets": "Link assets",
  "what is link assets":
    "Link your Data Room assets to feed your assistant with contextual information, so it can answer questions properly.",
  "parent assistant": "Parent assistant",
  "language & tone": "Language & tone",
  tone: "Tone",
  languages: "Language's",
  "instructions & constraints": "Instructions & Constraints",
  instructions: "Instructions",
  constraints: "Constraints",
  "assets associated": "Assets associated",
  tools: "Tools",
  "advanced settings": "Advanced settings",
  "rules & conditions": "Rules & Conditions",
  "key words": "Key Words",
  key: "Key",
  value: "Value",
  "response instructions": "Response Instructions",
  prompt: "Prompt",
  variables: "Variables",
  "tokens consumed": "Tokens consumed",
  overview: "Overview",
  "errors & issues": "Errors & Issues",
  "context description": "Context Description",
  "what is context description": "Write purpose to use this context in prompt ",
  "what is context":
    "Context for a prompt helps guide the AI to generate more relevant and accurate responses based on the given situation or background information.",
  context: "Context",
};
