import { Tab as MUITab, Tabs as MUITabs, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
export const CustomTextField = styled((BtnProps) => (
  <TextField
    fullWidth
    variant="outlined"
    size="small"
    // sx={(theme) => {
    //   return {
    //     color: "red",
    //     background: "red",
    //   };
    // }}
    // inputProps={(theme) => {
    //   return {
    //     style: { fontSize: "30px" },
    //   };
    // }}
    {...BtnProps}
  />
))(({ theme }) => ({
  // padding: "10px 14px",
  // border: "1px solid #D0D5DD",
  // ...theme.custom.text_md_normal,
  "& fieldset": {
    border: "0px solid #D0D5DD",
    borderRadius: "8px",
    padding: "8px 14px 8px 14px",
    // backgroundColor: "#22242D",
    color: "white",
  },
  "& .MuiInputBase-root": {
    ...theme.custom.text_md_normal,
    color: "white",
    backgroundColor: "#22242D",
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    color: "white",
  },
  "& .MuiInputBase-input:hover + fieldset": {
    border: `0px solid green`,
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:focus + fieldset": {
    border: `1px solid white`,
    borderRadius: "8px",
  },

  // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  color: "white",
  boxShadow: "0px 1px 2px 0px #1018280D",
  borderRadius: "8px",
}));

export const StartButtonTextField = styled((BtnProps) => (
  <TextField
    sx={(theme) =>
      BtnProps.InputProps
        ? {
            "& .MuiOutlinedInput-input": {
              // background: "red",
              // borderLeft: "1px solid #D0D5DD",
              borderLeft: `1px solid ${theme.palette.borderPrimary}`,
            },

            "& .MuiOutlinedInput-input:hover": {
              // background: "red",
              borderLeft: `1px solid ${theme.palette.borderPrimary}`,
            },
            "& .MuiOutlinedInput-input:disabled": {
              // background: "red",
              borderLeft: `1px solid ${theme.palette.borderPrimary}`,
            },
            "& .MuiOutlinedInput-input:focus": {
              borderLeft: `1px solid ${theme.palette.borderPrimary}`,
              // borderLeft: "2px solid theme.palette.primary.main",
            },
          }
        : null
    }
    inputProps={{
      style: {
        fontFamily: [
          "Inter",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 14,
        // height: 44,
        padding: "10px 14px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",

        // borderRadius: "56px",
      },
    }}
    fullWidth
    // variant="outlined"
    {...BtnProps}
  />
))(({ theme }) => ({
  // padding: "10px 14px",
  // border: "1px solid #D0D5DD",

  "& fieldset": {
    border: "1px solid #333741",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:hover + fieldset": {
    border: `1px solid white`,
    borderRadius: "8px",
  },
  "& .MuiInputBase-input:focus + fieldset": {
    // border: `2px solid yellow`,
    borderRadius: "8px",
  },
  // "& .MuiInputBase-input:active + fieldset": {
  //   border: `2px solid yellow`,
  //   borderRadius: "8px",
  // },
  // "& .MuiInputBase-input:active + fieldset": {
  //   border: `2px solid yellow`,
  //   borderRadius: "8px",
  // },
  // "& .MuiInputBase-input + fieldset": {
  //   border: `2px solid yellow`,
  //   borderRadius: "8px",
  // },
  "& .MuiInputBase-input ": {
    backgroundColor: "rgba(12, 17, 29, 1)",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid #333741`,
    borderRadius: "8px",
  },
  ":hover .MuiOutlinedInput-notchedOutline": {
    border: `1px solid #333741`,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid white`,
  },

  // boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  // color: "#D6D6D6",
}));

export const MUISTYleTab = styled(MUITab)`
  color: #667085;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transperent;

  padding: 8px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: none;

  &:hover {
    background-color: transperent;
  }

  &. buttonUnstyledClasses.focusVisible {
    color: #2c2f33;
    outline: none;
    background-color: red;
  }

  &.Mui-selected {
    background-color: rgba(22, 27, 38, 1);
    color: rgba(206, 207, 210, 1);
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const MUITabsList = styled(MUITabs)`
  min-width: 320px;
  background-color: trapenrent;
  border-radius: 8px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  align-content: space-between;
`;
