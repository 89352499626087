import util from '@Services.Core/util';
import { LEFT, RIGHT, TOP, BOTTOM } from '@Components/UI/DrawerManager';

let manager;
const objectify = (props) => (util.isString(props) ? { content: props } : props);
const setManager = (mgr) => (manager = mgr);

const info = (props) => {
    const _props = objectify(props);
    return new Promise((resolve) => {
        _props.onClose = () => resolve();
        manager.showDrawer(_props);
    });
};

const right = (props) => info(Object.assign({ anchor: RIGHT }, objectify(props)));
const left = (props) => info(Object.assign({ anchor: LEFT }, objectify(props)));
const top = (props) => info(Object.assign({ anchor: TOP }, objectify(props)));
const bottom = (props) => info(Object.assign({ anchor: BOTTOM }, objectify(props)));
const close = (drower) => manager.close(drower);

export default {
    setManager,
    info,
    left,
    right,
    top,
    bottom,
    close
};
