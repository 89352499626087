import { configureStore } from "@reduxjs/toolkit";
import assistantSlice from "./assistantSlice";
import collectionsSlice from "./collectionsSlice";
import dataRoomSlice from "./dataRoomSlice";

export const store = configureStore({
  reducer: {
    collectionsSlice,
    dataRoomSlice,
    assistantSlice,
  },
});
