import React from "react";
import util from "@Services.Core/util";
import {
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  styled,
} from "@mui/material";
export const INFO = "info";

export const SUCCESS = "success";

// const useStyles = makeStyles((theme) => ({
//     modal: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center'
//     },
//     paper: {
//         backgroundColor: theme.palette.background.paper,
//         border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3)
//     }
// }));
const CustomModal = styled(Modal)`

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

`;
// const useStyles = (theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: "2px solid #000",
//     boxShadow: theme.shadows[5],

//     padding: theme.spacing(2, 4, 3),
//   },
// });

const CustomDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],

  padding: theme.spacing(2, 4, 3),
}));

class ModalManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: "dummy",
      modals: [],
    };
  }

  newKey() {
    return `modal__${util.generateGuid()}`;
  }
  getContent(modal = {}) {
    const { key, content, contentProps = {} } = modal;
    const modalProps = {
      modal: {
        key,
        close: this.closeModals.bind(this, null, modal),
        onClose: this.closeModals.bind(this, null, modal),
      },
    };

    if (util.isString(content)) {
      return <Typography>{content}</Typography>;
    } else if (React.isValidElement(content)) {
      return React.cloneElement(modal.content, {
        ...contentProps,
        ...modalProps,
      });
    } else {
      return React.cloneElement(<modal.content {...contentProps} />, {
        ...modalProps,
      });
    }
  }

  renderContent(modal = {}) {
    return this.getContent(modal);
  }

  closeModals(e, { key, onClose } = {}) {
    if (!key) return;
    onClose && onClose(e);
    this.setState({
      modals: this.getModals().filter((modal) => modal.key !== key),
    });
  }

  showModal(props = {}) {
    const key = this.newKey();
    this.setState({
      modals: this.getModals().concat({ ...props, key, valid: true }),
    });
    return { key };
  }

  getModals() {
    return this.state.modals;
  }
  valid({ key } = {}, valid = true) {
    this.setState({
      modals: this.getModals().map((modal) =>
        modal.key === key ? { ...modal, valid } : modal
      ),
    });
  }

  renderModal(modal = {}) {
    const { classes } = this.props;
    return (
      <CustomModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modal}
        open={true}
        onClose={(e) => this.closeModals(e, modal)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <CustomDiv>{this.renderContent(modal)}</CustomDiv>
        </Fade>
      </CustomModal>
    );
  }

  render() {
    return this.getModals().map((modal) => this.renderModal(modal));
  }
}

export default ModalManager;
