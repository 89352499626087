import React, { useEffect, useMemo, useState } from "react";
import JsonEditor from "react-json-editor-ui";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import CodeHighlighter from "../../../UI/CodeHighlighter";

const JsonFormatter = (props) => {
  const { activeScreen = "Preview" } = props;
  const { colors, mode } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const modelsVersionsList = useSelector(
    (state) => state.collectionsSlice.modelsVersionsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const isImageProcessType =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.model_type === "Image";
  const outputData = promptData.output_keys[promptData?.outputFormat];

  const [editObject, setEditObject] = useState(
    isImageProcessType
      ? { data: [{ url: "string" }] }
      : outputData
      ? outputData
      : { data: [{ title: "string", description: "string" }] }
  );
  const dispatch = useDispatch();

  const [showEditor, setShowEditor] = useState(true);

  const handleEditObject = () => {
    const isImageProcessType =
      modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
        ?.model_type === "Image";
    let imageOutputFormat = { data: [] };
    setShowEditor(true);
    if (isImageProcessType && promptData?.variationCount) {
      let data = [];
      for (let index = 0; index < promptData?.variationCount; index++) {
        data.push({ url: "string" });
      }
      imageOutputFormat["data"] = data;
      setEditObject(imageOutputFormat);
    } else {
      setEditObject(
        outputData
          ? outputData
          : { data: [{ title: "string", description: "string" }] }
      );
    }
  };

  useEffect(() => {
    setShowEditor(false);
    setEditObject({});
    let handleEditor = setTimeout(() => {
      handleEditObject();
    }, 500);
    return () => clearTimeout(handleEditor);
  }, [promptData?.llmVersion, promptData?.variationCount]);

  useEffect(() => {
    setEditObject({});
    setShowEditor(false);
    let handleEditor = setTimeout(() => {
      handleEditObject();
    }, 500);
    return () => clearTimeout(handleEditor);
  }, [promptData?.id]);

  useMemo(() => {
    let data1 = {
      ...promptData,
      output_keys: {
        ...promptData.output_keys,
        json: JSON.parse(JSON.stringify(editObject)),
      },
    };
    dispatch(handleChangEditPrompt(data1));
  }, [editObject]);

  return (
    <div>
      {promptData?.outputFormat && (
        <div className="p-3 rounded-b-lg bg-secondary">
          {activeScreen !== promptData?.outputFormat ? (
            <div class={`relative scal-json-formatter`}>
              {isImageProcessType && (
                <div className="absolute w-full h-full bg-transparent z-20"></div>
              )}
              {showEditor && (
                <JsonEditor
                  data={editObject}
                  onChange={(data) => setEditObject(data)}
                  optionsMap={{
                    color: [
                      { value: "red", label: "Red" },
                      { value: "blue", label: "Blue" },
                    ],
                    city: [
                      { value: "beijing", label: "Beijing" },
                      { value: "shanghai", label: "Shanghai" },
                    ],
                  }}
                />
              )}
            </div>
          ) : (
            <div>
              <CodeHighlighter
                customStyle={{ background: "transparent" }}
                style={mode === "dark" ? "vs2015" : "a11yLight"}
                language={"json"}
                wrapLongLines={true}
                wrapLines={true}
              >
                {JSON.stringify(editObject, undefined, 2)}
              </CodeHighlighter>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JsonFormatter;
