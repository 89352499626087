import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatTimestampToDate,
  isValidTimestamp,
} from "../../../../services/generalFunctions";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import FilledTextField from "../../../UI/FilledTextField";

const Information = () => {
  const { colors } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();

  const handleTagChange = (e) => {
    setNewTag(e.target.value);
  };
  const handleAddTag = () => {
    if (!promptData?.tags?.includes(newTag) && !!newTag) {
      let data = {
        ...promptData,
        tags: [...promptData?.tags, newTag],
      };
      dispatch(handleChangEditPrompt(data));
      setNewTag("");
    }
  };
  const handleDeleteTag = (newTag) => {
    let data = {
      ...promptData,
      tags: promptData?.tags?.filter((tag) => tag !== newTag),
    };
    dispatch(handleChangEditPrompt(data));
  };
  return (
    <div className="m-5 flex flex-col gap-4">
      <div>
        <p className="text-[10px] text-tertiary mb-2">Created</p>
        {isValidTimestamp(promptData?.created_at) ? (
          <p className="text-sm">
            {formatTimestampToDate(promptData?.created_at)}
          </p>
        ) : (
          "-"
        )}
      </div>
      <div>
        <p className="text-[10px] text-tertiary mb-2">Last edit</p>
        {isValidTimestamp(promptData?.updated_at) ? (
          <p className="text-sm">
            {formatTimestampToDate(promptData?.updated_at)}
          </p>
        ) : (
          "-"
        )}
      </div>
      <div>
        <div className="my-5 flex flex-wrap gap-2">
          {promptData?.tags?.map((tag, index) => (
            <Chip
              key={index}
              deleteIcon={<CloseIcon fontSize="small" />}
              sx={{
                borderRadius: "8px",
                border: `1px solid ${colors.borderPrimary}`,
                bgcolor: colors.bgPrimary,
                color: colors.textPrimary,
              }}
              onDelete={() => handleDeleteTag(tag)}
              label={tag}
            />
          ))}
        </div>
        <div className="my-4">
          <FilledTextField
            label={"Tags"}
            value={newTag}
            onChange={handleTagChange}
            endButton={
              <div
                onClick={handleAddTag}
                disabled={!promptData?.id}
                className=" bg-secondary text-buttonSecondaryText border-l-1 border-secondary flex items-center p-2 cursor-pointer rounded-r-lg text-sm h-[41px]"
              >
                Add Tag
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Information;
