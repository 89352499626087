import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromptLogs } from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { formatTimestampToDate } from "../../../../services/generalFunctions";
import { handleLoading } from "../../../../store/collectionsSlice";
import MuiTable from "../../../UI/MuiTable";

const PromptLogs = () => {
  const [logsData, setLogsData] = useState({});
  const dispatch = useDispatch();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice?.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const fetchLogs = ({ page = 1 }) => {
    dispatch(handleLoading(true));
    var payload = {
      prompt_id: promptData?.id,
      params: {
        page: page,
      },
    };
    getPromptLogs(payload)
      .then((res) => {
        console.log(res, "LOGS");
        setLogsData(res);
      })
      .catch((error) => {
        snackbar.error({ title: `${error.error && error.error.toString()}` });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  useEffect(() => {
    if (!!promptData?.id) {
      fetchLogs({ page: 1 });
    }
  }, [promptData?.id]);

  const columns = [
    {
      field: "created_at",
      title: "Date",
      renderAction: (row) => (
        <span>
          {formatTimestampToDate(row?.created_at, "DD MMM, YYYY hh:mm A")}
        </span>
      ),
    },
    {
      field: "model_type",
      title: "Model",
    },
    {
      field: "version_type",
      title: "Version",
    },
    {
      field: "input_tokens",
      title: "Input Tokens",
    },
    {
      field: "output_tokens",
      title: "Output Tokens",
    },
    {
      field: "request_cost",
      title: "Cost",
      renderAction: (row) => <span>${row?.request_cost}</span>,
    },
    {
      field: "cached",
      title: "Cached",
      renderAction: (row) => <span>{row?.cached ? "True" : "False"}</span>,
    },
    {
      field: "api_burns",
      title: "Burn",
      renderAction: (row) => <span>{row?.api_burns ? "API" : "Test"}</span>,
    },
    {
      field: "success",
      title: "Status",
      renderAction: (row) => (
        <span
          className={`${
            row?.success
              ? "text-[#067647] dark:text-[#75E0A7]"
              : "text-[#B42318] dark:text-[#FDA29B]"
          }`}
        >
          {row?.success ? "Success" : "Failed"}
        </span>
      ),
    },
  ];

  return (
    <>
      <MuiTable
        data={logsData.items}
        columns={columns}
        page={logsData?.page}
        pages={logsData?.pages}
        onPageChange={(v, page) => fetchLogs({ page })}
        hideFilter={true}
        tableTitle={"Prompt Logs"}
      />
    </>
  );
};

export default PromptLogs;
