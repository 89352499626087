import env from "./core/env";
const makeApiUrl = (team_url, collection_key, version, prompt_context_url) =>
  `${env.getApiBaseUrl()}${team_url}/collection/${collection_key}/${version}/${prompt_context_url}`;

const nodeAxios = (data, method = "post") => `
const axios = require('axios');
let payload = JSON.stringify(${JSON.stringify(data?.payload, null, 2)}) ;

let config = {
  method: '${method}',
  maxBodyLength: Infinity,
  url: '${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}',
  headers: ${JSON.stringify(data?.headers, null, 2)},
  data : payload
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});
`;
const nodeRequest = (data, method = "post") => `
var request = require('request');
var options = {
  method: '${method}',
  'url': '${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}',
  headers: ${JSON.stringify(data?.headers, null, 2)},
  body:JSON.stringify(${JSON.stringify(data?.payload, null, 2)})

};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
});
`;

const nodeUnirest = (data, method = "post") => `
var unirest = require('unirest');
var req = unirest('${method}', '${makeApiUrl(
  data?.team_url,
  data?.collection_key,
  data?.version,
  data?.prompt_context_url
)}')
  .headers(${JSON.stringify(data?.headers, null, 2)})
  .send(JSON.stringify(${JSON.stringify(data?.payload, null, 2)}))
  .end(function (res) { 
    if (res.error) throw new Error(res.error); 
    console.log(res.raw_body);
  });
`;

const jsFetch = (data, method = "post") => {
  let header = data?.headers;
  let text = "";
  Object.keys(header).forEach((item) => {
    text = text + `myHeaders.append("${item}", "${header[item]}");\n`;
  });
  return `
  var myHeaders = new Headers();
  ${text}
  var raw = JSON.stringify(${JSON.stringify(data?.payload, null, 2)});
  
  var requestOptions = {
    method: '${method}',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch('${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}', requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
  `;
};

const pythonHTTP = (data, method = "post") => `
import http.client
import json

url = '/${env
  .getApiBaseUrl()
  .replace("https://", "")
  .split("/")
  .slice(1)
  .join("/")}${data?.team_url}/collection/${data?.collection_key}/${
  data?.version
}/${data?.prompt_context_url}'
conn = http.client.HTTPSConnection('${
  env.getApiBaseUrl().replace("https://", "").split("/")[0]
}')
payload = json.dumps(${JSON.stringify(data?.payload, null, 2)})
headers = ${JSON.stringify(data?.headers, null, 2)}

conn.request('${method.toUpperCase()}', url, payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`;

const pythonRequest = (data, method = "post") => `
import requests
import json

url = "${makeApiUrl(
  data?.team_url,
  data?.collection_key,
  data?.version,
  data?.prompt_context_url
)}"

payload = json.dumps(${JSON.stringify(data?.payload, null, 2)})
headers = ${JSON.stringify(data?.headers, null, 2)}

response = requests.request('${method?.toUpperCase()}', url, headers=headers, data=payload)

print(response.text)
`;

const javaUnirest = (data, method = "post") => {
  return `
  Unirest.setTimeouts(0, 0);
  HttpResponse<String> response = Unirest.${method}("${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}")
    .headers(${JSON.stringify(data?.headers)})
    .body("${JSON.stringify(data?.payload)
      .replace(" ", "")
      .replace(/"/g, '\\"')}")
    .asString();
  `;
};

const rHttr = (data, method = "post") => {
  let header = data?.headers;
  let text = "";
  Object.keys(header).forEach((item) => {
    text = text + `'${item}' = '${header[item]}'\n`;
  });
  return `
  library(httr)
  
  headers = c(
    ${text}
  )
  
  body = "${JSON.stringify(data?.payload, null, 2)}"
  
  res <- VERB("${method?.toUpperCase()}", url = "${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}", body = body, add_headers(headers))
  
  cat(content(res, 'text'))`;
};

const dartHTTP = (data, method = "post") => `
import 'dart:convert';
import 'package:http/http.dart' as http;
void main() async {
  var headers = ${JSON.stringify(data?.headers, null, 2)};
  var request = http.Request('${method?.toUpperCase()}', Uri.parse('${makeApiUrl(
  data?.team_url,
  data?.collection_key,
  data?.version,
  data?.prompt_context_url
)}'));
  request.body = json.encode(${JSON.stringify(data?.payload, null, 2)});
  request.headers.addAll(headers);

  http.StreamedResponse response = await request.send();

  if (response.statusCode == 200) {
    print(await response.stream.bytesToString());
  }
  else {
    print(response.reasonPhrase);
  }
}
`;

const dartDio = (data, method = "post") => `
var headers = ${JSON.stringify(data?.headers, null, 2)};
var data = json.encode(${JSON.stringify(data?.payload, null, 2)});
var dio = Dio();
var response = await dio.request(
  '${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}',
  options: Options(
    method: '${method?.toUpperCase()}',
    headers: headers,
  ),
  data: data,
);

if (response.statusCode == 200) {
  print(json.encode(response.data));
}
else {
  print(response.statusMessage);
}`;

const rubyHTTP = (data, method = "post") => {
  let header = data?.headers;
  let text = "";
  Object.keys(header).forEach((item) => {
    text = text + `request["${item}"] = "${header[item]}"\n`;
  });
  return `
require "uri"
require "json"
require "net/https"

uri = URI("${makeApiUrl(
    data?.team_url,
    data?.collection_key,
    data?.version,
    data?.prompt_context_url
  )}")

https = Net::HTTP.new(uri.host, uri.port)
https.use_ssl = true

request = Net::HTTP::${method?.charAt(0).toUpperCase()}${method?.slice(
    1
  )}.new(uri)
${text}
request.body = JSON.dump(${JSON.stringify(data?.payload, null, 2)})

response = https.request(request)

puts response.read_body
  `;
};

export const allLaguagesCode = {
  "NodeJS - Axios": nodeAxios,
  "NodeJS - Request": nodeRequest,
  "NodeJS - Unirest": nodeUnirest,
  "JavaScript - Fetch": jsFetch,
  "Python - http.client": pythonHTTP,
  "Python - Requests": pythonRequest,
  // "Java - Unirest": javaUnirest,
  // "R - httr": rHttr,
  "Dart - http": dartHTTP,
  // "Dart - dio": dartDio,
  "Ruby - Net::HTTP": rubyHTTP,
};

export default function generateCode(parameters) {
  const { language, data, method = "post" } = parameters;
  const func = allLaguagesCode[language];
  return func(data, method);
}
