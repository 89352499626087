import { ReactComponent as SaveIcon } from "@Images/FloppyDisk.svg";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useCSVDownloader } from "react-papaparse";
import { downloadCSV } from "../../../../services/generalFunctions";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";

const CsvOutput = (props) => {
  const { promptData } = props;
  const { CSVDownloader, Type } = useCSVDownloader();
  const { colors } = useThemeContext();
  const headers = Object.keys(promptData?.promptOutput[0]);

  return (
    <div className={`p-3 rounded-lg bg-secondary border-1 border-secondary`}>
      {Array.isArray(promptData?.promptOutput) ? (
        <div
          className={`overflow-auto bg-primary border-1 border-primary rounded-lg`}
        >
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="rounded-t-lg">
                <TableRow>
                  {headers.map((heading) => (
                    <TableCell
                      style={{ background: colors.bgSecondary }}
                      sx={{
                        padding: "12px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      variant="head"
                      className="w-32 first:rounded-t-lg last:rounded-t-lg"
                    >
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {promptData.promptOutput?.length > 0 &&
                  promptData.promptOutput.map((data, index) => {
                    let keys = Object.keys(data);
                    return (
                      <TableRow
                        key={index}
                        className="border-b-[0.5px] border-primary  last:border-none"
                      >
                        {keys.map((item) => (
                          <TableCell
                            sx={{
                              minWidth: "200px",
                              width: "auto",
                              padding: "12px",
                              fontSize: "14px",
                              color: colors.textTertiary,
                            }}
                            key={`${item}${data}`}
                          >
                            {data[item]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <pre className="p-3 rounded-lg h-96 overflow-auto bg-primary border-1 border-primary">
          {promptData?.promptOutput}
        </pre>
      )}

      <div className="flex justify-end mt-3">
        {promptData?.isValidCsvFormat ? (
          <CSVDownloader
            type={Type.Button}
            filename={promptData?.name}
            bom={true}
            config={{
              delimiter: ",",
            }}
            data={promptData?.promptOutput}
          >
            <Button
              disabled={!promptData?.promptOutput}
              variant="outlined"
              color="secondary"
              endIcon={<SaveIcon />}
            >
              Save output
            </Button>
          </CSVDownloader>
        ) : (
          <Button
            disabled={!promptData?.promptOutput}
            variant="outlined"
            color="secondary"
            endIcon={<SaveIcon />}
            onClick={() =>
              downloadCSV(promptData?.promptOutput, promptData?.name)
            }
          >
            Save output
          </Button>
        )}
      </div>
    </div>
  );
};

export default CsvOutput;
