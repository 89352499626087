import React from "react";
import LogInBg from "../../Images/log-in-bg.png";

const PlanetScreen = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundPosition: "center",
      }}
      className="px-28 relative flex justify-center flex-col bg-cover py-10 "
    >
      <div className="absolute left-1/2 -translate-x-1/2 top-0 z-0">
        <img src={LogInBg} alt="bg" />
      </div>
      <div className="z-10">{children}</div>
    </div>
  );
};

export default PlanetScreen;
