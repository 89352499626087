import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import {
  a11yDark,
  a11yLight,
  docco,
  stackoverflowDark,
  vs,
  vs2015,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

export const themes = {
  docco,
  vs2015,
  vs,
  a11yDark,
  a11yLight,
  stackoverflowDark,
};

const CodeHighlighter = (props) => {
  const {
    children = "",
    language = "javascript",
    style = "vs2015",
    customStyle = {},
    ...rest
  } = props;

  return (
    <SyntaxHighlighter
      language={language}
      style={themes[style]}
      showLineNumbers={true}
      customStyle={{
        borderRadius: "8px",
        ...customStyle,
      }}
      {...rest}
    >
      {children}
    </SyntaxHighlighter>
  );
};

export default CodeHighlighter;
