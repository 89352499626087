import { Check } from "@mui/icons-material";
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMyTeams, setActiveTeam } from "../../services/app/api";
import snackbar from "../../services/app/snackbar";
import { useAuth } from "../Auth/AuthContext";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import { TeamIcon } from "../UI/IconPack";
const TeamsList = () => {
  const [allTeams, setAllTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { colors } = useThemeContext();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getMyTeams(auth?.id)
      .then((res) => {
        setAllTeams(res?.items);
      })
      .catch((error) => {
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleTeamChange = (team) => {
    auth.setActiveTeam(team?.id);
    auth.setIsAdminTeam(!!team?.admin_team ? "yes" : "no");
    setActiveTeam({ team_id: team?.id });
    navigate("/onboard/collections", { replace: true });
  };

  return (
    <div
      style={{
        width: "100%",
        maxHeight: "300px",
        overflowY: "auto",
        background: colors.bgPrimary,
      }}
      className="border-b-1 border-primary"
    >
      {isLoading && (
        <div className="flex justify-center p-4">
          <CircularProgress />
        </div>
      )}
      {!!allTeams.length &&
        !isLoading &&
        allTeams.map((team) => (
          <MenuItem
            sx={{
              backgroundColor: colors.bgPrimary,
              "&:hover": {
                background: colors.bgSecondary,
              },
              padding: "12px 16px 12px 16px",
              minWidth: "240px",
            }}
            key={team?.id}
            onClick={() => handleTeamChange(team)}
          >
            <ListItemIcon>
              <TeamIcon height={16} width={14} />
            </ListItemIcon>
            <ListItemText>
              <div className="text-sm-regular">{team?.name}</div>
            </ListItemText>
            {auth?.activeTeam == team?.id && (
              <ListItemIcon
                sx={{
                  minWidth: "20px !important",
                  ".MuiListItemIcon-root": {
                    minWidth: "20px",
                  },
                }}
              >
                <Check fontSize="small" color="primary" />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
    </div>
  );
};

export default TeamsList;
