import { MUISTYleTab, MUITabsList } from "@Components/Themes/CustomComponents";
import { CustomTextField } from "@Components/UI/StyledComponets";
import { ReactComponent as SearchIcon } from "@Images/search-lg.svg";
import snackbarService from "@Services.App/snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import {
  addNewCollection,
  addTemplateCollection,
  getTemplatesCollections,
  getTemplatesTags,
} from "../../../services/app/api";
import {
  handleLoading,
  handleResetEdit,
} from "../../../store/collectionsSlice";
import PaginationElement from "../../UI/PaginationElement";

import { useDispatch } from "react-redux";
import { useAuth } from "../../Auth/AuthContext";
import { useThemeContext } from "../../Themes/ThemeContextProvider";
import TagsField from "../../UI/TagsField";

const CollectionSelect = ({ dialog, handlerRedirect, handleModalOpen }) => {
  const { mode, colors } = useThemeContext();
  const { activeTeam, id: userId } = useAuth();
  const [collectionName, setCollectionName] = useState("");
  const [value, setValue] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState("newCollection");
  const [description, setDescription] = useState("");
  const [newCollectionTags, setNewCollectionTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchKey, setSearchKey] = useState("");
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState("");
  const [pages, setPages] = useState(10);
  const fetchCollections = (tag, search = "", page = 1) => {
    // dispatch(handleLoading(true));
    setLoading(true);
    getTemplatesCollections({
      params: { tag, limit: 1, page: page, searchKey: search },
    })
      .then((res) => {
        setCollections(res?.items);
        setPages(res?.pages);
        setPage(res?.page);
      })
      .catch(() => {})
      .finally(() => {
        // dispatch(handleLoading(false));
        setLoading(false);
      });
  };

  const handleAddToCollection = () => {
    let apiFunction = addTemplateCollection;
    let payload = {
      team_id: activeTeam,
      data: {
        collection_id: selectedCollection,
        name: collectionName,
        tags: newCollectionTags,
        description,
      },
    };

    if (selectedCollection === "newCollection") {
      apiFunction = addNewCollection;
      payload = {
        data: {
          name: collectionName,
          user_id: userId,
          team_id: activeTeam,
        },
      };
      if (description) {
        payload["data"]["description"] = description;
      }
      if (newCollectionTags.length) {
        payload["data"]["tags"] = newCollectionTags;
      }
    }

    dispatch(handleLoading(true));
    apiFunction(payload)
      .then((res) => {
        handlerRedirect(res?.id, res);
        dispatch(handleResetEdit());
        dialog && dialog.close();
        snackbarService.success({ title: "Collection added" });
      })
      .catch((error) =>
        snackbarService.error({
          title: `${error.error && error.error.toString()}`,
        })
      )
      .finally(() => {
        dispatch(handleLoading(false));
      });
  };
  useEffect(() => {
    getTemplatesTags().then(({ items }) => {
      if (items?.length) {
        setTags(items);
        fetchCollections(items[0]);
        setSelectedTag(items[0]);
      }
    });
  }, []);

  return (
    <div style={{ width: "70vw", height: "100%" }} className="bg-primary p-6">
      <div className="flex justify-between  main-block border-b-1 border-primary">
        <div>
          <p className="text-xl-semibold  text-primary   ">New collection</p>
          <p className="text-sm-regular text-tertiary">
            Create a new collection from a template or start from scratch.
          </p>
        </div>
        <div>
          <IconButton
            onClick={() => {
              dialog && dialog.close();
            }}
            style={{ color: "#85888E" }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="h-[350px] 2xl:h-[500px] overflow-auto">
        <div className="flex flex-col gap-4  text-secondary py-6 border-b-1 border-primary">
          <div className="flex items-center w-full">
            <div className="text-sm text-secondary w-40">Collection name</div>
            <div className="w-full">
              <CustomTextField
                id="outlined-basic"
                // label="Enter feed link"
                placeholder="Collection name"
                variant="outlined"
                fullWidth
                type="url"
                value={collectionName}
                onChange={(e) => {
                  setCollectionName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex items-center w-full">
            <div className="text-sm text-secondary w-40">Description</div>
            <div className="w-full">
              <CustomTextField
                id="outlined-basic"
                // label="Enter feed link"
                placeholder="Add description"
                variant="outlined"
                fullWidth
                type="url"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex items-center w-full">
            <div className="text-sm text-secondary w-40">Tags</div>
            <div className="w-full">
              <TagsField
                value={newCollectionTags}
                onChange={(v) => setNewCollectionTags(v)}
              />
            </div>
          </div>
        </div>
        <div className="block-tags">
          <div className="cur-main-container-style  ">
            <MUITabsList
              value={value}
              onChange={() => {}}
              scrollbarFadeDuration="0"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
                  opacity: "1 !important",
                },
              }}
            >
              {tags.map((tag, index) => {
                return (
                  <MUISTYleTab
                    onClick={() => {
                      setValue(index);
                      setSelectedTag(tag);
                      setSearchKey("");
                      setSelectedCollection("newCollection");
                      fetchCollections(tag, "", 1);
                    }}
                    sx={{
                      color:
                        mode === "dark"
                          ? colors.textPrimary
                          : colors.textQuaternary,
                      fontWidth: "600",
                      "&.Mui-selected": {
                        color:
                          mode === "dark"
                            ? colors.textPrimary
                            : colors.brandPrimary,
                        backgroundColor: colors.bgSecondary,
                      },
                    }}
                    label={
                      <div name={tag} style={{ textTransform: "capitalize" }}>
                        {tag}
                      </div>
                    }
                  ></MUISTYleTab>
                );
              })}
            </MUITabsList>
          </div>

          <div className="w-full">
            <CustomTextField
              id="outlined-basic"
              // label="Enter feed link"
              placeholder="Search collection"
              variant="outlined"
              fullWidth
              type="url"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSelectedCollection("newCollection");
                fetchCollections(selectedTag, e.target.value, 1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="">
          <div className="main-card">
            <div
              className={
                "newCollection" !== selectedCollection
                  ? `tag-block-main  bg-secondary  cursor-pointer`
                  : "tag-block-main-selected cursor-pointer bg-primary  "
              }
              onClick={() => {
                setSelectedCollection("newCollection");
              }}
            >
              <div className="">
                <div className="text-xl-semibold">Empty collection</div>
                <p
                  style={{
                    display: "-webkit-box",
                    maxWidth: "100%",
                    WebkitLineClamp: 5,
                    "-webkit-box-orient": "vertical",
                    overflow: "hidden",
                  }}
                  className="text-tertiary text-md overflow-hidden mt-4"
                >
                  This is empty collection
                </p>
              </div>
            </div>
            {collections?.map((collection) => {
              return (
                <div
                  className={
                    collection.id !== selectedCollection
                      ? `tag-block-main  bg-secondary cursor-pointer`
                      : "tag-block-main-selected cursor-pointer bg-primary"
                  }
                  onClick={() => {
                    setSelectedCollection(collection?.id);
                  }}
                >
                  <div className="">
                    <div className="text-xl-semibold">{collection?.name}</div>
                    <p
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: 5,
                        "-webkit-box-orient": "vertical",
                        overflow: "hidden",
                      }}
                      className="text-tertiary text-md overflow-hidden mt-4"
                    >
                      {collection?.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {collections?.length > 0 && pages > 1 && (
          <div className=" pagination-block">
            <PaginationElement
              page={page}
              total={pages}
              handleChangeInPagination={(val, page) => {
                fetchCollections(selectedTag, searchKey, page);
              }}
            />
          </div>
        )}
      </div>

      <div className="flex justify-end pt-5 gap-4 border-t-1 border-primary">
        <Button
          onClick={() => {
            dialog && dialog.close();
          }}
          variant="outlined"
          color="secondary"
          sx={
            mode === "light" && {
              color: "#344054",
              borderColor: "#D0D5DD",
            }
          }
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ backgroundImage: "none" }}
          onClick={handleAddToCollection}
          disabled={!selectedCollection || !collectionName || loading}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CollectionSelect;
