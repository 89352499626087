export const lightThemeColors = {
  borderPrimary: "#D0D5DD",
  borderSecondary: "#EAECF0",
  textErrorPrimary: "#F97066",
  textPrimary: "#101828",
  textSecondary: "#344054",
  textTertiary: "#475467",
  textQuaternary: "#667085",
  bgPrimary: "#FFFFFF",
  bgSecondary: "#F9FAFB",
  bgTertiary: "#F4F3FF",
  bgQuaternary: "#EAECF0",
  bgErrorSecondary: "#F5F5F6",
  brandPrimary: "#6938EF",
  brandSecondary: "#6938EF",
  error: "#D92D20",
};

export const darkThemColors = {
  borderPrimary: "#333741",
  borderSecondary: "#1F242F",
  textErrorPrimary: "#F97066",
  textPrimary: "#F5F5F6",
  textSecondary: "#CECFD2",
  textTertiary: "#94969C",
  textQuaternary: "#94969C",
  bgPrimary: "#0C111D",
  bgSecondary: "#161B26",
  bgTertiary: "#1F242F",
  bgQuaternary: "#333741",
  bgErrorSecondary: "#F5F5F6",
  brandPrimary: "#7A5AF8",
  brandSecondary: "#6938EF",
  error: "#E57878",
};
