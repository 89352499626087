import { useAuth } from "@Components/Auth/AuthContext";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const { component } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.isAuthenticated()) {
      auth.logout();
      navigate("/");
    }
  }, []);

  return <>{component}</>;
};

export default PrivateRoute;
